/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IUserData {
  displayName: string;
  userId: string;
  email: string;
}

const isObject = (value: any): boolean =>
  typeof value === "object" && value !== null && !Array.isArray(value);

export const IsUserData = (obj: any): obj is IUserData =>
  isObject(obj) &&
  Object.prototype.hasOwnProperty.call(obj, "displayName") &&
  Object.prototype.hasOwnProperty.call(obj, "userId") &&
  Object.prototype.hasOwnProperty.call(obj, "email");

export interface IGameSave {
  score: number;
  data: any;
  completed: boolean;
}
export const IsGame = (obj: any): obj is IGameSave =>
  isObject(obj) &&
  Object.prototype.hasOwnProperty.call(obj, "score") &&
  Object.prototype.hasOwnProperty.call(obj, "data") &&
  Object.prototype.hasOwnProperty.call(obj, "completed");

export interface IPortalAPI {
  // Get info about the logged-in user. Return undefined if no user is logged in.
  getUser: () => Promise<IUserData>;
  // Download game data to the backend
  loadGame: () => Promise<IGameSave>;
  // Upload game data to the backend
  saveGame: (game: IGameSave) => Promise<void>;
}
