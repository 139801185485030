const callbacks: Map<string, () => void> = new Map();

export function subscribeToPageVisibility(
  key: string,
  onHidden: () => void,
  onShown: () => void,
) {
  if (__BUILD_ENV__ === "TEST") return;
  if (callbacks.has(key)) {
    document.removeEventListener("visibilitychange", callbacks.get(key));
  }
  const callback = () => {
    if (document.hidden) {
      onHidden();
    } else {
      onShown();
    }
  };
  callbacks.set(key, callback);
  document.addEventListener("visibilitychange", callback);
}

export function unsubscribeFromPageVisibility(key: string) {
  if (__BUILD_ENV__ === "TEST") return;
  if (callbacks.has(key)) {
    document.removeEventListener("visibilitychange", callbacks.get(key));
    callbacks.delete(key);
  }
}
