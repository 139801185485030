const xTiles = 48;
const yTiles = 27;

export const tileSize = 50;
export const tillYPos = 13.5;

// Bottom product row
export const bottomProductYPos = 16.5;
export const firstProductXPos = 17;
export const lastProductXPos = 31;

export const mapData = {
  width: xTiles * tileSize,
  height: yTiles * tileSize,
  tilesX: xTiles,
  tilesY: yTiles,
  tileSize: tileSize,
  roadTop: 7 * tileSize,
  roadBottom: 10 * tileSize,
  customerTopLane: 8.5 * tileSize, // The top lane is set to higher than the road top to account for the size and origin of the customer game object.
  customerMidLane: 9.5 * tileSize,
  customerBottomLane: 10.5 * tileSize,
  tillPosition: [
    { x: 23, y: tillYPos },
    { x: 25, y: tillYPos },
    { x: 20, y: tillYPos },
    { x: 28, y: tillYPos },
    { x: 17, y: tillYPos },
    { x: 31, y: tillYPos },
    { x: 14, y: tillYPos },
  ].map((v) => {
    v.x *= tileSize;
    v.y *= tileSize;
    return v;
  }),
  // TODO: Update in SM-AGEC-244
  // Hire Employee positions are different to employee till positions
  hireEmployeePosition: [
    { x: 23, y: tillYPos },
    { x: 25, y: tillYPos },
    { x: 20, y: tillYPos },
    { x: 28, y: tillYPos },
    { x: 17, y: tillYPos },
    { x: 31, y: tillYPos },
    { x: 14, y: tillYPos },
  ].map((v) => {
    v.x *= tileSize;
    v.y *= tileSize;
    return v;
  }),
  tillSize: {
    w: 2 * tileSize,
    h: 1 * tileSize,
  },
  products: [
    { x: 24, y: bottomProductYPos, w: 2, h: 1 }, // center
    { x: 21, y: bottomProductYPos, w: 2, h: 1 },
    { x: 27, y: bottomProductYPos, w: 2, h: 1 },
    { x: firstProductXPos, y: bottomProductYPos, w: 2, h: 1 },
    { x: lastProductXPos, y: bottomProductYPos, w: 2, h: 1 }, // center

    { x: 11.5, y: 15, w: 1, h: 2 }, // left
    { x: 36.5, y: 15, w: 1, h: 2 }, // right
    { x: 11.5, y: 18, w: 1, h: 2 }, // left
    { x: 36.5, y: 18, w: 1, h: 2 }, // right
  ].map((v) => {
    v.x *= tileSize;
    v.y *= tileSize;
    v.w *= tileSize;
    v.h *= tileSize;
    return v;
  }),
  launchProductPosition: [
    // TODO: Update in SM-AGEC-244
    // Add Product positions are different to product till positions
    { x: 24, y: bottomProductYPos, w: 2, h: 1 }, // center
    { x: 21, y: bottomProductYPos, w: 2, h: 1 },
    { x: 27, y: bottomProductYPos, w: 2, h: 1 },
    { x: firstProductXPos, y: bottomProductYPos, w: 2, h: 1 },
    { x: lastProductXPos, y: bottomProductYPos, w: 2, h: 1 }, // center

    { x: 11.5, y: 15, w: 1, h: 2 }, // left
    { x: 36.5, y: 15, w: 1, h: 2 }, // right
    { x: 11.5, y: 18, w: 1, h: 2 }, // left
    { x: 36.5, y: 18, w: 1, h: 2 }, // right
  ].map((v) => {
    v.x *= tileSize;
    v.y *= tileSize;
    v.w *= tileSize;
    v.h *= tileSize;
    return v;
  }),
};
