import { css, html } from "lit";
import { customElement } from "lit/decorators.js";
import { buttonStyle } from "../styles/theme";
import { LitElementI18n } from "../translations/lit-mixin";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { UIWatchableModel } from "../models/gameModel";
import {
  FinancialReportModel,
  ProductInfo,
} from "../models/financialReportModel";

@customElement("profit-loss")
export class ProfitLoss extends LitElementI18n {
  static styles = [
    buttonStyle,
    css`
      :host {
        width: 100%;
        height: 100%;
        background-color: white;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .container {
        position: relative;

        width: 100%;
        max-width: 1920px;
        height: 100%;
        max-height: 1080px;

        display: flex;
        justify-content: space-evenly;
      }

      .column {
        flex: 1;
        padding: 5px;
      }
    `,
  ];

  @consume({ context: gameContext })
  game: GameController;

  uiModel: UIWatchableModel;
  financialReportModel: FinancialReportModel;

  connectedCallback(): void {
    super.connectedCallback();
    this.uiModel = this.game.getModel().getUIWatchableModel();
    this.financialReportModel = this.game.getModel().getFinancialReportModel();
  }

  renderProfitOrLoss = () => {
    if (this.financialReportModel.profit > 0) {
      return html`<h1>${this.financialReportModel.profit} Profit ⓘ</h1>`;
    } else if (this.financialReportModel.profit < 0) {
      return html`<h1>
        ${Math.abs(this.financialReportModel.profit)} Loss ⓘ
      </h1>`;
    } else {
      return html`<h1>You've broken even!ⓘ</h1>`;
    }
  };

  renderProductSales = (info: ProductInfo) => {
    return html` <p>${info.kind.key()}: ${info.income}</p> `;
  };

  render() {
    return html` <div class="container">
      <div class="column">
        <h1>Revenue ⓘ: ${this.financialReportModel.income}</h1>
        <p>Sales: ${this.financialReportModel.salesIncome}</p>
        ${Object.values(this.financialReportModel.productInfo).map((info) =>
          this.renderProductSales(info),
        )}
        <p>Achievements: ${this.financialReportModel.achievementsRevenue}</p>
      </div>
      <div class="column">
        <h1>Expenses ⓘ: ${this.financialReportModel.expenses}</h1>
        <p>Stock: ${this.financialReportModel.stockCost}</p>
        <p>Marketing: ${this.financialReportModel.marketing}</p>
        <p>Staff: ${this.financialReportModel.salaries}</p>
      </div>
      <div class="column">${this.renderProfitOrLoss()}</div>
    </div>`;
  }
}
