import { html, css } from "lit";
import { customElement } from "lit/decorators.js";
import { LitElementI18n } from "../translations/lit-mixin";
import { translate } from "../translations/translations";
import { closeDialog } from "../state/app-state";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { productGroupInfoMap } from "../data/products";
import { UIImages } from "../data/images";
import { gameDialogStyles } from "../styles/game-dialog";
import { playSoundEffect } from "../sound/sound-manager";

@customElement("add-product-dialog")
export class AddProductDialog extends LitElementI18n {
  static styles = [
    gameDialogStyles,
    css`
      #dialog {
        background-color: white;

        position: relative;
        bottom: 0;
        min-width: 200px;

        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 20px;
      }

      #buttons {
        display: flex;
        gap: 10px;
      }
      button {
        flex-grow: 1;
      }

      .product-btn {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding-top: 20px;
      }
    `,
  ];

  @consume({ context: gameContext })
  game: GameController;

  onAccept = () => {
    this.game.launchProduct();
    playSoundEffect("Purchase");
    closeDialog();
  };
  onCancel = () => {
    closeDialog();
  };

  getNextProduct = () => {
    const idx = this.game.getNextAvailableProductIdx();

    if (idx <= -1) {
      return html`<p>No more products to launch for this level</p>`;
    }

    const businessType = this.game.getModel().getShop().getBusinessType();

    return html`
      <div class="product-btn">
        <button autofocus @click=${() => this.onAccept()}>
          ${this.game.getModel().getShop().nextProductCost ?? ""}
          <img src=${UIImages.Gold} height="12" />
          ${productGroupInfoMap[businessType][idx]}
        </button>
      </div>
    `;
  };

  render() {
    return html`<dialog open>
      <div class="content">
        ${translate("Product_LaunchProduct")} ${this.getNextProduct()}
      </div>
      <div class="buttons">
        <button @click=${this.onCancel}>
          ${translate("Warning_Button_Cancel")}
        </button>
      </div>
    </dialog>`;
    // TODO: Get this working again with the camera that can pan and zoom
    // return html`
    //   <div
    //     style=${styleMap({
    //       position: "absolute",
    //       left: `${this.position.x}px`,
    //       bottom: `calc(100% - ${this.position.y}px)`,
    //     })}
    //   >
    //     <div id="dialog">
    //       <div>${t(Key.LaunchProduct)}</div>
    //       <div id="buttons">
    //         <button @click=${this.onCancel}>${t(Key.Cancel)}</button>
    //         <button autofocus @click=${this.onAccept}>
    //           <img src="assets/gold.png" height="12" />15
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // `;
  }
}
