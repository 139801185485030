import { PortalAPIDev } from "./dev";
import { IGameSave, IPortalAPI, IUserData } from "./type";
import { usingDevBackend, usingLocalBackend } from "../env";
import { info } from "../helpers/loggerHelper";
import { requestActions } from "../state/api-state";
import { PortalAPIProd } from "./prod";

class PortalAPIStateWrapper implements IPortalAPI {
  api: IPortalAPI;

  constructor() {
    if (usingDevBackend) {
      info("Using Dev API");
      this.api = new PortalAPIDev();
    } else if (usingLocalBackend) {
      info("Using Local API");
      this.api = new PortalAPIProd("http://localhost:8080");
    } else {
      // TODO: Use proper base URL
      this.api = new PortalAPIProd("http://localhost:8080");
    }
  }

  getUser = async (): Promise<IUserData> => {
    requestActions.getUser.start();
    try {
      const user = await this.api.getUser();
      requestActions.getUser.success(user);
      return user;
    } catch (e) {
      requestActions.getUser.failed(e.toString());
      throw e;
    }
  };

  loadGame = async (): Promise<IGameSave> => {
    requestActions.loadGame.start();
    try {
      const game = await this.api.loadGame();
      requestActions.loadGame.success(game);
      return game;
    } catch (e) {
      requestActions.loadGame.failed(e.toString());
      throw e;
    }
  };
  saveGame = async (game: IGameSave): Promise<void> => {
    requestActions.saveGame.start();
    try {
      await this.api.saveGame(game);
      requestActions.saveGame.success();
    } catch (e) {
      requestActions.saveGame.failed(e.toString());
      throw e;
    }
  };
}

export const PortalAPI = new PortalAPIStateWrapper();
