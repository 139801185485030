import Phaser from "phaser";
import { ProgressBarGameObject } from "./progress-bar";
import { Employee, EmployeeState } from "../models/employee";
import { GameObjectDepth } from "../data/game-objects";
import { ActiveDialog, openDialog } from "../state/app-state";
import { ThoughtBubbleGameObject } from "./thought-bubble";
import { GameImages } from "../data/images";
import { employeeDirectionHelper } from "../helpers/directionHelper";
import { SpriteDirection } from "../types/spriteDirection";
import phaserJuice from "../plugins/phaser-juice/phaserJuice.min.js";
import { EllipsGameObject } from "./ellips";
import { EmployeeEmote, EmployeeServingState } from "../types/emoteStates";
import { EmoteGameObject } from "./emote";

export class EmployeeGameObject extends Phaser.GameObjects.Container {
  id: string;
  spriteIndex: number;
  spriteBody: Phaser.GameObjects.Image;

  private spriteSheetKey: string;
  private employee: Employee;

  private prevX: number;
  private prevY: number;
  private juice: phaserJuice;
  private bobMovement: phaserJuice;

  // Reactions - EMPLOYEE SERVING STATES
  private progressBar: ProgressBarGameObject;
  private isIdle: boolean = false;
  private thoughtBubble: ThoughtBubbleGameObject;
  private ellipsObject: EllipsGameObject;
  private emote: EmoteGameObject;
  private employeeServingState: EmployeeServingState;

  constructor(scene: Phaser.Scene, employee: Employee, spriteIndex: number) {
    super(scene);
    this.id = employee.id;
    this.spriteIndex = spriteIndex;

    this.spriteSheetKey = GameImages.EmployeeSheet;
    if (this.spriteIndex === 0) {
      this.spriteSheetKey = GameImages.MainCharacterSheet;
    }

    this.create(employee);
    this.setDepth(GameObjectDepth.Employee);

    this.juice = new phaserJuice(this.scene);
    this.bobJuice();

    this.updateWithModel(employee);
  }

  updateWithModel = (employee: Employee) => {
    if (!employee) {
      return;
    }

    // SPRITE DIRECTION
    const direction = employeeDirectionHelper(
      this.prevX,
      this.prevY,
      employee.getPosition().x,
      employee.getPosition().y,
    );
    this.updateEmployeeDirection(direction);

    // BOB MOVEMENT
    if (
      this.prevX === employee.getPosition().x &&
      this.prevY === employee.getPosition().y
    ) {
      (this.bobMovement.wobbleTween as Phaser.Tweens.Tween).pause();
      (this.bobMovement.wobbleTween as Phaser.Tweens.Tween).restart();
    } else {
      (this.bobMovement.wobbleTween as Phaser.Tweens.Tween).resume();
    }

    this.updateEmployeeServingState(employee);

    this.prevX = employee.getPosition().x;
    this.prevY = employee.getPosition().y;

    const { x, y } = employee.getPosition();
    this.setPosition(x, y);
    this.employee = employee;
  };

  handleEmoteAndThoughtBubbleVisibility = () => {
    if (this.employeeServingState === EmployeeServingState.Annoyed) {
      if (this.emote.emoteSprite?.anims?.isPlaying && !this.emote.visible) {
        this.thoughtBubble.setVisible(false);
        this.emote.setVisible(true);
      } else if (
        !this.emote.emoteSprite?.anims?.isPlaying &&
        !this.thoughtBubble.visible
      ) {
        this.thoughtBubble.setVisible(true);
        this.emote.setVisible(false);
      }
    }
  };

  private updateEmployeeServingState = (employee: Employee) => {
    const newState = this.getEmployeeServingState(employee);

    if (this.employeeServingState === newState) {
      if (this.employeeServingState === EmployeeServingState.LoadingBar) {
        this.progressBar.setProgress(employee.getProgress());
      }

      this.handleEmoteAndThoughtBubbleVisibility();

      return;
    }

    this.employeeServingState = newState;

    switch (this.employeeServingState) {
      case EmployeeServingState.Ellips:
        this.ellipsObject.setVisible(true);
        this.progressBar.setVisible(false);
        this.thoughtBubble.setVisible(false);
        this.emote.setVisible(false);
        this.updateIdleState(false);
        return;
      case EmployeeServingState.Annoyed:
        this.ellipsObject.setVisible(false);
        this.progressBar.setVisible(false);
        this.emote.setEmoteReaction(EmployeeEmote.Annoyed, true);
        this.handleEmoteAndThoughtBubbleVisibility();
        this.updateIdleState(true);
        return;
      case EmployeeServingState.LoadingBar:
        this.ellipsObject.setVisible(false);
        this.progressBar.setVisible(true);
        this.progressBar.setProgress(employee.getProgress());
        this.thoughtBubble.setVisible(false);
        this.updateIdleState(false);
        return;
      case EmployeeServingState.Nothing:
      default:
        this.ellipsObject.setVisible(false);
        this.progressBar.setVisible(false);
        this.thoughtBubble.setVisible(false);
        this.updateIdleState(false);
        return;
    }
  };

  private getEmployeeServingState = (
    employee: Employee,
  ): EmployeeServingState => {
    switch (employee.getState()) {
      case EmployeeState.TakingOrder:
        return EmployeeServingState.LoadingBar;
      case EmployeeState.WalkingToProduct:
        return EmployeeServingState.Nothing;
      case EmployeeState.TakingOutProduct:
        return EmployeeServingState.LoadingBar;
      case EmployeeState.TakingProductToTill:
        return EmployeeServingState.Nothing;
      case EmployeeState.SellingProduct:
        return EmployeeServingState.LoadingBar;
      case EmployeeState.WaitingAtTill:
        if (employee.idle) {
          return EmployeeServingState.Annoyed;
        }
        return EmployeeServingState.Ellips;
      default:
        if (employee.idle) {
          return EmployeeServingState.Annoyed;
        }
        return EmployeeServingState.Ellips;
    }
  };

  private updateEmployeeDirection = (direction: SpriteDirection) => {
    if (direction === SpriteDirection.Left) {
      this.spriteBody
        .setTexture(this.spriteSheetKey, this.getFrame("side"))
        .setScale(1, 1);
    } else if (direction === SpriteDirection.Right) {
      this.spriteBody
        .setTexture(this.spriteSheetKey, this.getFrame("side"))
        .setScale(-1, 1);
    } else {
      this.spriteBody
        .setTexture(this.spriteSheetKey, this.getFrame(direction))
        .setScale(1, 1);
    }
  };

  private create = (employee: Employee) => {
    this.employee = employee;
    this.prevX = employee.getPosition().x;
    this.prevY = employee.getPosition().y;

    // Setting the depth doesn't seem to have any effect on objects within a container
    // The order in which they are created becomes important - shadow needs to be created first
    const shadow = this.scene.add.image(
      0,
      0,
      GameImages.EmployeeSheet,
      "Shadow.png",
    );
    this.add(shadow);

    this.spriteBody = this.scene.add
      .image(0, 0, this.spriteSheetKey, this.getFrame("back"))
      .setOrigin(0.5);
    this.add(this.spriteBody);

    this.scene.input.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown);

    // REACTIONS
    this.createEmployeeReactionObjects();
  };

  private createEmployeeReactionObjects = () => {
    // LOADING BAR
    this.progressBar = new ProgressBarGameObject(
      this.scene,
      this.spriteBody.getTopCenter().x,
      this.spriteBody.getTopCenter().y - 10,
    );
    this.add(this.progressBar);

    // THOUGHT BUBBLE
    this.thoughtBubble = new ThoughtBubbleGameObject(
      this.scene,
      this.spriteBody.getTopCenter().x,
      this.spriteBody.getTopCenter().y,
      GameImages.EmoticonAndThoughtBubbleSheet,
      "bubble_salary_due.png",
    );

    this.thoughtBubble.setVisible(false);
    this.add(this.thoughtBubble);

    this.thoughtBubble.bubble.on(
      Phaser.Input.Events.POINTER_DOWN,
      this.onThoughtBubbleClicked,
    );

    // EMOTE
    this.emote = new EmoteGameObject(
      this.scene,
      this.spriteBody.getTopCenter().x,
      this.spriteBody.getTopCenter().y,
    );

    this.add(this.emote);

    // ELLIPS
    this.ellipsObject = new EllipsGameObject(
      this.scene,
      this.spriteBody.getTopCenter().x,
      this.spriteBody.getTopCenter().y,
    );
    this.add(this.ellipsObject);
  };

  private updateIdleState = (idle: boolean) => {
    if (this.isIdle !== idle) {
      this.isIdle = idle;
      if (idle) {
        this.spriteBody.setInteractive({
          useHandCursor: true,
        });
        this.thoughtBubble.bubble.setInteractive({ useHandCursor: true });
        this.thoughtBubble.setImageWithKeyAndFrame(
          GameImages.EmoticonAndThoughtBubbleSheet,
          "bubble_salary_due.png",
        );
      } else {
        this.spriteBody.disableInteractive();
      }
      this.thoughtBubble.setPayPipVisibility(idle);
    }
  };

  private getFrame(direction: "front" | "back" | "side") {
    if (this.spriteIndex === 0) {
      return `main_${direction}.png`;
    }
    return `employee_${this.spriteIndex - 1}_${direction}.png`;
  }

  private bobJuice() {
    this.bobMovement = this.juice.wobble(this.spriteBody, {
      x: 0,
      y: -10,
      repeat: -1,
      yoyo: true,
      angle: -45,
      ease: "linear",
      duration: 100,
    });
  }

  private onPointerDown = (
    _: Phaser.Input.Pointer,
    currentlyOver: Phaser.GameObjects.GameObject[],
  ) => {
    if (!this.employee.idle || !currentlyOver.includes(this.spriteBody)) return;
    openDialog(ActiveDialog.PayEmployee, this.employee);
  };

  private onThoughtBubbleClicked = () => {
    if (!this.employee.idle) return;
    openDialog(ActiveDialog.PayEmployee, this.employee);
  };

  destroy(fromScene?: boolean): void {
    this.scene.input.off(Phaser.Input.Events.POINTER_DOWN);
    this.thoughtBubble.bubble.off(Phaser.Input.Events.POINTER_DOWN);
    super.destroy(fromScene);
  }
}
