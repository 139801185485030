import i18next, { t } from "i18next";
import { setLanguage } from "../state/app-state";
import { info } from "../helpers/loggerHelper";
import { CopyKey } from "./keys";

const DEFAULT_NS = "translation";

export enum Language {
  English = "en",
}

export function translate(key: CopyKey) {
  return t(key);
}

export const initializeTranslations = async () => {
  await i18next.init({
    lng: "en",
    defaultNS: DEFAULT_NS,
    resources: {},
  });
  await loadLanguage(Language.English);
  setLanguage(Language.English);
};

const loadLanguage = async (lang: string) => {
  if (i18next.hasResourceBundle(lang, DEFAULT_NS)) {
    return;
  }
  info(`Loading ${lang} language translation`);
  const resp = await fetch(`translations/${lang}.json`);
  const translation = await resp.json();
  i18next.addResourceBundle(lang, DEFAULT_NS, translation);
};

export const changeLanguage = async (lang: string) => {
  await loadLanguage(lang);
  await i18next.changeLanguage(lang);
  setLanguage(lang);
};
