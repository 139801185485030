import { IsUserData, IUserData } from "./portal/type";
import { setUser } from "./state/app-state";
import { info } from "./helpers/loggerHelper";

const USER_KEY: string = "agec_local_user";

export const logOutUser = () => {
  clearLocalUser();
  setUser(null);
};

export const logInUser = (user: IUserData) => {
  if (!user) {
    return;
  }
  setLocalUser(user);
  setUser(user);
};

const clearLocalUser = () => {
  info("Clearing local user");
  localStorage.removeItem(USER_KEY);
};

const setLocalUser = (user: IUserData) => {
  if (!user) {
    return;
  }
  info(`Setting local user: ${user}`);
  localStorage.setItem(USER_KEY, JSON.stringify(user));
};

export const getLocalUser = (): IUserData | null => {
  const data = localStorage.getItem(USER_KEY);
  if (data === null) {
    return null;
  }
  const obj = JSON.parse(data);
  return IsUserData(obj) ? obj : null;
};
