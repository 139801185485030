import { AppClient } from "./api-gen";
import { IGameSave, IPortalAPI, IUserData } from "./type";

// TODO: Get the proper ID of the game from Realm, we're using a random one in the meantime
const AGECGameID: string = "8adfd77e-0043-4a90-9fbb-11f3a18ca01d";

export class PortalAPIProd implements IPortalAPI {
  private client: AppClient;

  constructor(baseURL: string) {
    this.client = new AppClient({ BASE: baseURL });
  }

  getUser = async (): Promise<IUserData> => {
    const resp = await this.client.gameData.getCurrentUser();
    return {
      displayName: `${resp.firstName} ${resp.lastName}`,
      userId: resp.userId,
      email: resp.email,
    };
  };

  // Download game data from the backend
  loadGame = async (): Promise<IGameSave> => {
    const resp = await this.client.gameData.getUserGameData(AGECGameID);
    return {
      score: resp.score,
      data: JSON.parse(resp.data),
      completed: resp.completed,
    };
  };

  // Upload game data to the backend
  saveGame = async (game: IGameSave): Promise<void> => {
    await this.client.gameData.saveUserGameData(AGECGameID, {
      gameId: AGECGameID,
      score: game.score,
      data: JSON.stringify(game.data),
      completed: game.completed,
    });
  };
}
