import { html, css } from "lit";
import { customElement } from "lit/decorators.js";
import { LitElementI18n } from "../translations/lit-mixin";
import { Screen, setScreen } from "../state/app-state";

@customElement("game-over")
export class GameOver extends LitElementI18n {
  static styles = [
    css`
      :host {
        width: 100%;
        height: 100%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .container {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-items: start;
        font-size: 200%;
      }
      button {
        min-height: 10px;
        margin-top: 50px;
      }
      .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 80px;
      }
    `,
  ];

  toMainMenu = () => {
    setScreen(Screen.MainMenu);
  };

  render() {
    return html`
      <div class="container">
        <div class="main">
          <p>Allan Gray Entrepreneurship Challenge</p>
          <h2>Game Over!</h2>
          <button class="primary" @click=${this.toMainMenu}>
            Go to main menu
          </button>
        </div>
      </div>
    `;
  }
}
