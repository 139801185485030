import { Signal, signal } from "@lit-labs/preact-signals";
import { IEventParams } from "../config/gameplayParameters";

export const maxIntroPage = 7;
export const selectBusinessTypePage = 6;

export interface IGameState {
  complete: Signal<boolean>;
  paydayBarPercentage: Signal<number>;
  eventQueue: Signal<IEventParams[]>;
  displayLevelUp: Signal<boolean>;
  displayFinancialReport: Signal<boolean>;
  isPaused: Signal<boolean>;
  isHorizontal: Signal<boolean>;
  isPageFocused: Signal<boolean>;
  introPage: Signal<number>;
  totalSalariesDue: Signal<number>;
  overdueSalaries: Signal<number>;
  paydayCount: Signal<number>;
}

const complete = signal(false);
const paydayBarPercentage = signal(0);
const displayFinancialReport = signal(false);
const eventQueue = signal([] as IEventParams[]);
const displayLevelUp = signal(false);
const isPaused = signal(false);
const isHorizontal = signal(false);
const introPage = signal(1);
const totalSalariesDue = signal(0);
const overdueSalaries = signal(0);
const isPageFocused = signal(true);
const paydayCount = signal(1);

export const gameState: IGameState = {
  complete,
  paydayBarPercentage,
  displayFinancialReport,
  eventQueue,
  displayLevelUp,
  isPaused,
  isHorizontal,
  isPageFocused,
  introPage,
  totalSalariesDue,
  overdueSalaries,
  paydayCount,
};

export const gameComplete = () => (complete.value = true);

export const setPaydayBarProgress = (normalizedValue: number) => {
  // Translate to %
  paydayBarPercentage.value = normalizedValue * 100;
};
export const enableFinancialReport = (value: boolean) =>
  (displayFinancialReport.value = value);

export const queueEvent = (event: IEventParams) => {
  eventQueue.value = [...eventQueue.value, event];
};

export const clearEvent = () => {
  eventQueue.value = eventQueue.value.filter((_, i) => i);
};

export const hasEvent = () => {
  return !!eventQueue.value.length;
};

export const enableDisplayLevelUp = (value: boolean) =>
  (displayLevelUp.value = value);

export const setIsPaused = (value: boolean) => {
  isPaused.value = value;
};

export const togglePause = () => {
  setIsPaused(!isPaused.value);
};

export const setIsHorizontal = (value: boolean) => {
  isHorizontal.value = value;
};

export const setTotalSalariesDue = (value: number) => {
  totalSalariesDue.value = value;
};

export const setOverdueSalaries = (value: number) => {
  overdueSalaries.value = value;
};

export const setIsPageFocused = (value: boolean) => {
  isPageFocused.value = value;
};
export const setNextIntroPage = () => introPage.value++;
export const skipIntro = () => (introPage.value = selectBusinessTypePage);
export const isIntroComplete = () => introPage.value > maxIntroPage;
export const resetIntroPage = () => (introPage.value = 1);
export const skipIntroFlow = () => (introPage.value += maxIntroPage);

export const setPayday = (value: number) => (paydayCount.value = value);
