import { CustomersController } from "./customerController";
import { ShopController } from "./shopController";
import { EmployeeController } from "./employeeController";
import { GameModel } from "../models/gameModel";
import { GameViewStub, IGameView } from "../views/gameView";
import { Subcontroller } from "./subcontroller";
import {
  IGameplayParams,
  InitialGameplayParams,
} from "../config/gameplayParameters";
import {
  enableDisplayLevelUp,
  setIsPageFocused,
  setIsPaused,
  setPaydayBarProgress,
  isIntroComplete,
  skipIntroFlow,
  resetIntroPage,
  setPayday,
} from "../state/game-state";
import { enableFinancialReport } from "../state/game-state";
import { Screen, setSaving, setScreen } from "../state/app-state";
import { info } from "../helpers/loggerHelper";
import { Employee } from "../models/employee";
import { ProductGroup, ProductKind } from "../data/products";
import { Product } from "../models/product";
import { ModelReducer } from "../models/modelReducer";
import { isDevEnv } from "../env";
import { IntervalTimer } from "../timers/interval_timer";
import {
  subscribeToPageVisibility,
  unsubscribeFromPageVisibility,
} from "../helpers/visibilityHelper";
import { GameStartEvent } from "../analytics/analytics";
import { exitFullscreen, goFullscreen } from "../helpers/fullscreenHelper";
import { clamp } from "../helpers/mathHelper";
import { SaveManager, saveManager } from "../state/save-state";

// GameController is responsible for handling game logic and coordination
// between other game systems.
export class GameController {
  public gameplayParams: IGameplayParams;

  private model: GameModel;
  private view: IGameView;

  private customersController: CustomersController;
  private shopController: ShopController;
  private employeesController: EmployeeController;
  private subcontrollers: Subcontroller[];

  private loading: boolean = false;
  private ready: boolean = false;

  private aiThoughtTimer: NodeJS.Timer;

  private paydayIntervalMs: number;

  private modelSubscriptions: ((model: GameModel) => void)[];

  private saveManager: SaveManager;
  private modelReducer: ModelReducer;

  private maxCustomersSpawned: number;

  // Timer to manage when the game gets saved
  private saveTimer: IntervalTimer;

  constructor(
    gameplayParams: IGameplayParams,
    model: GameModel,
    view?: IGameView,
  ) {
    this.saveManager = saveManager;

    this.model = model;
    this.view = view ?? new GameViewStub();
    this.gameplayParams = gameplayParams;

    this.initializeSubcontrollers();
    this.cacheMaxCustomers();

    this.modelSubscriptions = [];
    this.modelReducer = new ModelReducer(
      this.model,
      this.gameplayParams.events,
    );

    this.saveTimer = new IntervalTimer(
      this.handleSaveTimerTick,
      60000, // every minute
    );
  }

  private handleSaveTimerTick = () => {
    info("Autosaving...");
    this.save();
  };

  private initializeSubcontrollers = () => {
    this.subcontrollers = [];
    this.shopController = new ShopController(
      this.model,
      this.view,
      InitialGameplayParams,
    );
    this.subcontrollers.push(this.shopController);

    this.customersController = new CustomersController(
      this.model,
      this.view,
      this.shopController,
      this.gameplayParams,
    );
    this.subcontrollers.push(this.customersController);

    this.employeesController = new EmployeeController(
      this.model,
      this.view,
      this.gameplayParams,
    );
    this.subcontrollers.push(this.employeesController);
  };

  getModel = (): GameModel => this.model;
  setModel = (model: GameModel) => {
    this.model = model;
    this.subcontrollers.forEach((sc) => sc.setModel(this.model));
    this.view.syncToModel(this.model);
    this.modelSubscriptions.forEach((cb) => cb(this.model));
    this.modelReducer = new ModelReducer(
      this.model,
      this.gameplayParams.events,
    );
  };

  setView = (view: IGameView) => {
    this.view = view;
    this.subcontrollers.forEach((sc) => sc.setView(this.view));
    this.view.syncToModel(this.model);
  };

  startGame = (newGame: boolean) => {
    goFullscreen();
    GameStartEvent(newGame ? "new_game" : "continuation");
    this.subcontrollers.forEach((sc) => sc.startGame(newGame));
    this.paydayIntervalMs = this.gameplayParams.payday.secondsPerPayday * 1000;

    if (isDevEnv) {
      // Only re-calculate cache in a dev environment
      this.cacheMaxCustomers();
    }

    if (newGame) {
      // start the shop off with one employee
      this.hire(true);
      this.model.getPayday().setPaydayCounter(this.paydayIntervalMs);
    }

    // Run the decision-making AI on each of the customers and employees
    // every tenth of a second.
    this.aiThoughtTimer = setInterval(() => {
      if (this.active()) {
        this.customersController.thinkAll();
      }
    }, 100);

    this.ready = true;
    setScreen(Screen.Game);
    subscribeToPageVisibility(
      "gameController",
      this.onPageHidden,
      this.onPageShown,
    );
  };

  exitGame = () => {
    exitFullscreen();
    this.save();
    // Stop the game tick
    this.ready = false;
    this.view.destroyAll();
    unsubscribeFromPageVisibility("gameController");
    clearInterval(this.aiThoughtTimer);
  };

  // Indicates if the game is active.
  // Things like viewing story pages will make the game inactive.
  active = () => isIntroComplete() && !this.loading && this.ready;

  updateGameplayParams = () => {
    if (
      this.paydayIntervalMs !==
      this.gameplayParams.payday.secondsPerPayday * 1000
    ) {
      this.paydayIntervalMs =
        this.gameplayParams.payday.secondsPerPayday * 1000;
      this.model.getPayday().setPaydayCounter(this.paydayIntervalMs); // reset when changing the seconds per payday
    }
    this.customersController.updateParams(this.gameplayParams);
    this.shopController.updateParams(this.gameplayParams);
    this.employeesController.updateParams(this.gameplayParams);
    this.modelReducer.updateEventParams(this.gameplayParams.events);
  };

  // Hire a new employee if you have enough money.
  // The return value indicates if the employee was successfully hired.
  hire = (free: boolean = false): boolean => {
    const cost = free ? 0 : this.model.getEmployeeHiringCost();
    if (!this.model.canPay(cost)) {
      // Can't hire if you can't pay
      return false;
    }
    const tillID = this.shopController.addTill();
    if (!tillID) {
      return false;
    }
    this.model.payEmployee(cost);
    this.employeesController.hire(tillID, free);

    this.model.checkTotalSalariesDue();

    this.levelUp();
    return true;
  };

  levelUp = () => {
    if (this.shopController.levelUp()) {
      enableDisplayLevelUp(true);
    }
  };

  setBusinessType = (businessType: ProductGroup) => {
    this.shopController.setBusinessType(businessType);
    this.customersController.createFirstCustomerSpawnTimer();
  };

  getNextAvailableProductIdx = (): number => {
    return this.model.getShop().getNextProductIndex();
  };

  launchProduct = () => {
    const newProduct = this.shopController.launchProduct();
    if (newProduct) {
      this.customersController.onProductLaunched(newProduct);
      this.levelUp();
    }
  };

  buyStock = (product: Product) => {
    if (
      product.stock.value < product.stockLimit &&
      this.model.buyStock(product.cost, product.kind)
    ) {
      product.stock.value++;
    }
  };

  promoteProduct = (product: Product) => {
    if (product.demand >= this.gameplayParams.marketing.maxDemand) return;
    const cost = product.getMarketingCost(
      this.gameplayParams.marketing.cost,
      this.gameplayParams.marketing.increasePerLevel,
    );
    if (this.model.promoteProduct(cost, product.kind)) {
      product.demand++;
      this.customersController.onProductPromoted(product);
    }
  };

  setProductPrice = (product: Product, price: number) => {
    if (price > product.maxPrice || price < product.cost + 1) return;
    product.price = price;
  };

  tickPaydayCounter = (delta: number) => {
    const counterMs = this.model.getPayday().decreasePaydayCounter(delta);
    setPaydayBarProgress(Math.max(counterMs / this.paydayIntervalMs, 0));
    if (counterMs <= 0) {
      this.endPayday();
    }
  };

  endPayday = () => {
    setIsPaused(true);
    this.displayFinancialReport();
    ModelReducer.onModelUpdate(this.model);
    this.save();
  };

  displayFinancialReport = () => {
    this.setCustomersLostDueToNoMarketing();
    enableFinancialReport(true);
  };

  dismissFinancialReport = () => {
    enableFinancialReport(false);
    if (this.model.getPayday().getPaydayCounter() <= 0) {
      this.startNextPayday();
    }
  };

  setCustomersLostDueToNoMarketing = () => {
    const paydayScale = clamp(
      1 - this.model.getPayday().getPaydayCounter() / this.paydayIntervalMs,
      0,
      1,
    );
    this.model
      .getShop()
      .getProducts()
      .forEach((p) => {
        this.model.getFinancialReportModel().getProduct(p.kind).no_marketing =
          Math.max(
            Math.floor(
              (this.getMaxCustomersForProduct(p.kind) -
                this.model
                  .getFinancialReportModel()
                  .getCustomerCount(p.kind, false)) *
                paydayScale,
            ),
            0,
          );
      });
  };

  getMaxCustomersForProduct = (kind: ProductKind) => {
    const productMultiplier = this.gameplayParams.products.find(
      (group) => group.category === kind.group,
    ).products[kind.index].spawnRateMultiplier;
    return Math.floor(this.maxCustomersSpawned / productMultiplier);
  };

  private cacheMaxCustomers = () => {
    const { secondsPerPayday } = this.gameplayParams.payday;
    const { spawnInterval, spawnIntervalDecrease } =
      this.gameplayParams.customer;
    const { maxDemand } = this.gameplayParams.marketing;
    this.maxCustomersSpawned =
      secondsPerPayday / (spawnInterval - maxDemand * spawnIntervalDecrease);
  };

  startNextPayday = () => {
    setIsPaused(false);
    this.model.getPayday().setPaydayCounter(this.paydayIntervalMs);
    this.employeesController.idleAll();
    const paydayCount = this.model.getPayday().incrementPayday();

    // UPDATE UI
    setPayday(paydayCount);

    ModelReducer.onModelUpdate(this.model);
    this.model.getFinancialReportModel().clearReportData(paydayCount);
    ModelReducer.onModelUpdate(this.model);

    if (isDevEnv) {
      // Only re-calculate cache in a dev environment
      this.cacheMaxCustomers();
    }

    if (paydayCount === this.gameplayParams.payday.paydayCount) {
      this.exitGame();
      // TODO: pass score to app state
      setScreen(Screen.GameOver);
    }
    this.save();
  };

  payEmployee = (employee: Employee) => {
    this.employeesController.payEmployee(employee);
  };

  tick = (delta: number) => {
    if (this.active()) {
      this.saveTimer.tick(delta);
      this.employeesController.tickAll(delta);
      this.customersController.tickAll(delta);
      this.shopController.tick();
      this.tickPaydayCounter(delta);
    }
  };

  save = () => {
    setSaving(true);
    this.saveManager.save(this.model.toJSON());
    setSaving(false);
  };

  load = async (local: boolean) => {
    skipIntroFlow();

    this.loading = true;

    let data: object;
    if (local) {
      info("Loading local save");
      data = this.saveManager.localSave()?.data;
    } else {
      info("Loading cloud save");
      data = this.saveManager.cloudSave()?.data;
    }
    this.setModel(GameModel.fromJSON(data));
    this.startGame(false);

    this.loading = false;
  };

  startNewGame = () => {
    resetIntroPage();

    this.setModel(new GameModel());
    this.startGame(true);
  };

  onPageHidden = () => {
    setIsPageFocused(false);
    this.save();
  };

  onPageShown = () => {
    setIsPageFocused(true);
  };
}
