import { CustomerGameObject } from "../game-objects/customer";
import Phaser from "phaser";
import { Customer } from "../models/customer";
import { Employee } from "../models/employee";
import { EmployeeGameObject } from "../game-objects/employee";
import { ShopGameObject } from "../game-objects/shop";
import { Shop } from "../models/shop";
import { GameModel } from "../models/gameModel";

export interface IGameView {
  updateShop: (shop: Shop) => void;
  get shop(): ShopGameObject;
  destroyShop: () => void;
  createCustomer: (c: Customer) => void;
  updateCustomer: (c: Customer) => void;
  destroyCustomer: (id: string) => void;
  addEmployee: (e: Employee, i: number) => void;
  updateEmployee: (e: Employee) => void;
  destroyAll: () => void;
  syncToModel: (model: GameModel) => void;
}

// GameView keeps instances of all game objects linked to the model and
// manages adding and removing them from the scene.
export class GameView implements IGameView {
  private readonly scene: Phaser.Scene;
  private customerObjects: { [id: string]: CustomerGameObject };
  private employeeObjects: { [id: string]: EmployeeGameObject };
  private shopObject: ShopGameObject;

  constructor(scene: Phaser.Scene) {
    this.scene = scene;
    this.customerObjects = {};
    this.employeeObjects = {};
  }

  updateShop = (shop: Shop) => {
    this.destroyShop();
    if (!shop) {
      return;
    }
    this.shopObject = new ShopGameObject(
      this.scene,
      shop.getLevel(),
      shop.nextLaunchProduct(),
      shop.nextHireEmployeePosition(),
    );
    shop.getTills().forEach(this.shopObject.createTill);

    shop.getProducts().forEach((product) => {
      this.shopObject.createProduct(product);
    });
  };
  public get shop() {
    return this.shopObject;
  }

  destroyShop = () => {
    this.shopObject?.destroy();
    this.shopObject = undefined;
  };

  createCustomer = (customer: Customer) => {
    const c = new CustomerGameObject(this.scene, customer);
    this.scene.add.existing(c);
    this.customerObjects[customer.id] = c;
  };

  updateCustomer = (customer: Customer) => {
    this.customerObjects[customer.id]?.updateWithModel(customer);
  };

  destroyCustomer = (id: string) => {
    this.customerObjects[id]?.destroy(true);
    delete this.customerObjects[id];
  };

  addEmployee = (employee: Employee, spriteIndex: number) => {
    const e = new EmployeeGameObject(this.scene, employee, spriteIndex);
    this.scene.add.existing(e);
    this.employeeObjects[employee.id] = e;
  };

  updateEmployee = (employee: Employee) => {
    this.employeeObjects[employee.id]?.updateWithModel(employee);
  };

  syncToModel = (model: GameModel) => {
    this.destroyAll();
    this.updateShop(model.getShop());
    model.allEmployees().forEach(this.addEmployee);
    model.allCustomers().forEach(this.createCustomer);
  };

  destroyAll = () => {
    this.destroyShop();
    Object.values(this.customerObjects).forEach((c) => c.destroy(true));
    this.customerObjects = {};
    Object.values(this.employeeObjects).forEach((e) => e.destroy(true));
    this.employeeObjects = {};
  };
}

export class GameViewStub implements IGameView {
  updateShop = () => {
    // do nothing
  };
  get shop(): ShopGameObject {
    return undefined;
  }
  destroyShop = () => {
    // do nothing
  };
  createCustomer = () => {
    // do nothing
  };
  updateCustomer = () => {
    // do nothing
  };
  destroyCustomer = () => {
    // do nothing
  };
  addEmployee = () => {
    // do nothing
  };
  updateEmployee = () => {
    // do nothing
  };
  destroyAll = () => {
    // do nothing
  };
  syncToModel = () => {
    // do nothing
  };
}
