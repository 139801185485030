import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import { gameDialogStyles } from "../styles/game-dialog";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { playSoundEffect } from "../sound/sound-manager";

@customElement("hire-dialog")
export class HireDialog extends LitElement {
  static styles = [
    gameDialogStyles,
    css`
      .content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }

      .buttons {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
      }

      button {
        height: 50%;
        background-color: lightblue;
        min-width: 20%;
      }
    `,
  ];

  @consume({ context: gameContext })
  game: GameController;

  onYes = () => {
    this.dispatchEvent(new CustomEvent("accept"));
    playSoundEffect("Purchase");
  };
  onNo = () => {
    this.dispatchEvent(new CustomEvent("cancel"));
  };

  render() {
    return html`
      <dialog open>
        <div class="content">
          <div>Do you want to hire another employee?</div>
        </div>
        <div class="buttons">
          <button @click=${this.onNo}>No</button>
          <button autofocus @click=${this.onYes}>
            Yes (${this.game.getModel().getEmployeeHiringCost()})
          </button>
        </div>
      </dialog>
    `;
  }
}
