import { GameModel } from "../models/gameModel";
import { IGameView } from "../views/gameView";

export abstract class Subcontroller {
  protected model: GameModel;
  protected view: IGameView;

  constructor(model: GameModel, view: IGameView) {
    this.model = model;
    this.view = view;
  }

  public setModel = (model: GameModel) => {
    this.model = model;
  };
  public setView = (view: IGameView) => {
    this.view = view;
  };

  abstract startGame(newGame: boolean): void;
}
