export class Payday {
  /** Time left until the end of the payday, in ms */
  private paydayCounterMs: number;
  /** Number of paydays played */
  private paydayCount: number;

  constructor(paydayCounterMs: number, paydayCount?: number) {
    this.paydayCounterMs = paydayCounterMs;
    this.paydayCount = paydayCount ?? 0;
  }

  getPaydayCounter = () => this.paydayCounterMs;
  decreasePaydayCounter = (ms: number) => (this.paydayCounterMs -= ms);
  setPaydayCounter = (t: number) => (this.paydayCounterMs = t);

  incrementPayday = () => (this.paydayCount += 1);
  getPaydayCount = () => this.paydayCount;

  toJSON = (): object => ({
    paydayCounterMs: this.paydayCounterMs,
    paydayCount: this.paydayCount,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJSON = (obj: any): Payday => {
    return new Payday(obj.paydayCounterMs, obj.paydayCount);
  };
}
