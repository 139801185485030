import { css } from "lit";

export const gameDialogStyles = css`
  :host {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
  }
  dialog {
    width: 70%;
    height: 70%;

    display: flex;
    flex-direction: column;
  }
  .content {
    width: 100%;
    height: 50%;
  }
  .buttons {
    width: 100%;
    height: 50%;
  }
`;
