import { warn } from "../helpers/loggerHelper";
import { playSoundEffect } from "../sound/sound-manager";

export class EventsModel {
  private completedEvents: { [key: string]: boolean };

  constructor(events?: { [key: string]: boolean }) {
    this.completedEvents = events ?? {};
  }

  public setEventCompleted = (key: string) => {
    this.completedEvents[key] = true;
    playSoundEffect("Notification");
  };

  public isEventCompleted = (key: string): boolean => {
    return !!this.completedEvents[key];
  };

  toJSON = () => this.completedEvents;

  static fromJSON = (obj: unknown): EventsModel => {
    if (
      Object.entries(obj).find(
        ([key, value]) => typeof key !== "string" || typeof value !== "boolean",
      )
    ) {
      warn("[EventsModel.fromJSON] Passed object's type wasn't correct", {
        obj,
      });
      return new EventsModel();
    }
    return new EventsModel(obj as { [key: string]: boolean });
  };
}
