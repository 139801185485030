import { productGroupInfoMap } from "../data/products";

export interface IProductParams {
  id: string;
  name: string;
  cost: number;
  maxCost: number;
  stockLimit: number;
  spawnRateMultiplier: number;
}

export interface IProductCategoryParams {
  category: string;
  products: IProductParams[];
}

export enum EventTriggerCategories {
  ShopLevel = "Shop Level x",
  Cash = "Cash = x",
  BusinessValue = "Business Value = x",
  Profit = "Profit by x payday",
  Loss = "Loss by x payday",
  Staff = "Hired x Staff",
  ProductLaunch = "Product x Launched",
  LostSalePrice = "Lost x Sales due to Price",
  LostSaleStock = "Lost x Sales due to No Stock",
  LostSaleStaff = "Lost x Sales due to No Staff",
  LostSaleMarketing = "Lost x Sales due to No Marketing",
  StaffIdleTime = "Staff Idling for x Seconds",
  Sales = "Sale No. x",
  CanLaunchBy = "Can Launch Next Product By x",
  CanHireBy = "Can Hire Next Staff By x",
  Payday = "Payday x",
}

interface EventTrigger {
  category: EventTriggerCategories;
  value: number;
}

export interface IEventParams {
  id: string;
  trigger: EventTrigger;
  cash?: number;
  businessValue?: number;
}

export interface IGameplayParams {
  customer: {
    spawnInterval: number;
    spawnIntervalDecrease: number;
    speed: number;
  };
  staff: {
    orderTakingSpeed: number;
    orderFetchingSpeed: number;
    orderFinishingSpeed: number;
    orderSpeedMultiplier: number;
    speed: number;
    hiringCost: number;
    multiplierPerStaff: number;
  };
  marketing: {
    cost: number;
    increasePerLevel: number;
    maxDemand: number;
  };
  products: IProductCategoryParams[];
  payday: {
    secondsPerPayday: number;
    paydayCount: number;
  };
  events: IEventParams[];
}

export interface ISerializedParamsJson {
  version: number;
  timestamp: string;
  params: IGameplayParams;
}

// Increment if there are breaking changes to the scheme of the Gameplay Params
export const GameplayParamsVersion = 1;

const getInitialProductParams = (): IProductCategoryParams[] => {
  const allProductCategories: IProductCategoryParams[] = [];

  Object.entries(productGroupInfoMap).forEach(([c, p]) => {
    const products: IProductParams[] = [];
    p.forEach((item, index) => {
      const product = {
        id: `product_${c}_${index}`,
        name: item,
        cost: 5,
        maxCost: 15,
        stockLimit: 8,
        spawnRateMultiplier: 1,
      };

      products.push(product);
    });
    const category: IProductCategoryParams = {
      category: c,
      products: products,
    };
    allProductCategories.push(category);
  });

  return allProductCategories;
};

export const InitialGameplayParams: IGameplayParams = {
  customer: {
    spawnInterval: 15,
    spawnIntervalDecrease: 0.2,
    speed: 500,
  },
  staff: {
    orderTakingSpeed: 1,
    orderFetchingSpeed: 1,
    orderFinishingSpeed: 1,
    orderSpeedMultiplier: 1,
    speed: 500,
    hiringCost: 15,
    multiplierPerStaff: 2,
  },
  marketing: {
    cost: 1,
    increasePerLevel: 0.5,
    maxDemand: 50,
  },
  products: getInitialProductParams(),
  payday: {
    secondsPerPayday: 136.0,
    paydayCount: 12,
  },
  events: [
    //SHOP LEVEL UPGRADES
    {
      id: "shop-2",
      trigger: {
        category: EventTriggerCategories.ShopLevel,
        value: 2,
      },
    },
    {
      id: "shop-3",
      trigger: {
        category: EventTriggerCategories.ShopLevel,
        value: 3,
      },
    },
    {
      id: "shop-4",
      trigger: {
        category: EventTriggerCategories.ShopLevel,
        value: 4,
      },
    },
    {
      id: "shop-5",
      trigger: {
        category: EventTriggerCategories.ShopLevel,
        value: 5,
      },
    },
    //FIRST PROFIT/LOSS
    {
      id: "first-profit",
      trigger: {
        category: EventTriggerCategories.Profit,
        value: 3,
      },
    },
    {
      id: "first-loss",
      trigger: {
        category: EventTriggerCategories.Loss,
        value: 3,
      },
    },
    //STAFF HIRES
    {
      id: "hired-1st-staff",
      trigger: {
        category: EventTriggerCategories.Staff,
        value: 2,
      },
    },
    {
      id: "hired-2nd-staff",
      trigger: {
        category: EventTriggerCategories.Staff,
        value: 3,
      },
    },
    {
      id: "hired-3rd-staff",
      trigger: {
        category: EventTriggerCategories.Staff,
        value: 4,
      },
    },
    {
      id: "hired-4th-staff",
      trigger: {
        category: EventTriggerCategories.Staff,
        value: 5,
      },
    },
    {
      id: "hired-5th-staff",
      trigger: {
        category: EventTriggerCategories.Staff,
        value: 6,
      },
    },
    {
      id: "hired-6th-staff",
      trigger: {
        category: EventTriggerCategories.Staff,
        value: 7,
      },
    },
    {
      id: "hired-7th-staff",
      trigger: {
        category: EventTriggerCategories.Staff,
        value: 8,
      },
    },
    //PRODUCTS LAUNCHED
    {
      id: "product-1-added",
      trigger: {
        category: EventTriggerCategories.ProductLaunch,
        value: 1,
      },
    },
    //LOST SALES
    {
      id: "lost-sale-price",
      trigger: {
        category: EventTriggerCategories.LostSalePrice,
        value: 0,
      },
    },
    {
      id: "lost-sale-no-stock",
      trigger: {
        category: EventTriggerCategories.LostSaleStock,
        value: 0,
      },
    },
    {
      id: "lost-sale-no-staff",
      trigger: {
        category: EventTriggerCategories.LostSaleStaff,
        value: 0,
      },
      cash: 30,
    },
    {
      id: "lost-sale-no-marketing",
      trigger: {
        category: EventTriggerCategories.LostSaleMarketing,
        value: 0,
      },
    },
    //LOST SALES N
    {
      id: "lost-sale-price-n",
      trigger: {
        category: EventTriggerCategories.LostSalePrice,
        value: 0,
      },
    },
    {
      id: "lost-sale-no-stock-n",
      trigger: {
        category: EventTriggerCategories.LostSaleStock,
        value: 0,
      },
    },
    {
      id: "lost-sale-no-staff-n",
      trigger: {
        category: EventTriggerCategories.LostSaleStaff,
        value: 0,
      },
    },
    {
      id: "lost-sale-no-marketing-n",
      trigger: {
        category: EventTriggerCategories.LostSaleMarketing,
        value: 0,
      },
    },
    //BUSINESS VALUE
    {
      id: "business-value-1",
      trigger: {
        category: EventTriggerCategories.BusinessValue,
        value: 0,
      },
      businessValue: 1000,
    },
    {
      id: "business-value-2",
      trigger: {
        category: EventTriggerCategories.BusinessValue,
        value: 0,
      },
    },
    {
      id: "business-value-3",
      trigger: {
        category: EventTriggerCategories.BusinessValue,
        value: 0,
      },
    },
    {
      id: "business-value-4",
      trigger: {
        category: EventTriggerCategories.BusinessValue,
        value: 0,
      },
    },
    {
      id: "business-value-5",
      trigger: {
        category: EventTriggerCategories.BusinessValue,
        value: 0,
      },
    },
    {
      id: "business-value-6",
      trigger: {
        category: EventTriggerCategories.BusinessValue,
        value: 0,
      },
    },
    //CASH VALUE
    {
      id: "cash-value",
      trigger: {
        category: EventTriggerCategories.Cash,
        value: -5,
      },
    },
    //SALARY DUE
    {
      id: "salary-first-due-1-second",
      trigger: {
        category: EventTriggerCategories.StaffIdleTime,
        value: 1,
      },
      cash: 30,
    },
    {
      id: "salary-first-due-30-seconds",
      trigger: {
        category: EventTriggerCategories.StaffIdleTime,
        value: 30,
      },
    },
    //NUMBER OF SALES = 1
    {
      id: "number-of-sales-equals-1",
      trigger: {
        category: EventTriggerCategories.Sales,
        value: 1,
      },
    },
    //NUMBER OF SALES = N
    {
      id: "number-of-sales-equals-n",
      trigger: {
        category: EventTriggerCategories.Sales,
        value: 0,
      },
    },
    //NEXT PRODUCT/STAFF
    {
      id: "cash-cost-of-next-product-x",
      trigger: {
        category: EventTriggerCategories.CanLaunchBy,
        value: 0,
      },
    },
    {
      id: "cash-cost-of-next-staff-x",
      trigger: {
        category: EventTriggerCategories.CanHireBy,
        value: 0,
      },
    },
    //AFTER PAYDAY
    //NOTE: CURRENTLY ONLY PAYDAY 1/2/6/11 SHOULD TRIGGER AN EVENT
    {
      id: "payday-1",
      trigger: {
        category: EventTriggerCategories.Payday,
        value: 1,
      },
    },
    {
      id: "payday-2",
      trigger: {
        category: EventTriggerCategories.Payday,
        value: 2,
      },
    },
    {
      id: "payday-3",
      trigger: {
        category: EventTriggerCategories.Payday,
        value: 0,
      },
    },
    {
      id: "payday-4",
      trigger: {
        category: EventTriggerCategories.Payday,
        value: 0,
      },
    },
    {
      id: "payday-5",
      trigger: {
        category: EventTriggerCategories.Payday,
        value: 0,
      },
    },
    {
      id: "payday-6",
      trigger: {
        category: EventTriggerCategories.Payday,
        value: 6,
      },
    },
    {
      id: "payday-7",
      trigger: {
        category: EventTriggerCategories.Payday,
        value: 0,
      },
    },
    {
      id: "payday-8",
      trigger: {
        category: EventTriggerCategories.Payday,
        value: 0,
      },
    },
    {
      id: "payday-9",
      trigger: {
        category: EventTriggerCategories.Payday,
        value: 0,
      },
    },
    {
      id: "payday-10",
      trigger: {
        category: EventTriggerCategories.Payday,
        value: 0,
      },
    },
    {
      id: "payday-11",
      trigger: {
        category: EventTriggerCategories.Payday,
        value: 11,
      },
    },
  ],
};
