import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { UIImages } from "../data/images";

@customElement("orientation-change")
export class OrientationChange extends LitElement {
  static styles = css`
    #orientation-change {
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      background-color: var(--violet-lowlight);
      align-items: center;
      text-align: center;
    }

    .rotate-device-img {
        width: 158px;
        height: 178px;
        flex-shrink: 0;
  `;

  render() {
    return html`
      <div id="orientation-change">
        <img class="rotate-device-img" src=${UIImages.RotateDevice} />
      </div>
    `;
  }
}
