import { Customer, WalkAwayReason } from "./customer";
import { Employee } from "./employee";
import { Shop } from "./shop";
import { error } from "../helpers/loggerHelper";
import { Stats } from "./stats";
import { Signal, computed, signal } from "@lit-labs/preact-signals";
import { IGameplayParams } from "../config/gameplayParameters";
import { Payday } from "./payday";
import { FinancialReportModel } from "./financialReportModel";
import { ProductKind } from "../data/products";
import { EventsModel } from "./events";
import { ModelReducer } from "./modelReducer";
import {
  setOverdueSalaries,
  setPayday,
  setTotalSalariesDue,
} from "../state/game-state";

export interface UIWatchableModel {
  gold: Signal<number>;
  businessValue: Signal<number>;
}

function getInitialGold() {
  // For local dev builds, we want a lot of money for quick testing.
  if (__BUILD_ENV__ === "TEST") return 1000;
  return __LOCAL_NETWORK__ ? 100000 : 5;
}

// This class represents the state of the game and contains/manages all the models of the different entities.
export class GameModel {
  readonly stats: Stats;
  readonly events: EventsModel;
  private gold: Signal<number>;
  private businessValue: Signal<number>;
  private shop: Shop;
  private customers: { [id: string]: Customer };
  private employees: { [id: string]: Employee };
  private employeeHiringCost: number = 0;
  private payday: Payday;
  private financialReportModel: FinancialReportModel;

  constructor(
    opts?: {
      gold: number;
      stock?: number;
      achievements?: number;
      payday?: Payday;
      stats?: Stats;
      financialReportModel?: FinancialReportModel;
      shop?: Shop;
      events?: EventsModel;
    },
    params?: IGameplayParams,
  ) {
    this.stats = opts?.stats ?? new Stats();
    this.gold = signal(opts?.gold ?? getInitialGold());
    this.financialReportModel =
      opts?.financialReportModel ?? new FinancialReportModel();
    this.businessValue = computed(
      () =>
        this.gold.value +
        this.financialReportModel.stockValue.value +
        this.financialReportModel.achievementsValue.value,
    );
    this.shop = opts?.shop ?? new Shop(150);
    this.customers = {};
    this.employees = {};
    this.payday =
      opts?.payday ?? new Payday((params?.payday.secondsPerPayday ?? 0) * 1000);

    setPayday(this.payday.getPaydayCount());
    this.events = opts?.events ?? new EventsModel();
  }

  // Pay reduces gold by the amount specified . If there isn't enough gold,
  // it is not subtracted. The return value indicates if the payment was successful.
  private pay = (amount: number): boolean => {
    if (this.gold.value < amount) {
      return false;
    }
    this.gold.value -= amount;
    return true;
  };
  private earn = (amount: number) => {
    if (!amount) return;
    this.gold.value += amount;
  };
  canPay = (amount: number) => {
    return this.gold.value >= amount;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  buyStock = (amount: number, kind: ProductKind) => {
    if (this.pay(amount)) {
      this.financialReportModel.stockValue.value += amount;
      this.financialReportModel.stockCost += amount;
      this.financialReportModel.getProduct(kind);
      ModelReducer.onModelUpdate(this);
      return true;
    }
    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  promoteProduct = (amount: number, kind: ProductKind) => {
    if (this.pay(amount)) {
      this.financialReportModel.marketing += amount;
      ModelReducer.onModelUpdate(this);
      return true;
    }
    return false;
  };

  payEmployee = (amount: number) => {
    if (this.pay(amount)) {
      this.financialReportModel.salaries += amount;
      ModelReducer.onModelUpdate(this);
      return true;
    }
    return false;
  };

  sellProduct = (amount: number, kind: ProductKind) => {
    this.earn(amount);
    this.financialReportModel.sellProduct(amount, kind);
    ModelReducer.onModelUpdate(this);
  };

  loseProductSale = (kind: ProductKind, reason: WalkAwayReason) => {
    this.financialReportModel.loseProductSale(kind, reason);
    ModelReducer.onModelUpdate(this);
  };

  onEvent = (props: { gold?: number; value?: number }) => {
    if (props.value) {
      this.financialReportModel.achievementsValue.value += props.value;
    }
    if (props.gold) {
      this.earn(props.gold);
      this.financialReportModel.achievementsRevenue += props.gold;
    }
    ModelReducer.onModelUpdate(this);
  };

  checkTotalSalariesDue = () => {
    let salaries = 0;
    let unPaidSalaries = 0;

    if (this.allEmployees().length > 0) {
      this.allEmployees().forEach((employee) => {
        if (employee.getCost()) {
          salaries += employee.getCost();
        }
        if (employee.idle && employee.getCost()) {
          unPaidSalaries += employee.getCost();
        }
      });

      setTotalSalariesDue(salaries);
      setOverdueSalaries(unPaidSalaries);
    }
  };

  getGold = () => this.gold.value;
  getBusinessValue = () =>
    this.financialReportModel.achievementsValue.value +
    this.financialReportModel.stockValue.value +
    this.gold.value;

  getPayday = () => this.payday;

  setShop = (s: Shop) => (this.shop = s);
  getShop = (): Shop => this.shop;

  addCustomer = (c: Customer) => (this.customers[c.id] = c);
  customer = (id: string): Customer => this.customers[id];
  allCustomers = (): Customer[] => Object.values(this.customers);
  removeCustomer = (id: string) => delete this.customers[id];

  addEmployee = (e: Employee, hiring = false) => {
    this.employees[e.id] = e;
    if (hiring) ModelReducer.onModelUpdate(this);
    return this.employees[e.id];
  };
  employee = (id: string): Employee => this.employees[id];
  allEmployees = (): Employee[] => Object.values(this.employees);
  removeEmployee = (id: string) => delete this.employees[id];

  getEmployeeHiringCost = () => this.employeeHiringCost;
  setEmployeeHiringCost = (c: number) => (this.employeeHiringCost = c);

  getFinancialReportModel = (): FinancialReportModel => {
    return this.financialReportModel;
  };

  /**
   * A function to get the model's signals that the UI can observe without
   * needing to manually subscribe to state changes.
   * @returns An object made up of the observable signals in the model.
   */
  getUIWatchableModel = (): UIWatchableModel => {
    return {
      gold: this.gold,
      businessValue: this.businessValue,
    };
  };

  toJSON = (): object =>
    JSON.parse(
      JSON.stringify({
        version: 1,
        game: {
          stats: this.stats.toJSON(),
          gold: this.gold.value,
          shop: this.shop.toJSON(),
          employees: Object.values(this.employees).map((e) => e.toJSON()),
          customers: Object.values(this.customers).map((c) => c.toJSON()),
          employeeHiringCost: this.employeeHiringCost,
          payday: this.payday.toJSON(),
          financialReportModel: this.financialReportModel.toJSON(),
          events: this.events.toJSON(),
        },
      }),
    );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJSON = (obj: any): GameModel => {
    if (obj.version === undefined || obj.game === undefined) {
      error("Invalid save file");
      return undefined;
    }
    // TODO: Added code here to migrate JSON when the version gets increased
    // for example:
    // if (obj.version === 1) {
    //   migrateToV2(obj);
    // }

    const model = new GameModel({
      gold: obj.game.gold,
      stock: obj.game.stock,
      achievements: obj.game.achievements,
      stats: Stats.fromJSON(obj.game.stats),
      payday: Payday.fromJSON(obj.game.payday),
      financialReportModel: FinancialReportModel.fromJSON(
        obj.game.financialReportModel,
      ),
      shop: Shop.fromJSON(obj.game.shop),
      events: EventsModel.fromJSON(obj.game.events),
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obj.game.employees.forEach((e: any) => {
      model.addEmployee(Employee.fromJSON(e));
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obj.game.customers.forEach((c: any) => {
      model.addCustomer(Customer.fromJSON(c));
    });
    model.setEmployeeHiringCost(obj.game.employeeHiringCost);
    return model;
  };
}
