import Phaser from "phaser";
import { GameObjectDepth } from "../data/game-objects";
import { GameImages, TillKeys } from "../data/images";

export class HireEmployeeButton extends Phaser.GameObjects.Container {
  private button: Phaser.GameObjects.Image;
  private upgradePip: Phaser.GameObjects.Image;

  constructor(scene: Phaser.Scene) {
    super(scene);

    this.button = this.scene.add.image(
      0,
      0,
      GameImages.TillSheet,
      TillKeys.HireEmployeeButton,
    );
    this.add(this.button);
    this.setDepth(GameObjectDepth.Product);

    this.upgradePip = this.scene.add.image(
      this.button.getTopRight().x,
      this.button.getTopRight().y,
      GameImages.DemandAndUpgradeAlert,
    );
    this.add(this.upgradePip);

    // setup click event
    this.button.setInteractive({
      useHandCursor: true,
    });
    this.scene.input.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown);
  }

  setUpgradePipVisibility = (visible: boolean) => {
    if (this.upgradePip.visible !== visible) {
      this.upgradePip.setVisible(visible);
    }
  };

  private onPointerDown = (
    pointer: Phaser.Input.Pointer,
    currentlyOver: Phaser.GameObjects.GameObject[],
  ) => {
    if (currentlyOver.includes(this.button)) {
      this.emit("click", pointer.worldX, pointer.worldY);
    }
  };

  destroy(fromScene?: boolean): void {
    this.scene.input.off(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown);
    super.destroy(fromScene);
  }
}
