import { css, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { Screen, setScreen } from "../state/app-state";
import { buttonStyle } from "../styles/theme";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { translate } from "../translations/translations";
import { LitElementI18n } from "../translations/lit-mixin";
import { when } from "lit/directives/when.js";
import { SignalWatcher } from "@lit-labs/preact-signals";
import { UIImages } from "../data/images";
import { saveManager } from "../state/save-state";
import { installApp } from "../install";

@customElement("main-menu")
export class MainMenu extends SignalWatcher(LitElementI18n) {
  static styles = [
    buttonStyle,
    css`
      :host {
        width: 100%;
        height: 100%;
        background: url("assets/tiles/BG_pattern_transparent.png") repeat 0 0;
        background-size: 500px 500px;
        background-color: var(--secondary-violet-highlight);

        display: flex;
        align-items: center;
        justify-content: center;
      }

      /* TODO SM-AGEC-332 - add in media queries*/
      .container {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        place-content: center;
        background-color: rgba(255, 255, 255, 0.5);
      }

      .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
      }

      .install-prompt {
        position: absolute;
        bottom: 28px;
        right: 18px;
      }

      .logo {
        width: 180px;
        height: 76px;
      }

      .score-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .score {
        width: 252px;
        height: 84px;
        box-sizing: border-box;
        padding-top: 10px;
        fill: var(--seconday-violet-lowlight);
        stroke-width: 2px;
        stroke: var(--primary-navy);
        position: relative;
        background-size: 100% 100%;
      }

      .score-text {
        color: var(--primary-white);
        text-align: center;
        font-family: Ubuntu;
        font-style: normal;
        line-height: normal;
        margin: 0;
      }
      p.score-text {
        font-size: 14px;
        font-weight: 500;
      }
      h1.score-text {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: -0.144px;
      }

      .score-submit {
        position: absolute;
        bottom: 0;
        margin: 0;
        transform: translateY(50%);
      }

      /* TODO SM-AGEC-332 - We can refactor this into the secondary app button at some point */
      .secondary-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 10px;

        border-radius: 59px;
        border: 2px solid var(--primary-teal-main);
        background-color: var(--primary-teal-highlight);

        color: var(--primary-navy);
        font-family: Ubuntu;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .install-tip {
        position: relative;
        width: 125px;
        background-color: var(--primary-white);
        box-sizing: border-box;
        padding: 9px;
        border-radius: 4px;
        margin-bottom: 12px;

        color: var(--primary-navy);
        text-align: center;
        font-family: Ubuntu;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        @media (min-width: 1000px) {
          margin-bottom: 16px;
        }
      }

      /* Old UI, TODO SM-AGEC-332  remove */
      .dev-ui {
        position: absolute;
        top: 0;
        right: 0;
      }
      .profile {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
      }
      .profile-name {
        padding: 10px;
      }
      .profile-image {
        padding-left: 10px;
      }
    `,
  ];

  @property({ type: String })
  placeholderProfileName: string = "Guest";
  @property({ type: String })
  placeholderProfileImage: string = "assets/icons/profile-placeholder.svg";
  @property({ type: Boolean })
  isSubmitted: boolean;
  @property({ type: Boolean })
  disabled: boolean;

  @state()
  displayDevUI: boolean;

  @consume({ context: gameContext })
  game: GameController;

  submitScoreToLeaderboard() {
    this.isSubmitted = true;
    this.disabled = true;
    //TODO: Add functionality to let the user submit their score
  }

  renderDownloadButton = () => html`
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 6H1L1 18L19 18L19 6H15.5"
        stroke="#16006D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 11L10 1"
        stroke="#16006D"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M10 13L13 10"
        stroke="#16006D"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M10 13L7 10"
        stroke="#16006D"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  `;

  render() {
    return html`
      <div class="container">
        <div class="main">
          <img class="logo" src=${UIImages.LogoHome} />
          ${when(
            !!saveManager.cloudSave(),
            () => html`
              <div
                class="score-container"
                style="margin-bottom: ${this.isSubmitted ? "0" : "16px"};"
              >
                <div
                  class="score"
                  style="background-image: url(${UIImages.ScoreContainer});"
                >
                  <p class="score-text">${translate("HomeScreen_HighScore")}</p>
                  <h1 class="score-text">${saveManager.cloudSave().score}</h1>
                </div>
                <button
                  class="score-submit secondary-button"
                  @click=${this.submitScoreToLeaderboard}
                  ?disabled=${this.isSubmitted == true}
                  style="display: ${this.isSubmitted ? "none" : "block"}"
                >
                  ${translate("HomeScreen_Submit")}
                </button>
              </div>
            `,
          )}

          <app-button
            @click=${() => setScreen(Screen.ChooseSave)}
            text=${translate("HomeScreen_Start")}
          >
          </app-button>
          </div>
          ${when(
            window.installPrompt,
            () => html`
              <div class="install-prompt">
                <div class="install-tip">
                  ${translate("HomeScreen_InstallTip")}
                  <dialog-arrow direction="bottom" no-border></dialog-arrow>
                </div>
                <button class="secondary-button" @click=${installApp}>
                  ${this.renderDownloadButton()}
                  ${translate("HomeScreen_InstallButton")}
                </button>
              </div>
            `,
          )}
        </div>
      </div>
    `;
  }
}
