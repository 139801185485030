import { consume } from "@lit/context";
import { css, html, LitElement, nothing } from "lit";
import { customElement, state } from "lit/decorators.js";
import { GameController } from "../controllers/gameController";
import { gameContext } from "../gameContext";
import { translate } from "../translations/translations";
import { UIImages } from "../data/images";
import { buttonStyle } from "../styles/theme";
import { saveManager, RequestState, saveState } from "../state/save-state";
import { SignalWatcher } from "@lit-labs/preact-signals";
import { GameModel } from "../models/gameModel";

@customElement("choose-save")
export class ChooseSave extends SignalWatcher(LitElement) {
  static styles = [
    buttonStyle,
    css`
      :host {
        width: 100%;
        height: 100%;
        background: var(--secondary-violet-highlight);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .container-row {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: row;
        gap: 20px;
      }

      .save-container {
        position: relative;
        width: 170px;
        height: 228px;
        box-sizing: border-box;
        padding: 10px 16px;

        border-radius: 4px;
        border: 2px solid var(--primary-navy);
        background: var(--primary-white);

        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
      }

      .save-image {
        width: 60px;
        height: 60px;
      }

      .pill-info-container {
        display: block;
        width: 94px;
        height: 18px;
        margin: 4px 0 2px;
      }

      .pill-info {
        width: 100%;
        height: 100%;
        display: grid;
        place-content: center;

        border-radius: 12px;

        color: var(--primary-navy);
        /* Pill copy_small */
        font-family: Ubuntu;
        font-size: 8px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }

      .pill-info.teal {
        background: var(--primary-teal-highlight);
      }

      .pill-info.violet {
        background: var(--secondary-violet-highlight);
      }

      .pill-info.yellow {
        background: var(--secondary-yellow-highlight);
      }

      .pill-info.ginger {
        background: var(--secondary-ginger-highlight);
      }

      .text-container {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-content: center;
      }

      .title {
        color: var(--primary-navy);
        margin: 2.5px 0;
        text-align: center;
        /* Width has to be set manually bc "On the Internet" is too big */
        width: 138px;

        /* Heading 2 */
        font-family: Ubuntu;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }

      .body {
        color: var(--primary-navy, #16006d);
        margin: 2px 0;
        width: 100%;

        /* Body copy_small */
        font-family: Ubuntu;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 200% */
      }

      .body.short {
        text-align: center;
      }

      .body.detailed {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      app-button {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 50%);
      }
    `,
  ];

  @consume({ context: gameContext })
  game: GameController;

  @state()
  hasRetriedFetch: boolean = false;

  newGame() {
    this.game.startNewGame();
  }
  continueLocal() {
    this.game.load(true);
  }
  continueCloud() {
    this.game.load(false);
  }

  connectedCallback() {
    super.connectedCallback();
    saveManager.refreshLocalSave();
    if (saveManager.cloudSaveRequestState() === RequestState.None) {
      saveManager.refreshCloudSave();
    }
  }

  retryCloudFetch = () => {
    this.hasRetriedFetch = true;
    saveManager.refreshCloudSave();
  };

  getSaveValue = (timestamp: Date): string => {
    const now = new Date();
    const differenceMs = Math.abs(timestamp.getTime() - now.getTime());

    const minutes = Math.floor(differenceMs / (1000 * 60));
    const hours = Math.floor(differenceMs / (1000 * 60 * 60));
    const days = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

    if (minutes <= 60) {
      return `${minutes} ${translate("SavedGame_SavedOption_Minutes")}`;
    }
    if (hours <= 24) {
      return `${hours} ${translate("SavedGame_SavedOption_Hours")}`;
    }
    return `${days} ${translate("SavedGame_SavedOption_Days")}`;
  };

  getPaydayAndBusinessValue = (data: unknown): string[] => {
    let payday = 0;
    let businessValue = 0;
    if (data) {
      const gameModel = GameModel.fromJSON(data);
      if (gameModel) {
        payday = gameModel.getPayday().getPaydayCount();
        businessValue = gameModel.getBusinessValue();
      }
    }
    return [`${payday}/12`, `${businessValue}`];
  };

  renderDetailedBodyText = (
    saveValue: string,
    paydayValue: string,
    businessValue: string,
  ) => html`
    <div class="body detailed">
      <div>${translate("SavedGame_Saved")}</div>
      <div>${saveValue}</div>
    </div>
    <div class="body detailed">
      <div>${translate("SavedGame_Payday")}</div>
      <div>${paydayValue}</div>
    </div>
    <div class="body detailed">
      <div>${translate("SavedGame_BusinessValue")}</div>
      <div>${businessValue}</div>
    </div>
  `;

  renderShortBody = (text: string) => html`
    <div class="body short">${text}</div>
  `;

  renderSavedContainer = (type: "new" | "local" | "cloud") => {
    let title;
    let icon;
    let saveValue;
    let paydayValue;
    let businessValue;
    let pillText;
    let shortBody;
    let detailedBody: boolean = true;
    let pillColor: "teal" | "violet" | "yellow" | "ginger" | "";
    let callback: () => void;
    let buttonDisabled = false;
    let buttonCopy = translate("SavedGame_Button_Load");

    const localSave = saveState.local.value;
    const cloudSave = saveState.cloud.value;
    const localCloudTimeDiff =
      (localSave ? saveManager.localSaveTimeStamp().getTime() : 0) -
      (cloudSave ? saveManager.cloudSaveTimeStamp().getTime() : 0);

    switch (type) {
      case "new":
        icon = UIImages.SaveNewGame;
        title = translate("SavedGame_NewGame");
        saveValue = `0 ${translate("SavedGame_SavedOption_Hours")}`;
        paydayValue = "0/12";
        businessValue = "0";
        callback = this.newGame;
        break;
      case "local": {
        if (!localSave) return;
        icon = UIImages.SaveDevice;
        title = translate("SavedGame_Device");
        saveValue = this.getSaveValue(saveManager.localSaveTimeStamp());
        [paydayValue, businessValue] = this.getPaydayAndBusinessValue(
          localSave.data,
        );
        if (localCloudTimeDiff >= 0) {
          pillColor = "teal";
          pillText = translate("SavedGame_Latest");
        }
        callback = this.continueLocal;
        break;
      }
      case "cloud":
        icon = UIImages.SaveInternet;
        title = translate("SavedGame_Internet");
        if (cloudSave) {
          saveValue = this.getSaveValue(saveManager.cloudSaveTimeStamp());
          [paydayValue, businessValue] = this.getPaydayAndBusinessValue(
            cloudSave.data,
          );
          if (localCloudTimeDiff < 0) {
            pillColor = "teal";
            pillText = translate("SavedGame_Latest");
          }
          callback = this.continueCloud;
          break;
        }
        detailedBody = false;
        if (saveState.cloudRequestState.value === RequestState.Loading) {
          shortBody = translate("SavedGame_LoadSoon");
          pillText = translate("SavedGame_Connecting");
          pillColor = "yellow";
          buttonDisabled = true;
        } else if (saveState.cloudRequestState.value === RequestState.Failed) {
          // TODO: Add Reconnected/no-save functionality.
          shortBody = translate("SavedGame_TryAgain");
          pillText = translate("SavedGame_NoConnection");
          pillColor = "ginger";
          buttonDisabled = false;
          buttonCopy = translate("SavedGame_Button_Retry");
          callback = this.retryCloudFetch;
        } else {
          return;
        }
        break;
      default:
        return;
    }
    const pillInfo = pillText
      ? html` <div class="pill-info ${pillColor}">${pillText}</div> `
      : nothing;

    return html`
      <div class="save-container">
        <img class="save-image" src=${icon} />
        <div class="pill-info-container ${pillColor}">${pillInfo}</div>
        <div class="title">${title}</div>
        ${detailedBody
          ? this.renderDetailedBodyText(saveValue, paydayValue, businessValue)
          : this.renderShortBody(shortBody)}
        <app-button
          ?disabled=${buttonDisabled}
          text=${buttonCopy}
          @click=${callback}
        ></app-button>
      </div>
    `;
  };

  render() {
    return html`
      <div class="container-row">
        ${this.renderSavedContainer("new")}
        ${this.renderSavedContainer("local")}
        ${this.renderSavedContainer("cloud")}
      </div>
    `;
  }
}
