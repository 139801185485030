import {
  bottomProductYPos,
  firstProductXPos,
  tileSize,
  tillYPos,
} from "../controllers/mapData";
import { SpriteDirection } from "../types/spriteDirection";

export const customerDirectionHelper = (
  prevX: number,
  prevY: number,
  currentX: number,
  currentY: number,
): SpriteDirection => {
  if (
    (prevX === currentX && prevY < currentY) ||
    (prevX === currentX && prevY === currentY)
  ) {
    // moving down
    return SpriteDirection.Front;
  } else if (prevX === currentX && prevY > currentY) {
    // moving up
    return SpriteDirection.Back;
  } else if (prevY === currentY && prevX < currentX) {
    return SpriteDirection.Right;
  } else {
    return SpriteDirection.Left;
  }
};

export const employeeDirectionHelper = (
  prevX: number,
  prevY: number,
  currentX: number,
  currentY: number,
): SpriteDirection => {
  if (prevX === currentX && prevY < currentY) {
    // moving down
    return SpriteDirection.Front;
  } else if (prevX === currentX && prevY > currentY) {
    // moving up
    return SpriteDirection.Back;
  } else if (prevY === currentY && prevX < currentX) {
    // moving right
    return SpriteDirection.Right;
  } else if (prevY === currentY && prevX > currentX) {
    //moving left
    return SpriteDirection.Left;
  } else {
    // TODO: update offset once final gameobject positions are in [SM-AGEC-224]
    // stopped moving
    if (currentY === tillYPos * tileSize + tileSize) {
      // waiting at till
      return SpriteDirection.Back;
    } else if (currentY === bottomProductYPos * tileSize - tileSize) {
      // bottom is at bottom product row
      return SpriteDirection.Front;
    } else if (currentX < firstProductXPos * tileSize) {
      // getting product left side
      return SpriteDirection.Left;
    } else {
      // getting product right side
      return SpriteDirection.Right;
    }
  }
};
