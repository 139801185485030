import { v4 as uuidv4 } from "uuid";
import { Vector2 } from "./vector2";
import { ProductKind } from "../data/products";

export enum EmployeeState {
  WalkingToTill = "walking-to-till",
  WaitingAtTill = "waiting-at-till",
  TakingOrder = "taking-order",
  WalkingToProduct = "walking-to-product",
  TakingOutProduct = "taking-out-product",
  TakingProductToTill = "taking-product-to-till",
  SellingProduct = "selling-product",
}

const EmployeeTimes = {
  orderTakingSpeed: 1000,
  orderFetchingSpeed: 1000,
  orderFinishingSpeed: 1000,
};

export class Employee {
  readonly id: string;
  public speed: number;

  private state: EmployeeState;
  private till: string;

  private position: Vector2;

  private order: ProductKind;
  private progress: number;
  private progressRequired: number;

  private cost: number;
  public idle: boolean;
  public idleTime: number;

  // Employee constructor. Set the values parameter to create the Employee
  // in a predetermined state.
  constructor(
    x: number,
    y: number,
    till: string,
    opts?: {
      id?: string;
      speed?: number;
      state?: EmployeeState;
      order?: ProductKind;
      progress?: number;
      progressRequired?: number;
      cost?: number;
      idle?: boolean;
      idleTime?: number;
    },
  ) {
    this.id = opts?.id ?? uuidv4();
    this.setPosition(x, y);
    // Each employee is assigned a till
    this.till = till;
    this.speed = opts?.speed ?? 500;
    this.state = opts?.state ?? EmployeeState.WalkingToTill;
    this.order = opts?.order ?? undefined;
    this.progress = opts?.progress ?? 0;
    this.progressRequired = opts?.progressRequired ?? undefined;
    this.cost = opts?.cost ?? 0;
    this.idle = opts?.idle ?? false;
    this.idleTime = opts?.idleTime ?? 0;
  }

  toJSON = () => ({
    id: this.id,
    speed: this.speed,
    state: this.state,
    till: this.till,
    x: this.position.x,
    y: this.position.y,
    order: this.order,
    progress: this.progress,
    progressRequired: this.progressRequired,
    cost: this.cost,
    idle: this.idle,
    idleTime: this.idleTime,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJSON = (obj: any): Employee =>
    new Employee(obj.x, obj.y, obj.till, {
      id: obj.id,
      speed: obj.speed,
      state: obj.state,
      order: obj.order,
      progress: obj.progress,
      progressRequired: obj.progressRequired,
      cost: obj.cost,
      idle: obj.idle,
      idleTime: obj.idleTime,
    });

  private setPosition = (x: number, y: number) => {
    this.position = new Vector2(x, y);
  };
  getPosition = (): Vector2 => this.position;

  move(target: Vector2, delta: number): boolean {
    const pos = new Vector2(this.getPosition().x, this.getPosition().y);
    const offset = target.subtract(pos);
    const moveDistance = this.speed * (delta / 1000);
    if (offset.length() <= moveDistance) {
      this.setPosition(target.x, target.y);
      return true;
    }
    const move = offset.normalize().scale(moveDistance);
    const newPosition = pos.add(move);
    this.setPosition(newPosition.x, newPosition.y);
    return false;
  }

  moveTo = (target: Vector2, delta: number): boolean => {
    let x: boolean = target.x === this.getPosition().x;
    let y: boolean = target.y === this.getPosition().y;

    if (!y) {
      y = this.move(new Vector2(this.getPosition().x, target.y), delta);
    } else {
      x = this.move(new Vector2(target.x, this.getPosition().y), delta);
    }

    return x && y;
  };

  waitAtTill = () => {
    if (
      this.state != EmployeeState.WalkingToTill &&
      this.state != EmployeeState.SellingProduct
    ) {
      return false;
    }
    this.order = undefined;
    this.progressRequired = undefined;

    this.state = EmployeeState.WaitingAtTill;

    return true;
  };

  takeOrder = (product: ProductKind) => {
    if (this.state != EmployeeState.WaitingAtTill || this.idle) {
      return false;
    }
    this.order = product;
    this.progress = 0;
    this.progressRequired = EmployeeTimes.orderTakingSpeed;
    this.state = EmployeeState.TakingOrder;
    return true;
  };

  makeProgress = (delta: number) => {
    this.progress += delta;
    return this.progress >= this.progressRequired;
  };

  // progress made in the range [0,1]
  getProgress = () => Math.min(1, this.progress / this.progressRequired);

  walkToProduct = () => {
    if (this.state != EmployeeState.TakingOrder) {
      return false;
    }
    this.progressRequired = undefined;
    this.state = EmployeeState.WalkingToProduct;
    return true;
  };
  orderProduct = (): ProductKind => this.order;

  takeProductOut = () => {
    if (this.state != EmployeeState.WalkingToProduct) {
      return false;
    }
    this.progress = 0;
    this.progressRequired = EmployeeTimes.orderFetchingSpeed;
    this.state = EmployeeState.TakingOutProduct;
    return true;
  };
  takeProductToTill = () => {
    if (this.state != EmployeeState.TakingOutProduct) {
      return false;
    }
    this.progressRequired = undefined;
    this.state = EmployeeState.TakingProductToTill;
    return true;
  };
  sellProduct = () => {
    if (this.state != EmployeeState.TakingProductToTill) {
      return false;
    }
    this.progress = 0;
    this.progressRequired = EmployeeTimes.orderFinishingSpeed;
    this.state = EmployeeState.SellingProduct;
    return true;
  };
  receivePayment = () => {
    this.idle = false;
    this.idleTime = 0;
    this.waitAtTill();
  };

  setHiringCost = (cost: number) => {
    this.cost = cost;
  };

  // getters
  getState = () => this.state;
  getTill = () => this.till;
  getCost = () => this.cost;

  getOrderIndex = () => this.order?.index;
}
