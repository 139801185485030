import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { buttonStyle } from "../styles/theme";
import { playSoundEffect } from "../sound/sound-manager";

@customElement("reusable-button")
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ReusableButton extends LitElement {
  static styles = [
    buttonStyle,
    css`
      button {
        border-radius: 6px;
        background: var(--primary);
        box-sizing: border-box;
        border: 2px solid var(--primary-navy);
        box-shadow: 0px -4px 0px 0px var(--primary-lowlight) inset;
        outline: none;
        -webkit-tap-highlight-color: transparent;

        font-family: Ubuntu;
        font-size: 20px;
        font-weight: 700;
        line-height: normal;
        text-align: left;
        color: #16006d;
        margin: 0px;
      }

      button:active {
        background: var(--primary-lowlight);
        box-shadow: none;
      }

      button:disabled {
        background: var(--button-disabled-primary);
        border: 2px solid var(--button-disabled-lowlight);
        box-shadow: 0px -4px 0px 0px var(--button-disabled-lowlight) inset;
        color: var(--button-disabled-lowlight);
      }
    `,
  ];

  @property({ type: Boolean })
  disabled: boolean;

  onClick = () => {
    playSoundEffect("ButtonSelect_3");
  };

  render() {
    return html`
      <button ?disabled=${this.disabled}>
        <slot></slot>
      </button>
    `;
  }
}
