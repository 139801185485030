export enum ProductGroup {
  Clothing = "clothing",
  Technology = "technology",
  CoffeeShop = "coffeeShop",
  Stationary = "stationary",
  Toiletries = "toiletries",
}

export class ProductKind {
  readonly group: ProductGroup;
  readonly index: number;
  constructor(group: ProductGroup, index: number) {
    this.group = group;
    this.index = index;
  }
  key = (): string => `product_${this.group}_${this.index}`;
  equals = (other: ProductKind): boolean =>
    this.group === other.group && this.index === other.index;
  // TODO: Localise Product Name
  productName = () => productGroupInfoMap[this.group][this.index];
}

export const productGroupInfoMap = {
  [ProductGroup.Clothing]: [
    "Caps",
    "Flip flops",
    "T-shirt",
    "Strappy top",
    "Hoodie",
    "Jean",
    "Boots",
    "Sneakers",
    "Street Puffer Jacket",
  ],
  [ProductGroup.Technology]: [
    "Phone cover",
    "Earphones",
    "Bluetooth speaker",
    "Ring light camera",
    "Smart watch",
    "Cellphone",
    "Camera",
    "Laptop",
    "Game Console",
  ],
  [ProductGroup.CoffeeShop]: [
    "Coffee",
    "Pastry",
    "Bubble Tea",
    "Sandwich",
    "Fancy Coffee (Starbucks)",
    "Full cake",
    "Cake slice",
    "Coffee Beans",
    "Coffee Machine",
  ],
  [ProductGroup.Stationary]: [
    "Notebook",
    "Colourful pens",
    "Pencil case",
    "(Tote?) Bag",
    "Pencil holder",
    "Desk calendar",
    "Novel",
    "Journal",
    "Art Supplies Box Set",
  ],
  [ProductGroup.Toiletries]: [
    "Eyeshadow",
    "Lipstick",
    "Nail polish",
    "False Eyelashes",
    "Hair treatment",
    "Hair clip",
    "Face cream",
    "Fancy hair weave",
    "Serum",
  ],
};
