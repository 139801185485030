import { Loader, Textures } from "phaser";
import { ImageData, ImageObject, ImageType } from "../data/images";
import { warn } from "./loggerHelper";
import { onUIPreloadComplete } from "../state/app-state";

/**
 * Preloads image assets into memory considering the different types of images
 * @param phaserLoader The Phaser Loader object to perform the preloading of images
 * @param textureManager The Phasaer Textures object for checking if the textures have already been loaded
 */
export function preloadPhaserImageData(
  imageData: ImageData,
  phaserLoader: Loader.LoaderPlugin,
  textureManager: Textures.TextureManager,
): void {
  Object.values(imageData).forEach((image) => {
    preloadImage(image, phaserLoader, textureManager);
  });
}

function preloadImage(
  image: ImageObject,
  phaserLoader: Loader.LoaderPlugin,
  textureManager: Textures.TextureManager,
): void {
  if (textureManager.exists(image.key)) {
    warn(
      `[PreloadHelper] Image preload failed - Object has already been loaded into memory!`,
      { imageKey: image.key },
    );
    return;
  }
  switch (image.type) {
    case ImageType.Image:
      phaserLoader.image(image.key, image.src);
      break;
    case ImageType.Atlas:
      phaserLoader.atlas(image.key, image.src, image.json);
      break;
    case ImageType.SVG:
      phaserLoader.svg(image.key, image.src);
      break;
  }
}

const preloadedImages: HTMLImageElement[] = [];
let count = 0;
export function preloadHTMLImageData(urls: string[]) {
  count += urls.length - 1;
  urls.forEach((url) => {
    const img = new Image();
    img.onload = () => {
      const index = preloadedImages.indexOf(img);
      if (index >= 0) {
        preloadedImages.splice(index, 1);
        count--;
      }
      if (count <= 0) {
        onUIPreloadComplete();
      }
    };
    preloadedImages.push(img);
    img.src = url;
  });
}
