import { html, css, nothing } from "lit";
import { customElement } from "lit/decorators.js";
import { LitElementI18n } from "../translations/lit-mixin";
import { clearEvent, gameState } from "../state/game-state";
import { SignalWatcher } from "@lit-labs/preact-signals";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { playSoundEffect } from "../sound/sound-manager";
import { EventsTabs } from "../data/events";
import { translate } from "../translations/translations";

@customElement("event-modal")
export class EventModal extends SignalWatcher(LitElementI18n) {
  static styles = [
    css`
      :host {
        display: block;
      }

      #root {
        width: 85%;
        height: 65%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
      }
    `,
  ];

  @consume({ context: gameContext })
  game: GameController;

  closeModal = () => {
    const events = gameState.eventQueue.value;
    if (events.length === 0) return;
    const { cash: gold, businessValue: value } = events[0];
    this.game.getModel().onEvent({ gold, value });
    playSoundEffect("PopOut");
    clearEvent();
  };

  firstUpdated() {
    playSoundEffect("PopIn");
  }

  render() {
    const events = gameState.eventQueue.value;
    if (events.length === 0) return nothing;
    const { titleKey, bodyKey } = EventsTabs[events[0].id];
    return html`
      <div id="root">
        <h1>${translate(titleKey)}</h1>
        ${bodyKey.map((key) => html`<p>${translate(key)}</p>`)}
        <button @click=${this.closeModal}>${"OK"}</button>
      </div>
    `;
  }
}
