export class IntervalTimer {
  private readonly fn: () => void;
  private interval: number;
  private ticks: number;

  constructor(fn: () => void, interval: number) {
    this.fn = fn;
    this.ticks = 0;
    this.interval = interval;
  }

  _call = () => {
    while (this.ticks >= this.interval) {
      this.fn();
      this.ticks -= this.interval;
    }
  };

  tick = (delta: number) => {
    this.ticks += delta;
    this._call();
  };

  setInterval = (value: number) => {
    this.interval = value;
    this._call();
  };
}
