import { css, html } from "lit";
import { customElement } from "lit/decorators.js";
import { buttonStyle } from "../styles/theme";
import { LitElementI18n } from "../translations/lit-mixin";
import "./business-value";
import "./profit-loss";
import "./market-research";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { playSoundEffect } from "../sound/sound-manager";

@customElement("financial-report")
export class FinancialReport extends LitElementI18n {
  static styles = [
    buttonStyle,
    css`
      :host {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        z-index: var(--z-index-financial-report);
      }

      button {
        min-height: 50px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    `,
  ];

  @consume({ context: gameContext })
  gameController: GameController;

  firstUpdated() {
    playSoundEffect("Report");
  }

  render() {
    return html`
      <tabbed-container>
        <h2 slot="tab">Business Value</h2>
        <section slot="panel"><business-value></business-value></section>
        <h2 slot="tab">Profit/Loss</h2>
        <section slot="panel"><profit-loss></profit-loss></section>
        <h2 slot="tab">Market Research</h2>
        <section slot="panel"><market-research></market-research></section>
      </tabbed-container>
      <button @click=${this.gameController.dismissFinancialReport}>
        Close Report
      </button>
    `;
  }
}
