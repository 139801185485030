import Phaser from "phaser";
import { GameObjectDepth } from "../data/game-objects";
import { GameImages, getProductSpriteInfo } from "../data/images";
import { INeed, WalkAwayReason } from "../models/customer";

const bubbleKey = "bubble.png";
const noStaffKey = "bubble_no_staff.png";
const prohibitedKey = "bubble_prohibited.png";
// Accurate Constants based on the asset
const speechBubbleHeightPx = 48;

export class ThoughtBubbleGameObject extends Phaser.GameObjects.Container {
  public bubble: Phaser.GameObjects.Image;
  private thoughtImage: Phaser.GameObjects.Image;
  private alertPip: Phaser.GameObjects.Image;
  // Caching to prevent multiple-redraws of text or prohibited sign
  private reason: WalkAwayReason;
  private yCentreOffset: number;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    sheet: string,
    frame?: string,
  ) {
    super(scene);
    this.create(sheet, frame);
    this.setPosition(x, y - this.bubble.height / 2);
    this.setDepth(GameObjectDepth.ThoughtBubble);
  }

  setImageWithKeyAndFrame = (key: string, frame: string) => {
    if (this.thoughtImage.texture.key === key) {
      return;
    }
    this.thoughtImage.setTexture(key, frame);
  };

  setPayPipVisibility = (visible: boolean) => {
    if (this.alertPip.visible !== visible) {
      this.alertPip.setVisible(visible);
    }
  };

  private spawnProhibitedSign = () => {
    const sign = this.scene.add.image(
      0,
      this.yCentreOffset,
      GameImages.EmoticonAndThoughtBubbleSheet,
      prohibitedKey,
    );
    this.add(sign);
  };

  private addCostText = (cost: number) => {
    this.thoughtImage.setTexture(GameImages.Coin);
    const bubbleQuarterWidth = this.bubble.width / 4;
    this.thoughtImage.setX(-bubbleQuarterWidth);
    const text = this.scene.add.text(
      bubbleQuarterWidth,
      this.yCentreOffset,
      cost.toString(),
      {
        color: "#16006D",
        fontSize: 14,
        // TODO: SM-AGEC-236 - Set font for text
      },
    );
    text.setOrigin(0.5, 0.5);
    this.add(text);
  };

  setImageFromWalkAwayReason = (reason: WalkAwayReason, need: INeed) => {
    if (reason === this.reason) return;
    this.reason = reason;
    switch (reason) {
      case WalkAwayReason.ProductPurchased:
        this.addCostText(need.purchasePrice);
        return;
      case WalkAwayReason.ProductNotAvailable:
        this.alertPip.setVisible(true);
        this.thoughtImage.setTexture(
          getProductSpriteInfo(need.kind).spriteKey,
          getProductSpriteInfo(need.kind).frame,
        );
        this.spawnProhibitedSign();
        return;
      case WalkAwayReason.TillNotAvailable:
        this.thoughtImage.setTexture(
          GameImages.EmoticonAndThoughtBubbleSheet,
          noStaffKey,
        );
        this.spawnProhibitedSign();
        return;
      case WalkAwayReason.ProductTooExpensive:
        this.thoughtImage.setTexture(GameImages.Coin);
        this.spawnProhibitedSign();
        return;
      case WalkAwayReason.Exception:
      default:
        return;
    }
  };

  create = (key: string, frame?: string) => {
    this.bubble = this.scene.add.image(
      0,
      0,
      GameImages.EmoticonAndThoughtBubbleSheet,
      bubbleKey,
    );
    this.add(this.bubble);
    this.yCentreOffset =
      this.bubble.getTopCenter().y + speechBubbleHeightPx / 2;

    this.thoughtImage = this.scene.add.image(0, this.yCentreOffset, key, frame);
    this.add(this.thoughtImage);

    this.alertPip = this.scene.add.image(
      this.bubble.getTopRight().x,
      this.bubble.getTopRight().y,
      GameImages.StockAndPayAlert,
    );
    this.alertPip.setVisible(false);
    this.add(this.alertPip);
  };
}
