import { Till } from "./till";
import { Product } from "./product";
import { v4 as uuidv4 } from "uuid";
import { Vector2 } from "./vector2";
import { mapData } from "../controllers/mapData";
import { ProductGroup, ProductKind } from "../data/products";
import {
  maxShopLevel,
  maxProductsPerLevel,
  maxEmployeesPerLevel,
} from "../data/shopData";
import { info } from "../helpers/loggerHelper";

export class Shop {
  readonly id: string;
  private level: number;
  readonly attractionRange: number;
  private readonly tills: Till[];
  private readonly products: Product[];
  private businessType: ProductGroup;
  public nextProductCost: number;

  constructor(
    attractionRange: number,
    opts?: {
      id?: string;
      level?: number;
      tills?: Till[];
      products?: Product[];
      businessType?: ProductGroup;
      nextProductCost?: number;
    },
  ) {
    this.id = opts?.id ?? uuidv4();
    this.attractionRange = attractionRange;
    this.level = opts?.level ?? 1;
    this.tills = opts?.tills ?? [];
    this.products = opts?.products ?? [];
    this.nextProductCost = opts?.nextProductCost ?? undefined;

    this.businessType = opts?.businessType ?? ProductGroup.Clothing;
  }

  toJSON = () => ({
    id: this.id,
    level: this.level,
    range: this.attractionRange,
    tills: this.tills.map((t) => t.toJSON()),
    products: this.products.map((p) => p.toJSON()),
    businessType: this.businessType,
    nextProductCost: this.nextProductCost,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJSON = (obj: any): Shop =>
    new Shop(obj.range, {
      id: obj.id,
      level: obj.level,
      tills: obj.tills.map(Till.fromJSON),
      products: obj.products.map(Product.fromJSON),
      businessType: obj.businessType,
      nextProductCost: obj.nextProductCost,
    });

  levelUp = (): boolean => {
    if (this.getLevel() >= maxShopLevel) {
      return false;
    }
    this.level++;
    info("[shop] - level up: " + this.level);
    return true;
  };

  setBusinessType = (type: ProductGroup) => {
    this.businessType = type;
  };

  addTill = (x: number, y: number, id?: string): Till => {
    const t = new Till(x, y, { id: id });
    this.tills.push(t);
    return t;
  };
  getTill = (id: string) => this.tills.find((t) => t.id === id);

  private nextProductMapData = () => {
    return mapData.products[this.getProducts().length];
  };

  nextProductPosition = () => {
    if (this.getProducts().length === maxProductsPerLevel[maxShopLevel]) {
      return undefined;
    }
    const d = this.nextProductMapData();
    return new Vector2(d.x, d.y);
  };
  nextLaunchProduct = () => {
    if (this.getProducts().length === maxProductsPerLevel[maxShopLevel]) {
      return undefined;
    }
    const d = mapData.launchProductPosition[this.getProducts().length];
    return new Vector2(d.x, d.y);
  };
  nextProductSize = () => {
    if (this.getProducts().length === maxProductsPerLevel[maxShopLevel]) {
      return undefined;
    }
    const d = this.nextProductMapData();
    return new Vector2(d.w, d.h);
  };
  nextHireEmployeePosition = () => {
    if (this.getTills().length === maxEmployeesPerLevel[maxShopLevel]) {
      return undefined;
    }
    const p = mapData.hireEmployeePosition[this.getTills().length];
    return new Vector2(p.x, p.y);
  };

  addProduct = (
    kind: ProductKind,
    cost: number,
    maxCost: number,
    stockLimit: number,
  ) => {
    if (this.hasProduct(kind)) {
      return;
    }

    if (this.getProducts().length === maxProductsPerLevel[maxShopLevel]) return;

    const product = new Product(
      kind,
      cost,
      maxCost,
      this.nextProductPosition(),
      this.nextProductSize(),
      stockLimit,
    );
    this.products.push(product);
    return product;
  };
  hasProduct = (kind: ProductKind): boolean =>
    this.products.find((p) => p.kind.equals(kind))?.stock.value > 0;

  getNextProductIndex = (): number => {
    const maxProducts = maxProductsPerLevel[this.getLevel()];
    const index = this.getProducts().length;

    if (this.getLevel() === 5 && index >= maxProducts) {
      return -1; // maximum products reached
    }

    return index;
  };

  // getters
  getBusinessType = (): ProductGroup => this.businessType;
  getLevel = (): number => this.level;
  getTills = () => this.tills;
  getProducts = () => this.products;
  product = (kind: ProductKind): Product =>
    this.getProducts().find((p) => p.kind.equals(kind));
  availableTills = (): Till[] => this.tills.filter((t) => t.isAvailable());
}
