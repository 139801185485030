import { css, html, LitElement, PropertyValues } from "lit";
import { customElement } from "lit/decorators.js";
import { GameController } from "../controllers/gameController";
import { FolderApi, Pane } from "tweakpane";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import {
  EventTriggerCategories,
  GameplayParamsVersion,
} from "../config/gameplayParameters";

@customElement("gameplay-parameters")
export class GameplayParamsElement extends LitElement {
  static styles = css`
    :host {
      position: absolute;
      top: 0;
      left: 0;
    }

    button {
      background-color: #fcca6f;
      width: 30px;
      height: 30px;
    }
  `;

  @consume({ context: gameContext })
  game: GameController;

  pane: Pane;

  createCustomerFolder() {
    const customerFolder = this.pane.addFolder({
      title: "Customer",
      expanded: false,
    });
    customerFolder.addBinding(
      this.game.gameplayParams.customer,
      "spawnInterval",
      {
        label: "Spawn Interval",
        min: 0.1,
      },
    );
    customerFolder.addBinding(
      this.game.gameplayParams.customer,
      "spawnIntervalDecrease",
      {
        label: "Spawn Interval Decrease Per Level of Marketing",
      },
    );
    customerFolder.addBinding(this.game.gameplayParams.customer, "speed", {
      label: "Speed",
      min: 100,
      max: 500,
    });
  }

  createStaffFolder() {
    const staffFolder = this.pane.addFolder({
      title: "Staff",
      expanded: false,
    });

    staffFolder.addBinding(this.game.gameplayParams.staff, "hiringCost", {
      label: "Hiring Cost",
    });

    staffFolder.addBinding(
      this.game.gameplayParams.staff,
      "multiplierPerStaff",
      {
        label: "Multiplier Per Staff Hired",
      },
    );

    staffFolder.addBinding(this.game.gameplayParams.staff, "speed", {
      label: "Speed",
    });

    staffFolder.addBinding(
      this.game.gameplayParams.staff,
      "orderSpeedMultiplier",
      {
        label: "Loading Bar Speed Multiplier",
      },
    );

    const loadingBarFolder = staffFolder.addFolder({
      title: "Loading Bar Values",
      expanded: false,
    });

    loadingBarFolder.addBinding(
      this.game.gameplayParams.staff,
      "orderTakingSpeed",
      {
        label: "Employee First Loading Bar",
        readonly: true,
      },
    );

    loadingBarFolder.addBinding(
      this.game.gameplayParams.staff,
      "orderFetchingSpeed",
      {
        label: "Employee Second Loading Bar",
        readonly: true,
      },
    );

    loadingBarFolder.addBinding(
      this.game.gameplayParams.staff,
      "orderFinishingSpeed",
      {
        label: "Employee Third Loading Bar",
        readonly: true,
      },
    );
  }

  createPaydayFolder() {
    const paydayFolder = this.pane.addFolder({
      title: "Payday",
      expanded: false,
    });
    paydayFolder.addBinding(
      this.game.gameplayParams.payday,
      "secondsPerPayday",
      {
        label: "Seconds Per Payday",
        min: 1,
      },
    );
    paydayFolder.addBinding(this.game.gameplayParams.payday, "paydayCount", {
      label: "Number of Paydays Per Game",
      min: 1,
    });
  }

  createMarketingFolder() {
    const marketingFolder = this.pane.addFolder({
      title: "Marketing",
      expanded: false,
    });
    marketingFolder.addBinding(this.game.gameplayParams.marketing, "cost", {
      label: "Cost Base",
    });
    marketingFolder.addBinding(
      this.game.gameplayParams.marketing,
      "increasePerLevel",
      {
        label: "Cost Increase Per Level",
      },
    );
    marketingFolder.addBinding(
      this.game.gameplayParams.marketing,
      "maxDemand",
      {
        label: "Max Demand Level",
      },
    );
  }

  createProductsFolder() {
    const productsFolder = this.pane.addFolder({
      title: "Products",
      expanded: false,
    });
    Object.values(this.game.gameplayParams.products).forEach((c) => {
      const subproductsFolder = productsFolder.addFolder({
        title: c.category.toUpperCase(),
        expanded: false,
      });
      c.products.forEach((product) => {
        const folder = subproductsFolder.addFolder({
          title: product.name,
          expanded: false,
        });
        folder.addBinding(product, "cost", {
          label: "Cost",
        });

        folder.addBinding(product, "maxCost", {
          label: "Max Cost",
        });

        folder.addBinding(product, "stockLimit", {
          label: "Stock Limit",
        });

        folder.addBinding(product, "spawnRateMultiplier", {
          label: "Spawn Rate Multiplier",
          max: 4,
          min: 0.5,
          step: 0.1,
        });
      });
    });
  }

  createEventsFolder() {
    const eventsFolder = this.pane.addFolder({
      title: "Events",
      expanded: false,
    });
    const categoryFolders: Map<EventTriggerCategories, FolderApi> = new Map();
    this.game.gameplayParams.events.forEach((event) => {
      if (!categoryFolders.has(event.trigger.category)) {
        categoryFolders.set(
          event.trigger.category,
          eventsFolder.addFolder({
            title: event.trigger.category,
            expanded: false,
          }),
        );
      }
      const folder = categoryFolders.get(event.trigger.category).addFolder({
        title: event.id,
        expanded: false,
      });
      event.cash = event.cash ?? 0;
      event.businessValue = event.businessValue ?? 0;
      folder.addBinding(event, "cash", {
        label: "Cash",
      });
      folder.addBinding(event, "businessValue", {
        label: "Business Value",
      });
      folder.addBinding(event.trigger, "category", {
        label: "Trigger Category",
        readonly: true,
      });
      folder.addBinding(event.trigger, "value", {
        label: "Trigger Value",
      });
    });
  }

  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);
    this.pane = new Pane();

    this.createCustomerFolder();
    this.createStaffFolder();
    this.createPaydayFolder();
    this.createMarketingFolder();
    this.createProductsFolder();
    this.createEventsFolder();

    const closeButton = this.pane.addButton({
      title: "Close",
    });
    closeButton.on("click", () => {
      this.pane.hidden = true;
    });

    this.pane.hidden = true;
    this.pane.on("change", this.game.updateGameplayParams);
  }

  toggleShowDialog = () => {
    this.pane.hidden = !this.pane.hidden;
  };

  downloadJSON = () => {
    const s = JSON.stringify({
      version: GameplayParamsVersion,
      timestamp: new Date().toUTCString(),
      params: this.game.gameplayParams,
    });
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(s),
    );
    element.setAttribute("download", "params.json");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  render() {
    return html`
      <button @click=${this.toggleShowDialog}>
        <img src="assets/icons/gear-solid.svg" />
      </button>
      <button @click=${this.downloadJSON}>
        <img src="assets/icons/download.svg" />
      </button>
    `;
  }
}
