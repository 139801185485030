import { css, html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { buttonStyle } from "../styles/theme";
import { LitElementI18n } from "../translations/lit-mixin";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { FinancialReportModel } from "../models/financialReportModel";
import { ProductInfo } from "../models/financialReportModel";

const priceTooHighText = "ⓘ Price too high: ";
const priceTooHighImage = "assets/icons/placeholder_PriceTooHigh.png";

const noStaffAvailableText = "ⓘ No staff available: ";
const noStaffAvailableImage = "assets/icons/placeholder_NoStaff.png";

const lackOfMarketingText = "ⓘ Lack of marketing: ";
const lackOfMarketingImage = "assets/icons/placeholder_LackOfMarketing.png";

const OutOfStockText = "ⓘ Out of stock: ";
const OutOfStockImage = "assets/icons/placeholder_NoStock.png";

@customElement("market-research")
export class MarketResearch extends LitElementI18n {
  static styles = [
    buttonStyle,
    css`
      :host {
        width: 100%;
        height: 100%;
        background-color: white;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .container {
        position: relative;

        width: 100%;
        max-width: 1920px;
        height: 100%;
        max-height: 1080px;

        display: flex;
        justify-content: space-evenly;
      }

      .rows {
        position: relative;

        width: 100%;
        max-width: 1920px;
        height: 100%;
        max-height: 1080px;

        display: flex;
        justify-content: space-evenly;
      }

      .column {
        flex: 1;
        padding: 5px;
      }
    `,
  ];

  @consume({ context: gameContext })
  game: GameController;

  @query("#product-select")
  productSelect: HTMLSelectElement;

  financialReportModel: FinancialReportModel;
  totalProductInfo: Partial<ProductInfo>;

  totalNumberOfCustomers: number = 0;

  @state()
  currentProductKey?: string;

  productKeys: string[];

  connectedCallback(): void {
    super.connectedCallback();
    this.financialReportModel = this.game.getModel().getFinancialReportModel();
    const { productInfo } = this.financialReportModel;
    this.totalProductInfo = {
      product_purchased: 0,
      product_not_available: 0,
      product_too_expensive: 0,
      till_not_available: 0,
      no_marketing: 0,
    };
    Object.values(productInfo).forEach((info) => {
      this.totalNumberOfCustomers += this.financialReportModel.getCustomerCount(
        info.kind,
      );
      this.totalProductInfo.product_purchased += info.product_purchased;
      this.totalProductInfo.product_not_available += info.product_not_available;
      this.totalProductInfo.product_too_expensive += info.product_too_expensive;
      this.totalProductInfo.till_not_available += info.till_not_available;
      this.totalProductInfo.no_marketing += info.no_marketing;
    });
    this.productKeys = this.game
      .getModel()
      .getShop()
      .getProducts()
      .map((p) => p.kind.key());
  }

  private onOptionSelected = () => {
    this.currentProductKey = this.productSelect.value;
  };

  renderContent = (key?: string) => {
    const productInfo = key
      ? this.financialReportModel.productInfo[key]
      : this.totalProductInfo;
    return html`
      <h1>
        Number of sales: ${productInfo.product_purchased} out of
        ${key
          ? this.financialReportModel.getCustomerCount(productInfo.kind)
          : this.totalNumberOfCustomers}
      </h1>
      <div class="column">
        <img src=${priceTooHighImage} />
        <h3>${priceTooHighText}</h3>
        <p>${productInfo.product_too_expensive}</p>
      </div>
      <div class="column">
        <img src=${noStaffAvailableImage} />
        <h3>${noStaffAvailableText}</h3>
        <p>${productInfo.till_not_available}</p>
      </div>
      <div class="column">
        <img src=${lackOfMarketingImage} />
        <h3>${lackOfMarketingText}</h3>
        <p>${Math.floor(productInfo.no_marketing)}</p>
      </div>
      <div class="column">
        <img src=${OutOfStockImage} />
        <h3>${OutOfStockText}</h3>
        <p>${productInfo.product_not_available}</p>
      </div>
    `;
  };

  render() {
    return html` <div class="container">
      <form>
        <label for="Product Filter"><h1>Filter:</h1></label>
        <select
          id="product-select"
          name="Choose"
          @change=${this.onOptionSelected}
        >
          <option value=${""}>All Products</option>
          ${this.productKeys.map((key) => {
            return html` <option value=${key}>${key}</option> `;
          })}
        </select>
      </form>
      ${this.renderContent(this.currentProductKey)}
    </div>`;
  }
}
