export const maxShopLevel: number = 5;

export const maxEmployeesPerLevel: { [lvl: number]: number } = {
  1: 2,
  2: 4,
  3: 5,
  4: 6,
  5: 7,
};

export const maxProductsPerLevel: { [lvl: number]: number } = {
  1: 1,
  2: 3,
  3: 4,
  4: 5,
  5: 9,
};
