import Phaser from "phaser";
import { GameObjectDepth } from "../data/game-objects";
import { GameImages } from "../data/images";

const ellipsKey = "ellips.png";

export class EllipsGameObject extends Phaser.GameObjects.Container {
  private xPos: number;
  private yPos: number;
  ellips: Phaser.GameObjects.Image;

  constructor(scene: Phaser.Scene, xPos: number, yPos: number) {
    super(scene);

    this.xPos = xPos;
    this.yPos = yPos - 15;
    this.setDepth(GameObjectDepth.ThoughtBubble);
    this.create();
  }

  private create() {
    this.ellips = this.scene.add.image(
      this.xPos,
      this.yPos,
      GameImages.EmoticonAndThoughtBubbleSheet,
      ellipsKey,
    );

    this.add(this.ellips);
  }
}
