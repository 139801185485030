import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";
import { LottieAnimationJSONs } from "../data/images";
import "@lottiefiles/lottie-player";

@customElement("loading-spinner")
export class LoadingSpinner extends LitElement {
  render() {
    //
    return html`
      <lottie-player
        autoplay
        loop
        mode="normal"
        src=${LottieAnimationJSONs.LoadingSpinner}
        style="width: 100%; height: 100%;"
      ></lottie-player>
    `;
  }
}
