import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";

@customElement("dialog-arrow")
export class DialogArrow extends LitElement {
  static styles = [
    css`
      .arrow {
        width: 18px;
        height: 16px;
        position: absolute;
        @media (min-width: 1000px) {
          width: 27px;
          height: 24px;
        }
      }
      .arrow > path {
        stroke-width: 3px;
      }

      .left {
        bottom: 8px;
        left: 0;
        transform: translate(-100%, 0);
        @media (min-width: 1000px) {
          bottom: 12px;
        }
      }

      .right {
        bottom: 8px;
        right: 0px;
        transform: rotate(180deg) translate(-100%, 0);
        @media (min-width: 1000px) {
          bottom: 12px;
        }
      }

      .bottom {
        bottom: 0px;
        left: 50%;
        transform: rotate(-90deg) translate(-95%, -50%);
      }
    `,
  ];

  @property({ type: String })
  direction: "left" | "right" | "bottom";

  @property({ type: Boolean, attribute: "no-border" })
  noBorder: boolean = false;

  render() {
    return html`
      <svg
        viewbox="0 0 2 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="arrow ${this.direction}"
      >
        <path
          d="M17 4L17 2.29015L15.5097 3.12842L2.60867 10.3853C0.569717 11.5322 0.569722 14.4678 2.60868 15.6147L15.5097 22.8716L17 23.7098L17 22L17 4Z"
          fill="white"
          stroke="${!this.noBorder ? "#16006D" : ""}"
        />
      </svg>
    `;
  }
}
