import Phaser from "phaser";
import { GameController } from "../controllers/gameController";
import {
  loadMainCharacterImages,
  loadEmployeeImages,
  loadEmoticonAndThoughtBubbleImages,
  loadMiscGameAssets,
  loadTillImages,
} from "../data/images";
import {
  loadAllShopProducts,
  loadShopLevelImages,
  loadCustomerImages,
  loadThoughtImages,
} from "../data/images";
import { gameConfig } from "../config/gameConfig";
import { loadAddProductButtonImages } from "../data/images";
import { effect } from "@lit-labs/preact-signals";
import { gameState, isIntroComplete } from "../state/game-state";
import { appState, onGamePreloadComplete } from "../state/app-state";

class GameScene extends Phaser.Scene {
  private readonly gameCtrl: GameController;
  private readonly onCreated: () => void;

  private unsubscribeFromSignal: () => void;

  private clicking = false;
  private startX: number;
  private startY: number;

  private minZoom: number;
  private currentZoom: number;

  constructor(ctrl: GameController, onCreated: () => void) {
    super();
    this.onCreated = onCreated;
    this.gameCtrl = ctrl;
  }

  adjustCameraZoomAndScroll = () => {
    const cam = this.cameras.main;
    cam.setPosition(0, 0);
    cam.setBounds(
      0,
      0,
      gameConfig.backgroundWidth,
      gameConfig.backgroundHeight,
    );

    // Display the bigger zoom value to prevent the background canvas from being shown
    let xZoom = cam.width / gameConfig.backgroundWidth;
    let yZoom = cam.height / gameConfig.backgroundHeight;

    const xScroll =
      gameConfig.backgroundWidth / 2 - this.cameras.main.width / 2;
    let yScroll =
      gameConfig.backgroundHeight / 2 - this.cameras.main.height / 2;

    if (window.innerWidth < gameConfig.smlDeviceWidth) {
      xZoom = cam.width / (gameConfig.backgroundWidth / gameConfig.mobileZoom);
      yZoom =
        cam.height / (gameConfig.backgroundHeight / gameConfig.mobileZoom);

      yScroll =
        gameConfig.backgroundHeight / 2 -
        this.cameras.main.height / 2 +
        gameConfig.cameraOffsetMobile;
    } else if (window.innerWidth >= gameConfig.smlDeviceWidth) {
      xZoom = cam.width / (gameConfig.backgroundWidth / gameConfig.desktopZoom);
      yZoom =
        cam.height / (gameConfig.backgroundHeight / gameConfig.desktopZoom);

      yScroll =
        gameConfig.backgroundHeight / 2 -
        this.cameras.main.height / 2 +
        gameConfig.cameraOffsetDeskop;
    }
    this.minZoom = xZoom > yZoom ? xZoom : yZoom;

    if (!this.currentZoom) cam.zoom = this.minZoom;

    // scroll to the middle of the image
    cam.setScroll(xScroll, yScroll);
  };

  init() {
    this.events.on(Phaser.Scenes.Events.CREATE, this.onCreated);

    this.adjustCameraZoomAndScroll();

    window.addEventListener("resize", () => {
      this.adjustCameraZoomAndScroll();
    });

    this.unsubscribeFromSignal = effect(() => {
      const isEventModalOpen =
        gameState.eventQueue.value.length && !gameState.displayLevelUp.value;
      const playGame =
        !gameState.displayFinancialReport.value &&
        !gameState.isPaused.value &&
        !isEventModalOpen &&
        !appState.tip.value &&
        gameState.isHorizontal.value &&
        gameState.isPageFocused.value &&
        isIntroComplete();

      if (playGame) {
        this.scene.resume();
      } else {
        this.scene.pause();
      }
    });
  }

  preload() {
    loadMainCharacterImages(this);
    loadEmployeeImages(this);
    loadShopLevelImages(this);
    loadCustomerImages(this);
    loadAllShopProducts(this);
    loadEmoticonAndThoughtBubbleImages(this);
    loadThoughtImages(this);
    loadAddProductButtonImages(this);
    loadMiscGameAssets(this);
    loadTillImages(this);
  }

  onDragStart = (e: PointerEvent | TouchEvent) => {
    this.clicking = true;

    if ("pageX" in e) {
      this.startX = e.pageX;
      this.startY = e.pageY;
    } else {
      this.startX = (e as TouchEvent).touches[0].pageX;
      this.startY = (e as TouchEvent).touches[0].pageY;
    }
  };
  onDragging = (e: PointerEvent | TouchEvent) => {
    const cam = this.cameras.main;
    if (!this.clicking) return;
    let pageX;
    let pageY;

    if ("pageX" in e) {
      pageX = e.pageX;
      pageY = e.pageY;
    } else {
      pageX = (e as TouchEvent).touches[0].pageX;
      pageY = (e as TouchEvent).touches[0].pageY;
    }
    cam.scrollX +=
      ((this.startX - pageX) / cam.zoom) * gameConfig.cameraScrollSpeed;
    cam.scrollY +=
      ((this.startY - pageY) / cam.zoom) * gameConfig.cameraScrollSpeed;
  };
  onDragStop = () => {
    this.clicking = false;
  };

  create() {
    this.input.on(
      Phaser.Input.Events.POINTER_WHEEL,
      (p: Phaser.Input.Pointer) => {
        const cam = this.cameras.main;
        const newZoom = cam.zoom * (1 - p.deltaY / 1000);
        this.currentZoom = newZoom < this.minZoom ? this.minZoom : newZoom;
        cam.zoom = this.currentZoom;
      },
    );

    this.game.canvas.addEventListener("pointerdown", this.onDragStart);
    this.game.canvas.addEventListener("pointermove", this.onDragging);
    window.addEventListener("pointerup", this.onDragStop);
    this.game.canvas.addEventListener("touchstart", this.onDragStart);
    this.game.canvas.addEventListener("touchmove", this.onDragging);
    window.addEventListener("touchend", this.onDragStop);
    onGamePreloadComplete();
  }

  destroy() {
    this.game.canvas.removeEventListener("pointerdown", this.onDragStart);
    this.game.canvas.removeEventListener("pointermove", this.onDragging);
    window.removeEventListener("pointerup", this.onDragStop);
    this.game.canvas.removeEventListener("touchstart", this.onDragStart);
    this.game.canvas.removeEventListener("touchmove", this.onDragging);
    window.removeEventListener("touchend", this.onDragStop);
    this.unsubscribeFromSignal();
  }

  update(_: number, delta: number) {
    this.gameCtrl.tick(delta);
  }
}

export default GameScene;
