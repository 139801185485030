import { Vector2 } from "./vector2";
import { ProductKind } from "../data/products";
import { Signal, signal } from "@lit-labs/preact-signals";

export class Product {
  readonly kind: ProductKind;
  // Cost is how much the product costs to restock
  readonly cost: number;
  readonly maxPrice: number;
  readonly position: Vector2;
  readonly size: Vector2;
  readonly stockLimit: number;
  readonly stock: Signal<number>;
  // Price is how much the product is being sold for
  public price: number;
  public demand: number;

  constructor(
    kind: ProductKind,
    cost: number,
    maxPrice: number,
    position: Vector2,
    size: Vector2,
    stockLimit: number,
    opts?: {
      price: number;
      demand: number;
      stock: number;
    },
  ) {
    this.kind = kind;
    this.cost = cost;
    this.price = opts?.price ?? cost + 1;
    this.maxPrice = maxPrice;
    this.position = position;
    this.size = size;
    this.stockLimit = stockLimit;
    this.stock =
      opts?.stock != undefined ? signal(opts.stock) : signal(stockLimit);
    this.demand = opts?.demand ?? 0;
  }

  toJSON = () => ({
    kind: this.kind,
    cost: this.cost,
    price: this.price,
    maxPrice: this.maxPrice,
    stock: this.stock.value,
    stockLimit: this.stockLimit,
    demand: this.demand,
    x: this.position.x,
    y: this.position.y,
    w: this.size.x,
    h: this.size.y,
  });

  /** This function returns a normalized [0, 1] value of
   * the likelihood that a customer will not pay for the product.
   * This is based on the distance of the current price from the stock cost.
   */
  getChanceToFail = (): number => {
    // Clamp to 0 and round to 2 decimal places
    const safePercentage = this.kind.index === 0 ? 0.5 : 0.33;
    return Math.max(
      +(
        (this.price - this.cost) / (this.maxPrice - this.cost) -
        safePercentage
      ).toFixed(2),
      0,
    );
  };

  public getMarketingCost(initialCost: number, costPerLevel: number): number {
    return initialCost + Math.floor(costPerLevel * this.demand);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJSON = (obj: any): Product =>
    new Product(
      new ProductKind(obj.kind.group, obj.kind.index),
      obj.cost,
      obj.maxPrice,
      new Vector2(obj.x, obj.y),
      new Vector2(obj.w, obj.h),
      obj.stockLimit,
      {
        price: obj.price,
        demand: obj.demand,
        stock: obj.stock,
      },
    );
}
