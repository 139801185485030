import { consume } from "@lit/context";
import { html, css, nothing, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { OpenProductProps, appState, closeDialog } from "../state/app-state";
import { Product } from "../models/product";
import { error } from "../helpers/loggerHelper";
import { Signal, SignalWatcher, watch } from "@lit-labs/preact-signals";
import { UIImages } from "../data/images";
import { playSoundEffect } from "../sound/sound-manager";
import { gameDialogStyles } from "../styles/game-dialog";
import { greenContainer } from "../styles/theme";

import "./reusable-button";
import "./arrow-container";
import { translate } from "../translations/translations";

@customElement("open-product-dialog")
export class OpenProductDialog extends SignalWatcher(LitElement) {
  static styles = [
    gameDialogStyles,
    greenContainer,
    css`
      :host {
        font-family: Ubuntu;
        font-size: 20px;
        font-weight: 700;
        line-height: normal;
        text-align: left;
        color: var(--primary-navy);
      }

      .wrapper {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        position: absolute;
        top: 0px;
      }

      .header-color {
        height: 67px;
        width: 100%;
        background-color: var(--primary);
      }

      .header {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }

      .header-content {
        display: flex;
        padding: 6px;
      }

      .tabbed-wrapper {
        width: 256px;
        height: 127px;
        display: flex;
        align-items: center;
        padding: 6px;
      }

      .gold-value {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
      }

      .gold-wrapper {
        display: flex;
        width: 100%;
        height: 52px;
        flex-direction: column;
        padding-top: 4px;
      }

      .gold-amount {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      .buy-stock {
        width: 100%;
        font-size: 14px;
        display: flex;
        justify-content: center;
      }

      .button-pill-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .pill {
        width: 100px;
        height: 20px;
        background-color: var(--primary-highlight);
        border-radius: 12px;
        border: 2px solid var(--primary);

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 14px;
        font-weight: 500;
        margin-right: 10px;
      }

      .tab-header {
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px;
        padding: 0px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        font-size: 14px;
        font-weight: 700;
      }

      .tab-header.marketing {
        background-color: var(--secondary-violet-highlight);
      }

      .tab-header.marketing[selected] {
        background-color: var(--violet-lowlight);
      }

      .tab-header.price {
        background-color: var(--light-purple);
      }

      .tab-header.price[selected] {
        background-color: var(--blue-lowlight);
      }

      .tab-section {
        height: 105px;
        box-sizing: border-box;
        border-radius: 0px 0px 4px 4px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }

      .tab-section.price {
        background-color: var(--light-purple);
        box-sizing: border-box;
        border: 2px solid var(--blue-lowlight);
      }

      .tab-section.marketing {
        background-color: var(--secondary-violet-highlight);
        box-sizing: border-box;
        border: 2px solid var(--violet);

        font-size: 14px;
        font-weight: 700;

        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        display: flex;
        padding: 6px;
        margin-bottom: 8px;
      }

      #price-input {
        -webkit-appearance: none;
        width: 188px;
        height: 2px;
        border-radius: 5px;
        background: var(--primary-navy);
        outline: none;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
        box-shadow: none;
        margin: 0px;
        z-index: 2;
      }

      #price-input::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        scrollbar-highlight-color: none;
        width: 28px;
        height: 28px;
        border: 0;
        background: url("assets/misc-game/icon_coin_colour.svg");
        cursor: pointer;
      }

      #price-input::-moz-range-thumb {
        appearance: none;
        width: 28px;
        height: 28px;
        border: 0;
        background: url("assets/misc-game/icon_coin_colour.svg");
        cursor: pointer;
      }

      .slider {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
      }

      .slider-end {
        width: 4px;
        height: 4px;
        border: 2px solid var(--primary-navy);
        background-color: var(--primary-navy);
        border-radius: 50%;
        z-index: 1;
      }

      .slider-wrapper {
        display: flex;
        padding-top: 15px;
        align-items: center;
      }

      .slider-text {
        display: flex;
        align-items: center;
        font-size: 14px;
        justify-content: space-between;
        width: 100%;
      }

      .marketing-bar-wrapper {
        font-weight: 500;
        padding-top: 4px;
        padding-bottom: 4px;
      }

      .marketing-bar-outer {
        width: 236px;
        height: 10px;
        box-sizing: border-box;
        border: 2px solid var(--primary-navy);
        border-radius: 6px;
        background-color: var(--white);
      }

      .marketing-bar-inner {
        width: 50%;
        height: 100%;
        border-radius: 10px;
        background-color: var(--violet);
      }

      .levels {
        display: flex;
      }

      .level-indicator {
        display: flex;
        justify-content: space-between;
      }

      .level-circle {
        display: flex;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 2px solid var(--primary-navy);
        background-color: var(--white);
        color: var(--primary-navy);
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        margin: 2px;
      }

      .level-circle.completed {
        background-color: var(--violet);
        color: var(--white);
      }

      .marketing-button {
        display: flex;
        justify-content: center;
        translate: 0px -50%;
      }
    `,
  ];

  // Slider with image was taken from here:
  // https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_js_rangeslider_pic

  @consume({ context: gameContext })
  game: GameController;

  @state()
  nextPromoteCost: number;

  @state()
  itemPrice: number;

  @query("#price-input")
  priceSlider: HTMLInputElement;

  private gold: Signal<number>;

  private product: Product;
  connectedCallback() {
    super.connectedCallback();
    this.product = (
      appState.dialog.value.props as unknown as OpenProductProps
    ).product;
    if (!this.product || !(this.product instanceof Product)) {
      error(
        "[open-product-dialog] Wrong properties passed to this dialog!",
        appState.dialog.value,
      );
      closeDialog();
    } else {
      this.calculateDemand();
      this.itemPrice = this.product.price;
    }
    this.gold = this.game.getModel().getUIWatchableModel().gold;
  }

  addStock = () => {
    this.game.buyStock(this.product);
    playSoundEffect("Purchase");
  };
  addDemand = () => {
    this.game.promoteProduct(this.product);
    this.calculateDemand();
    playSoundEffect("Purchase");
  };
  calculateDemand = () => {
    const { cost, increasePerLevel } = this.game.gameplayParams.marketing;
    this.nextPromoteCost = this.product.getMarketingCost(
      cost,
      increasePerLevel,
    );
  };
  onPriceChanged = () => {
    this.game.setProductPrice(this.product, +this.priceSlider.value);
  };

  renderMarketingLevelCircle = (marketingLevel: number, level: number) => {
    return html`
      <div
        class=${level <= marketingLevel
          ? "level-circle completed"
          : "level-circle"}
      >
        ${level}
      </div>
    `;
  };

  displayMarketingLevel = (marketingLevel: number) => {
    const levelCircles = [];
    for (let i = 1; i <= 5; i++) {
      levelCircles.push(this.renderMarketingLevelCircle(marketingLevel, i));
    }
    return html`<div class="levels">${levelCircles}</div> `;
  };

  render() {
    if (!this.product || !(this.product instanceof Product)) return nothing;
    const isStockEnabled =
      this.product.cost <= this.gold.value &&
      this.product.stock.value < this.product.stockLimit;
    const isMaxStock = this.product.stock.value === this.product.stockLimit;
    const isMaxDemand =
      this.product.demand >= this.game.gameplayParams.marketing.maxDemand;

    const isPromoteEnabled =
      this.nextPromoteCost <= this.gold.value && !isMaxDemand;
    const marketingLevel = Math.floor(this.product.demand / 10);

    return html`
      <arrow-container>
        <div class="wrapper">
          <div class="header">
            <div class="header-content">${this.product.kind.productName()}</div>
            <img
              src=${UIImages.Cross_Active}
              class="header-content"
              @click=${closeDialog}
            />
          </div>

          <div class="button-pill-container">
            <reusable-button
              @click=${this.addStock}
              class="gold-value"
              ?disabled=${!isStockEnabled}
            >
              <div class="gold-wrapper">
                <div class="gold-amount">
                  <img src=${UIImages.CoinColor} height="28px" />
                  ${isMaxStock
                    ? translate("ItemPopUp_Button_Full")
                    : this.product.cost}
                </div>
                <div class="buy-stock">
                  ${translate("ItemPopUp_Button_BuyStock")}
                </div>
              </div>
            </reusable-button>
            <div class="pill">
              ${watch(this.product.stock)} ${translate("ItemPopUp_InStock")}
            </div>
          </div>

          <div class="tabbed-wrapper">
            <tabbed-container>
              <h2 class="tab-header price" slot="tab">
                ${translate("ItemPopUp_SellingPrice")}
              </h2>
              <section slot="panel">
                <div class="tab-section price">
                  <div class="slider">
                    <div class="slider-wrapper">
                      <div class="slider-end" style="translate: 8px 0px;"></div>
                      <input
                        type="range"
                        min=${this.product.cost + 1}
                        max=${this.product.maxPrice}
                        value=${this.itemPrice}
                        class="price-slider"
                        @input=${this.onPriceChanged}
                        id="price-input"
                      />
                      <div
                        class="slider-end"
                        style="translate: -8px 0px;"
                      ></div>
                    </div>
                    <div class="slider-text">
                      <div style="padding: 8px">
                        ${translate("ItemPopUp_LowRisk")}
                      </div>
                      <div style="padding: 8px">
                        ${translate("ItemPopUp_HighRisk")}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <h2 class="tab-header marketing" slot="tab">
                ${translate("ItemPopUp_Marketing")}
              </h2>
              <section slot="panel">
                <div class="tab-section marketing">
                  ${translate("ItemPopUp_MoreCustomers")}
                  <div class="marketing-bar-wrapper">
                    <div class="level-indicator">
                      <div>${translate("ItemPopUp_Level")}</div>
                      ${this.displayMarketingLevel(marketingLevel)}
                    </div>
                    <div class="marketing-bar-outer">
                      <div
                        class="marketing-bar-inner"
                        style=${"width:" + this.product.demand * 2 + "%"}
                      ></div>
                    </div>

                    <div
                      style="font-size: 14px; weight: 500; padding-top: 8px; margin: 0px;"
                    >
                      ${translate("ItemPopUp_Marketing02")}
                    </div>
                  </div>
                </div>
                <reusable-button
                  class="marketing-button"
                  ?disabled=${!isPromoteEnabled}
                  @click=${this.addDemand}
                >
                  <div
                    style="width: 100%;  margin:  8px; display: flex; align-items: center;"
                  >
                    <img
                      src=${UIImages.CoinColor}
                      style="padding-right: 8px;"
                    />
                    ${isMaxDemand
                      ? translate("ItemPopUp_Button_Max")
                      : this.nextPromoteCost}
                  </div>
                </reusable-button>
              </section>
            </tabbed-container>
          </div>
        </div>
      </arrow-container>
    `;
  }
}
