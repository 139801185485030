import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { buttonStyle } from "../styles/theme";

@customElement("arrow-container")
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ArrowContainer extends LitElement {
  static styles = [
    buttonStyle,
    css`
      :host {
        width: 272px;
        height: 242px;
      }
      .speech-bubble {
        width: 272px;
        height: 242px;
        box-sizing: border-box;
        border-radius: 6px;
        border: 2px solid var(--primary-navy);
        background: var(--primary-white);
        display: flex;
        position: relative;
        flex-direction: column;
        position: absolute;
      }

      .header-color {
        width: 100%;
        height: 67px;
        background-color: #6ecfbc;
        border-radius: 4px 4px 0px 0px;
        position: relative;
        top: 0px;
      }
    `,
  ];

  @property({ type: Boolean })
  isLeft: boolean;

  renderDialogueArrow = (isLeft: boolean) => html`
    <dialog-arrow direction=${isLeft ? "left" : "right"}></dialog-arrow>
  `;

  render() {
    return html`
      <div class="speech-bubble">
        <div class="header-color">
          <slot></slot>
        </div>
        ${this.renderDialogueArrow(false)}
      </div>
    `;
  }
}
