import { Vector2 } from "../models/vector2";
import { warn } from "../helpers/loggerHelper";
import { Employee, EmployeeState } from "../models/employee";
import { GameModel } from "../models/gameModel";
import { IGameView } from "../views/gameView";
import { WalkAwayReason } from "../models/customer";
import { mapData } from "./mapData";
import {
  EventTriggerCategories,
  IEventParams,
  IGameplayParams,
} from "../config/gameplayParameters";
import { Subcontroller } from "./subcontroller";
import { queueEvent } from "../state/game-state";
import { playSoundEffect } from "../sound/sound-manager";

export class EmployeeController extends Subcontroller {
  private baseHiringCost: number;
  private hiringMultiplier: number;
  private employeeIdleEvents: IEventParams[];
  private employeeSpeed: number;
  private employeeSpeedMultiplier: number;

  constructor(model: GameModel, view: IGameView, params: IGameplayParams) {
    super(model, view);

    this.baseHiringCost = params.staff.hiringCost;
    this.hiringMultiplier = params.staff.multiplierPerStaff;
    this.employeeIdleEvents = params.events.filter(
      (ev) => ev.trigger.category === EventTriggerCategories.StaffIdleTime,
    );
    this.employeeSpeedMultiplier = params.staff.orderSpeedMultiplier;
  }

  startGame = (newGame: boolean) => {
    if (newGame) {
      this.model.setEmployeeHiringCost(this.baseHiringCost);
    }
  };

  updateParams = (params: IGameplayParams) => {
    if (
      this.baseHiringCost !== params.staff.hiringCost ||
      this.hiringMultiplier !== params.staff.multiplierPerStaff
    ) {
      this.baseHiringCost = params.staff.hiringCost;
      this.hiringMultiplier = params.staff.multiplierPerStaff;
      let currentHiringCost = this.baseHiringCost;
      const paidHires = this.model
        .allEmployees()
        .filter((e) => e.getCost()).length;
      for (let i = 0; i < paidHires; i++) {
        currentHiringCost *= this.hiringMultiplier;
      }
      this.model.setEmployeeHiringCost(currentHiringCost);
    }

    this.employeeIdleEvents = params.events.filter(
      (ev) => ev.trigger.category === EventTriggerCategories.StaffIdleTime,
    );
    if (this.employeeSpeedMultiplier !== params.staff.orderSpeedMultiplier) {
      this.employeeSpeedMultiplier = params.staff.orderSpeedMultiplier;
    }

    if (this.employeeSpeed !== params.staff.speed) {
      this.employeeSpeed = params.staff.speed;
      this.model.allEmployees().forEach((e) => (e.speed = this.employeeSpeed));
    }
  };

  hire = (tillID: string, freeHire?: boolean) => {
    const employee = this.spawn(tillID);
    if (employee && !freeHire) {
      const c = this.model.getEmployeeHiringCost();
      employee.setHiringCost(c);
      this.model.setEmployeeHiringCost(c * this.hiringMultiplier);
    }
    return employee?.id;
  };

  private spawn = (tillID: string) => {
    const till = this.model.getShop()?.getTill(tillID);
    if (!till) {
      warn("Till does not exist, can't spawn employee");
      return;
    }

    const e = new Employee(till.x, till.y + 100, tillID, {
      speed: this.employeeSpeed,
    });
    this.model.addEmployee(e, true);
    const spriteIndex = this.model.allEmployees().length - 1;
    this.view.addEmployee(e, spriteIndex);
    return e;
  };

  tickAll = (delta: number) => {
    this.model.allEmployees().forEach((e: Employee) => {
      this.tick(e, delta);
      this.view.updateEmployee(e);
    });
  };

  private tick = (employee: Employee, delta: number) => {
    if (!employee) {
      return;
    }
    switch (employee.getState()) {
      case EmployeeState.WalkingToTill:
      case EmployeeState.TakingProductToTill:
        this.walkToTillTick(employee, delta);
        break;
      case EmployeeState.TakingOrder:
        this.takeOrderTick(employee, delta * this.employeeSpeedMultiplier);
        break;
      case EmployeeState.WalkingToProduct:
        this.walkToProduct(employee, delta);
        break;
      case EmployeeState.TakingOutProduct:
        this.takeOutProduct(employee, delta * this.employeeSpeedMultiplier);
        break;
      case EmployeeState.SellingProduct:
        this.sellProduct(employee, delta * this.employeeSpeedMultiplier);
        break;
      case EmployeeState.WaitingAtTill:
        this.waitAtTillTick(employee, delta);
        break;
    }
  };

  private walkToTillTick = (employee: Employee, delta: number) => {
    const till = this.model.getShop()?.getTill(employee.getTill());
    if (!till) {
      warn(`Employee can't find till to walk to ${employee.getTill()}`);
      return;
    }

    // TODO: base the offset on the till's and employee's size.
    // TODO: update the offset in the employeeDirectionHelper [SM-AGEC-224]
    const employeeTillPosition = till
      .position()
      .add(new Vector2(0, mapData.tileSize));

    if (employee.moveTo(employeeTillPosition, delta)) {
      if (employee.getState() === EmployeeState.TakingProductToTill) {
        employee.sellProduct();
      } else {
        employee.waitAtTill();
      }
    }
  };
  private takeOrderTick = (employee: Employee, delta: number) => {
    if (employee.makeProgress(delta)) {
      employee.walkToProduct();
    }
  };
  private walkToProduct = (employee: Employee, delta: number) => {
    const product = this.model.getShop()?.product(employee.orderProduct());
    if (!product) {
      warn(`Employee can't find product to walk to ${employee.orderProduct()}`);
      return;
    }
    // TODO: base the offset on the product's and employee's size.
    const employeeProductPosition = product.position.subtract(
      new Vector2(0, mapData.tileSize),
    );
    if (employee.moveTo(employeeProductPosition, delta)) {
      employee.takeProductOut();
    }
  };
  private takeOutProduct = (employee: Employee, delta: number) => {
    if (employee.makeProgress(delta)) {
      employee.takeProductToTill();
    }
  };
  private sellProduct = (employee: Employee, delta: number) => {
    if (employee.makeProgress(delta)) {
      const till = this.model.getShop()?.getTill(employee.getTill());
      if (till) {
        const customer = this.model.customer(till.claimedBy());
        if (customer) {
          customer.walkAway(WalkAwayReason.ProductPurchased);
          playSoundEffect("Sale");
          this.model.sellProduct(
            customer.need.purchasePrice,
            customer.need.kind,
          );
        }
        till.releaseClaim();
      }
      employee.waitAtTill();
    }
  };
  private waitAtTillTick = (employee: Employee, delta: number) => {
    const till = this.model.getShop()?.getTill(employee.getTill());
    if (!till) {
      warn(`Employee can't find till to walk to ${employee.getTill()}`);
      return;
    }

    // TIMER FOR DISPLAYING EVENT
    if (employee.idle) {
      employee.idleTime += delta;
      this.employeeIdleEvents.forEach((ev) => {
        if (this.model.events.isEventCompleted(ev.id)) return;
        if (ev.trigger.value * 1000 < employee.idleTime) {
          queueEvent(ev);
          this.model.events.setEventCompleted(ev.id);
        }
      });
    }

    // MAN TILL
    if (
      employee.getState() === EmployeeState.WaitingAtTill &&
      !till.isManned() &&
      !employee.idle
    ) {
      till.man(employee.id);
    }

    // UNMAN TILL
    if (
      employee.getState() === EmployeeState.WaitingAtTill &&
      employee.idle &&
      till.isManned()
    ) {
      till.unman();
    }
  };

  idleAll = () => {
    this.model.allEmployees().forEach(this.idle);

    this.model.checkTotalSalariesDue();
  };

  private idle = (employee: Employee) => {
    if (employee.getCost()) {
      employee.idle = true;
      employee.idleTime = 0;
      this.view.updateEmployee(employee);
    }
  };

  payEmployee = (employee: Employee) => {
    if (this.model.payEmployee(employee.getCost())) {
      employee.receivePayment();
      this.view.updateEmployee(employee);
      this.model.checkTotalSalariesDue();
    }
  };
}
