import { CopyKey } from "../translations/keys";

interface TipTabInfo {
  title: CopyKey;
  body: CopyKey[];
}

export const TipsTab = {
  Tip_MissedSalesNoStaffAvailable: {
    title: "Tip_NoStaff_Heading",
    body: ["Tip_NoStaff_Body"],
  } as TipTabInfo,
  Tip_MissedSalesPriceTooHigh: {
    title: "Tip_HighPrice_Heading",
    body: ["Tip_HighPrice_Body01", "Tip_HighPrice_Body02"],
  } as TipTabInfo,
  Tip_MissedSaleLackOfMarketing: {
    title: "Tip_NoMarketing_Heading",
    body: ["Tip_NoMarketing_Body"],
  } as TipTabInfo,
  Tip_MissedSaleOutofStock: {
    title: "Tip_OutOfStock_Heading",
    body: ["Tip_OutOfStock_Body"],
  } as TipTabInfo,
  Tip_ProfitOrLoss: {
    title: "Tip_ProfitOrLoss_Heading",
    body: ["Tip_ProfitOrLoss_Body"],
  } as TipTabInfo,
  Tip_Expenses: {
    title: "Tip_Expenses_Heading",
    body: ["Tip_Expenses_Body"],
  } as TipTabInfo,
  Tip_Revenue: {
    title: "Tip_Revenue_Heading",
    body: ["Tip_Revenue_Body"],
  } as TipTabInfo,
  Tip_CommunityImpact: {
    title: "Tip_CommunityImpact_Heading",
    body: ["Tip_CommunityImpact_Body01", "Tip_CommunityImpact_Body02"],
  } as TipTabInfo,
  Tip_Stock: {
    title: "Tip_Stock_Heading",
    body: ["Tip_Stock_Body"],
  } as TipTabInfo,
  Tip_Cash: {
    title: "Tip_Cash_Heading",
    body: ["Tip_Cash_Body"],
  } as TipTabInfo,
};

export declare type TipTabKeys = keyof typeof TipsTab;
