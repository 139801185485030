export class Stats {
  // The total number of customers spawned so far
  private numCustomers: number = 0;

  customerSpawned = () => {
    this.numCustomers++;
  };

  toJSON = (): object => ({
    numCustomers: this.numCustomers,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJSON = (obj: any): Stats => {
    const s = new Stats();
    s.numCustomers = obj.numCustomers;
    return s;
  };
}
