import { HTMLTemplateResult, css, html, nothing } from "lit";
import { customElement, state } from "lit/decorators.js";
import { consume } from "@lit/context";
import { LitElementI18n } from "../translations/lit-mixin";
import { SignalWatcher } from "@lit-labs/preact-signals";
import { buttonStyle } from "../styles/theme";
import { ProductGroup } from "../data/products";
import { GameController } from "../controllers/gameController";
import { gameContext } from "../gameContext";
import { translate } from "../translations/translations";
import { playSoundEffect } from "../sound/sound-manager";
import { ChooseShopCategoryImages } from "../data/images";
import { UIImages } from "../data/images";
import { CopyKey } from "../translations/keys";

@customElement("business-selector-carousel")
export class BusinessSelectorCarousel extends SignalWatcher(LitElementI18n) {
  static styles = [
    buttonStyle,
    css`
      :host {
        width: 100%;
        height: 100%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .content-header {
        display: flex;
        flex-direction: column;
        background: var(--primary);
        width: 100%;
        height: 36px;
        flex-grow: 0;
        @media (min-width: 1000px) {
          height: 54px;
        }
      }

      .content-header-text {
        color: var(--primary-navy);
        text-align: center;
        font-family: Ubuntu;
        font-size: 20px;
        font-weight: 700;
        margin: 7px 0;
        @media (min-width: 1000px) {
          font-size: 30px;
        }
      }

      .content-background {
        display: flex;
        position: relative;
        flex-direction: column;
        background: var(--white);
        width: 408px;
        height: 266px;
        border: 3px solid var(--primary-navy);
        border-radius: 4px;
        @media (min-width: 1000px) {
          width: 618px;
          height: 399px;
        }
      }

      .content-container {
        width: 100%;
        flex-grow: 1;
      }

      .shop-type-pill-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .shop-type-pill {
        width: 136px;
        height: 16px;
        padding: 4px 10px;
        border-radius: 12px;
        margin-top: 8px;
        @media (min-width: 1000px) {
          width: 204px;
          height: 24px;
          padding: 6px 15px;
          border-radius: 18px;
          margin-top: 12px;
        }
      }

      .coffeeShop-pill-color {
        background: var(--coffeeShopPil, rgba(255, 196, 102, 0.5));
      }
      .fashionShop-pill-color {
        background: var(--fashionShopPill, rgba(248, 98, 63, 0.5));
      }
      .stationeryShop-pill-color {
        background: var(--stationeryShopPill, rgba(110, 207, 188, 0.5));
      }
      .techShop-pill-color {
        background: var(--techShopPill, rgba(142, 161, 255, 0.5));
      }
      .toiletryShop-pill-color {
        background: var(--toiletryShopPill, rgba(142, 89, 255, 0.5));
      }

      .shop-type-pill-text {
        color: var(--primary-navy);
        text-align: center;
        font-family: Ubuntu;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media (min-width: 1000px) {
          font-size: 21px;
        }
      }

      .shop-type-img-and-pager-buttons-container {
        height: 144px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
        gap: 16px;
        @media (min-width: 1000px) {
          height: 216px;
          margin-top: 12px;
          gap: 24px;
        }
      }

      .breadcrumb-container {
        justify-content: center;
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 6px;
        @media (min-width: 1000px) {
          gap: 12px;
          margin-top: 9px;
        }
      }

      .select-btn-container {
        display: flex;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 50%);
        bottom: 0px;
      }

      .pager-btn {
        display: grid;
        place-content: center;
        width: 40px;
        height: 40px;
        border-radius: 59px;
        border: 2px solid var(--primary);
        background: var(--primary-highlight);
        outline: none;
        -webkit-tap-highlight-color: transparent;
      }

      .chevron {
        width: 20px;
        height: 20px;
      }

      .shop-type-img {
        width: 144px;
        height: 144px;
        margin-top: 8px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        place-items: center;
        @media (min-width: 1000px) {
          height: 216px;
          width: 216px;
          margin-top: 12px;
        }
      }
      .shop-type-img > img {
        max-width: 100%;
        max-height: 100%;
      }

      .circle-size {
        @media (min-width: 1000px) {
          width: 12px;
          height: 12px;
          cx: 6.5;
          cy: 6;
          r: 6;
        }
      }
    `,
  ];

  @consume({ context: gameContext })
  game: GameController;

  @state()
  slide: number = 0;

  connectedCallback() {
    super.connectedCallback();
  }

  leftPagerBtnClick = () => {
    this.slide--;
    if (this.slide < 0) {
      this.slide = 4;
    }
    playSoundEffect("Swipe");
  };

  rightPagerBtnClick = () => {
    this.slide++;
    if (this.slide > 4) {
      this.slide = 0;
    }
    playSoundEffect("Swipe");
  };

  setBusinessType = (type: ProductGroup) => {
    this.game.setBusinessType(type);
    this.nextIntroPage();
  };

  nextIntroPage = () => {
    this.dispatchEvent(
      new Event("continue", {
        bubbles: true,
        composed: true,
      }),
    );
  };

  renderSlideBreadcrumb = (selected: boolean) => {
    return html`
      <svg
        class="circle-size"
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill=${selected ? "#16006D" : "#6ECFBC"}
      >
        <circle cx="4" cy="4" r="4" />
      </svg>
    `;
  };

  renderSlideBreadcrumbs = (): HTMLTemplateResult[] => {
    const result = [];
    for (let i = 0; i < 5; i++) {
      result.push(this.renderSlideBreadcrumb(i === this.slide));
    }
    return result;
  };

  render() {
    let pillColor: string;
    let headerKey: CopyKey;
    let imgSrc: string;
    let onClick: () => void;
    switch (this.slide) {
      case 0:
        pillColor = "coffeeShop-pill-color";
        headerKey = "BusinessType_CoffeeShop";
        imgSrc = ChooseShopCategoryImages.ChooseShopCategory_Coffee;
        onClick = () => this.setBusinessType(ProductGroup.CoffeeShop);
        break;
      case 1:
        pillColor = "fashionShop-pill-color";
        headerKey = "BusinessType_Fashion";
        imgSrc = ChooseShopCategoryImages.ChooseShopCategory_Clothing;
        onClick = () => this.setBusinessType(ProductGroup.Clothing);
        break;
      case 2:
        pillColor = "stationeryShop-pill-color";
        headerKey = "BusinessType_Stationery";
        imgSrc = ChooseShopCategoryImages.ChooseShopCategory_Stationery;
        onClick = () => this.setBusinessType(ProductGroup.Stationary);
        break;
      case 3:
        pillColor = "techShop-pill-color";
        headerKey = "BusinessType_Technology";
        imgSrc = ChooseShopCategoryImages.ChooseShopCategory_Technology;
        onClick = () => this.setBusinessType(ProductGroup.Technology);
        break;
      case 4:
        pillColor = "toiletryShop-pill-color";
        headerKey = "BusinessType_HairAndBeauty";
        imgSrc = ChooseShopCategoryImages.ChooseShopCategory_Toiletries;
        onClick = () => this.setBusinessType(ProductGroup.Toiletries);
        break;
      default:
        return nothing;
    }

    return html`
      <div class="content-background">
        <div class="content-header">
          <div class="content-header-text">
            ${translate("BusinessType_Choice")}
          </div>
        </div>
        <div class="content-container">
          <div class="shop-type-pill-container">
            <div class="shop-type-pill ${pillColor}">
              <div class="shop-type-pill-text">${translate(headerKey)}</div>
            </div>
          </div>
          <div class="shop-type-img-and-pager-buttons-container">
            <button class="pager-btn" @click=${this.leftPagerBtnClick}>
              <img src=${UIImages.Cheveron_Left_Active} class="chevron" />
            </button>
            <div class="shop-type-img">
              <img src=${imgSrc} />
            </div>
            <button class="pager-btn" @click=${this.rightPagerBtnClick}>
              <img src=${UIImages.Cheveron_Right_Active} class="chevron" />
            </button>
          </div>
          <div class="breadcrumb-container">
            ${this.renderSlideBreadcrumbs()}
          </div>
        </div>
        <div class="select-btn-container">
          <app-button
            text=${translate("BusinessType_Button_Select")}
            @click=${onClick}
          ></app-button>
        </div>
      </div>
    `;
  }
}
