import { CopyKey } from "../translations/keys";

interface EventCopy {
  titleKey: CopyKey;
  bodyKey: CopyKey[];
}

export const EventsTabs: Record<string, EventCopy> = {
  //SHOP LEVEL UPGRADES
  "shop-2": {
    titleKey: "Event_LevelUp_Heading",
    bodyKey: [
      "Event_Level_02_Upgrade_Body01",
      "Event_Level_02_Upgrade_Body02",
      "Event_Level_02_Upgrade_Body03",
    ],
  } as EventCopy,
  "shop-3": {
    titleKey: "Event_LevelUp_Heading",
    bodyKey: ["Event_Level_03_Upgrade_Body01", "Event_Level_03_Upgrade_Body02"],
  } as EventCopy,
  "shop-4": {
    titleKey: "Event_LevelUp_Heading",
    bodyKey: ["Event_Level_04_Upgrade_Body01", "Event_Level_04_Upgrade_Body02"],
  } as EventCopy,
  "shop-5": {
    titleKey: "Event_LevelUp_Heading",
    bodyKey: ["Event_Level_05_Upgrade_Body01"],
  } as EventCopy,

  //FIRST PROFIT/LOSS
  "first-profit": {
    titleKey: "Event_Profit_Heading",
    bodyKey: ["Event_Profit_Body"],
  } as EventCopy,
  "first-loss": {
    titleKey: "Event_Loss_Heading",
    bodyKey: ["Event_Loss_Body"],
  } as EventCopy,

  //STAFF HIRES
  "hired-1st-staff": {
    titleKey: "Event_NewStaff_Heading",
    bodyKey: ["Event_Staff01_Body01", "Event_Staff01_Body02"],
  } as EventCopy,
  "hired-2nd-staff": {
    titleKey: "Event_NewStaff_Heading",
    bodyKey: ["Event_Staff02_Body01", "Event_Staff02_Body02"],
  } as EventCopy,
  "hired-3rd-staff": {
    titleKey: "Event_NewStaff_Heading",
    bodyKey: ["Event_Staff03_Body01"],
  } as EventCopy,
  "hired-4th-staff": {
    titleKey: "Event_NewStaff_Heading",
    bodyKey: ["Event_Staff04_Body01"],
  } as EventCopy,
  "hired-5th-staff": {
    titleKey: "Event_NewStaff_Heading",
    bodyKey: ["Event_Staff05_Body01"],
  } as EventCopy,
  "hired-6th-staff": {
    titleKey: "Event_NewStaff_Heading",
    bodyKey: ["Event_Staff06_Body01"],
  } as EventCopy,
  "hired-7th-staff": {
    titleKey: "Event_NewStaff_Heading",
    bodyKey: ["Event_Staff07_Body01"],
  } as EventCopy,

  //PRODUCTS LAUNCHED
  "product-1-added": {
    titleKey: "Event_FirstProduct_Heading",
    bodyKey: [
      "Event_FirstProduct_Body01",
      "Event_FirstProduct_Body02",
      "Event_FirstProduct_Body03",
    ],
  } as EventCopy,

  //LOST SALES
  "lost-sale-price": {
    titleKey: "Event_Tip_Heading",
    bodyKey: ["Event_Expensive_Body01", "Event_Expensive_Body02"],
  } as EventCopy,
  "lost-sale-price-n": {
    titleKey: "Event_Tip_Heading",
    bodyKey: ["Not_Implemented"],
  } as EventCopy,
  "lost-sale-no-stock": {
    titleKey: "Event_Tip_Heading",
    bodyKey: ["Event_NoStock_Body_01"],
  } as EventCopy,
  "lost-sale-no-stock-n": {
    titleKey: "Event_Tip_Heading",
    bodyKey: ["Not_Implemented"],
  } as EventCopy,
  "lost-sale-no-staff": {
    titleKey: "Event_StaffNeeded_Heading",
    bodyKey: ["Event_StaffNeeded_Body01", "Event_StaffNeeded_Body02"],
  } as EventCopy,
  "lost-sale-no-staff-n": {
    titleKey: "Event_StaffNeeded_Heading",
    bodyKey: ["Not_Implemented"],
  } as EventCopy,
  "lost-sale-no-marketing": {
    titleKey: "Event_NoMarketing_Heading",
    bodyKey: ["Event_NoMarketing_Body01", "Event_NoMarketing_Body02"],
  } as EventCopy,
  "lost-sale-no-marketing-n": {
    titleKey: "Event_NoMarketing_Heading",
    bodyKey: ["Not_Implemented"],
  } as EventCopy,

  //BUSINESS VALUE
  "business-value-1": {
    titleKey: "Event_BusinessValue_01_Heading",
    bodyKey: ["Event_BusinessValue_01_Body"],
  } as EventCopy,
  "business-value-2": {
    titleKey: "Event_BusinessValue_02_Heading",
    bodyKey: ["Event_BusinessValue_02_Body"],
  } as EventCopy,
  "business-value-3": {
    titleKey: "Event_BusinessValue_03_Heading",
    bodyKey: ["Event_BusinessValue_03_Body"],
  } as EventCopy,
  "business-value-4": {
    titleKey: "Event_BusinessValue_04_Heading",
    bodyKey: ["Event_BusinessValue_04_Body"],
  } as EventCopy,
  "business-value-5": {
    titleKey: "Event_BusinessValue_05_Heading",
    bodyKey: ["Event_BusinessValue_05_Body"],
  } as EventCopy,
  "business-value-6": {
    titleKey: "Not_Implemented",
    bodyKey: ["Not_Implemented"],
  } as EventCopy,

  //CASH VALUE
  "cash-value": {
    titleKey: "Not_Implemented",
    bodyKey: ["Not_Implemented"],
  } as EventCopy,

  //SALARY DUE
  "salary-first-due-1-second": {
    titleKey: "Event_SalaryDue_Heading",
    bodyKey: ["Event_SalaryDue_Body01", "Event_SalaryDue_Body02"],
  } as EventCopy,
  "salary-first-due-30-seconds": {
    titleKey: "Event_Strike_Heading",
    bodyKey: ["Event_Strike_Body"],
  } as EventCopy,

  //NUMBER OF SALES
  "number-of-sales-equals-1": {
    titleKey: "Event_FirstSale_Heading",
    bodyKey: ["Event_FirstSale_Body01", "Event_FirstSale_Body02"],
  } as EventCopy,
  "number-of-sales-equals-n": {
    titleKey: "Not_Implemented",
    bodyKey: ["Not_Implemented"],
  } as EventCopy,

  //NEXT PRODUCT/STAFF
  "cash-cost-of-next-product-x": {
    titleKey: "Event_Expand_Items_Heading",
    bodyKey: ["Event_Expand_Items_Body"],
  } as EventCopy,
  "cash-cost-of-next-staff-x": {
    titleKey: "Event_Expand_Staff_Heading",
    bodyKey: ["Event_Expand_Staff_Body"],
  } as EventCopy,

  //AFTER PAYDAY
  "payday-1": {
    titleKey: "Event_AfterPayday01_Heading",
    bodyKey: [
      "Event_AfterPayday01_Body01",
      "Event_AfterPayday01_Body02",
      "Event_AfterPayday01_Body03",
    ],
  } as EventCopy,
  "payday-2": {
    titleKey: "Event_AfterPayday02_Heading",
    bodyKey: ["Event_AfterPayday02_Body01"],
  } as EventCopy,
  "payday-6": {
    titleKey: "Event_AfterPayday06_Heading",
    bodyKey: ["Event_AfterPayday06_Body01", "Event_AfterPayday06_Body02"],
  } as EventCopy,
  "payday-11": {
    titleKey: "Event_AfterPayday011_Heading",
    bodyKey: ["Event_AfterPayday011_Body01"],
  } as EventCopy,
};
