import { info } from "../helpers/loggerHelper";
if (__BUILD_ENV__ !== "TEST") {
  window.dataLayer = window.dataLayer || [];
}

declare type EventKey = "LoginSuccess" | "GameStart";

function evt(
  event: EventKey,
  eventParams: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  },
) {
  if (__BUILD_ENV__ === "TEST") return;
  info("Analytics event", {
    event,
    params: eventParams,
  });
  window.dataLayer.push({
    event,
    ...eventParams,
  });
}

export function LoginSuccessEvent(
  profile_id: string,
  name: string,
  email: string,
) {
  evt("LoginSuccess", {
    profile_id,
    name,
    email,
  });
}

export function GameStartEvent(game_type: "continuation" | "new_game") {
  evt("GameStart", {
    game_type,
  });
}
