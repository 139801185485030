import { css, html, LitElement } from "lit";
import { provide } from "@lit/context";
import { customElement, property, state } from "lit/decorators.js";
import { borderStyles, buttonStyle } from "../styles/theme";
import { setGlobalSoundOn } from "../sound/sound-manager";
import { UIImages } from "../data/images";
import { when } from "lit/directives/when.js";
import { translate } from "../translations/translations";
import { appState, Screen, setIsMuted, setScreen } from "../state/app-state";
import { usingDevBackend } from "../env";
import { PortalAPIDev } from "../portal/dev";
import { apiState, RequestStatus } from "../state/api-state";
import { isOnline } from "../helpers/connectivityHelper";
import { logOutUser } from "../user";
import { installApp } from "../install";
import { SignalWatcher } from "@lit-labs/preact-signals";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";

@customElement("hamburger-menu")
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class HamburgerMenu extends SignalWatcher(LitElement) {
  static styles = [
    buttonStyle,
    borderStyles,
    css`
      /*Hamburger menu button */
      .menu-button-events {
        touch-action: auto;
        pointer-events: auto;
      }

      app-button {
        width: 44px;
        height: 44px;
      }

      /*Hamburger menu content */
      .menu-background {
        position: absolute;
        top: 8px;
        left: 8px;
        min-width: 179px;

        display: flex;
        flex-direction: column;
        background: var(--white);
        touch-action: auto;
        pointer-events: auto;
      }

      .menu-header {
        display: flex;
        flex-direction: row;
        background: var(--primary);
        height: 36px;

        place-items: center;
      }

      .menu-avatar-container {
        width: 20px;
        height: 20px;
        flex-grow: 0;
        margin: 0 10px;
      }

      .menu-avatar {
        width: 100%;
        height: 100%;
      }

      .menu-username {
        color: var(--primary-navy);
        font-family: Ubuntu;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;

        flex-grow: 1;
      }

      .menu-close-btn {
        width: 20px;
        height: 20px;
        flex-grow: 0;
        padding: 0;
        margin: 0 13px;
      }

      .menu-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        margin: 14px;
      }

      .sub-menu-btn-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        width: 136px;
        height: 48px;
        gap: 10px;
      }

      .sub-menu-btn-text {
        margin-left: 10px;
        color: var(--primary-navy);
        font-family: Ubuntu;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    `,
  ];

  @property({ type: String })
  placeholderProfileName: string = "Guest";

  @state()
  displayMenu: boolean;

  @property({ type: Boolean })
  disabled: boolean;

  renderMenu = () => {
    this.displayMenu = true;
  };

  renderMenuBtn = () => {
    this.displayMenu = false;
  };

  toggleMute = () => {
    setIsMuted(!appState.isMuted.value);
    setGlobalSoundOn(appState.isMuted.value);
  };

  @provide({ context: gameContext })
  game: GameController;

  onHome = () => {
    if (appState.scene.value === Screen.Game) {
      // clean up game
      this.game.exitGame();
    }
    setScreen(Screen.MainMenu);
  };

  onSignOut = () => {
    if (usingDevBackend) {
      PortalAPIDev.signOut();
      return;
    }
    if (
      apiState.userRequest.value?.status !== RequestStatus.Success ||
      !apiState.userRequest.value?.data ||
      !isOnline()
    ) {
      setScreen(Screen.LogoutWarningScreen);
      return;
    }
    logOutUser();
  };

  render() {
    return html`
      ${when(
        this.displayMenu,
        () => html`
          <div class="menu-background border-normal">
            <div class="menu-header">
              <div class="menu-avatar-container">
                <img class="menu-avatar" src=${UIImages.Menu_Avatar} />
              </div>
              <div class="menu-username">
                ${appState.user.value?.displayName ||
                this.placeholderProfileName}
              </div>
              <button @click=${this.renderMenuBtn} class="menu-close-btn">
                <img src=${UIImages.Cross_Active} />
              </button>
            </div>
            <div class="menu-content-container">
              ${when(
                appState.scene.value !== Screen.MainMenu,
                () =>
                  html`<div class="sub-menu-btn-container">
                    <app-button
                      @click=${this.onHome}
                      imgURL=${UIImages.Menu_Home}
                    ></app-button>
                    <div class="sub-menu-btn-text">
                      ${translate("HamburgerMenu_Home")}
                    </div>
                  </div>`,
              )}
              <div class="sub-menu-btn-container">
                <app-button
                  @click=${this.toggleMute}
                  imgURL=${appState.isMuted.value
                    ? UIImages.Menu_Unmute
                    : UIImages.Menu_Mute}
                >
                </app-button>
                <div class="sub-menu-btn-text">
                  ${appState.isMuted.value
                    ? "Unmute" // TODO: get from translations
                    : translate("HamburgerMenu_Mute")}
                </div>
              </div>
              <div class="sub-menu-btn-container">
                <app-button
                  @click=${this.onSignOut}
                  imgURL=${UIImages.Menu_Logout}
                ></app-button>
                <div class="sub-menu-btn-text">
                  ${translate("HamburgerMenu_Logout")}
                </div>
              </div>
              ${when(
                window.installPrompt,
                () =>
                  html`<div class="sub-menu-btn-container">
                    <app-button
                      @click=${installApp}
                      imgURL=${UIImages.Menu_Install}
                    ></app-button>
                    <div class="sub-menu-btn-text">
                      ${translate("HamburgerMenu_InstallApp")}
                    </div>
                  </div>`,
              )}
            </div>
          </div>
        `,
        () =>
          html` <app-button
            class="menu-button-events"
            ?disabled=${this.disabled}
            @click=${this.renderMenu}
            imgURL=${UIImages.Menu_Active}
          >
          </app-button>`,
      )}
    `;
  }
}
