import { css, html } from "lit";
import { customElement } from "lit/decorators.js";
import { LitElementI18n } from "../translations/lit-mixin";
import { Screen, setScreen } from "../state/app-state";
import { logOutUser } from "../user";
import { SignalWatcher } from "@lit-labs/preact-signals";
import { usingDevBackend } from "../env";
import { PortalAPIDev } from "../portal/dev";
import { IErrorScreen } from "./error-screen";
import "./error-screen";
import { translate } from "../translations/translations";

@customElement("logout-warning")
export class LogoutWarning extends SignalWatcher(LitElementI18n) {
  static styles = css`
    :host {
      width: 100%;
      height: 100%;
      background: url("assets/tiles/BG_pattern_transparent.png") repeat 0 0;
      background-size: 500px 500px;
      background-color: var(--secondary-violet-highlight);
    }

    #dialog-container {
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);

      display: flex;
      align-items: center;
      justify-content: center;
    }

    #dialog-container > warning-dialog {
      display: block;
      width: 412px;
      height: 228px;
    }
  `;

  toScreen = (screen: Screen) => () => setScreen(screen);
  logOut = () => {
    if (usingDevBackend) {
      PortalAPIDev.signOut();
      return;
    }
    logOutUser();
  };

  render() {
    return html`
      <error-screen
        .details=${{
          title: translate("Warning_Heading"),
          buttons: [
            { text: translate("Warning_Button_Logout"), onClick: this.logOut },
            {
              text: translate("Warning_Button_Cancel"),
              onClick: this.toScreen(Screen.MainMenu),
            },
          ],
          content: [translate("Warning_Body01"), translate("Warning_Body02")],
        } as IErrorScreen}
      ></error-screen>
    `;
  }
}
