import { Signal, signal } from "@lit-labs/preact-signals";
import { IUserData } from "../portal/type";
import { Employee } from "../models/employee";
import { Product } from "../models/product";
import { playMusic, stopMusic } from "../sound/sound-manager";
import { LoginSuccessEvent } from "../analytics/analytics";
import { info } from "../helpers/loggerHelper";

export enum Screen {
  Loading = "Loading",
  LogoutWarningScreen = "Logout Warning Screen",
  LoggedOutScreen = "Logged Out Screen",
  MainMenu = "Main Menu",
  Game = "Game",
  SignIn = "Sign-in Screen",
  ChooseSave = "Choose Save",
  GameOver = "GameOver",
}

export enum ActiveDialog {
  None,
  HireEmployee,
  PayEmployee,
  LaunchProduct,
  OpenProduct,
  TestEvent,
}

export declare type PayEmployeeProps = Employee;
export interface LaunchProductProps {
  x: number;
  y: number;
}
export interface OpenProductProps {
  product: Product;
  x: number;
  y: number;
}
// Todo, add other dialog props here
declare type DialogProps =
  | PayEmployeeProps
  | LaunchProductProps
  | OpenProductProps;

export interface Dialog {
  type: ActiveDialog;
  props?: DialogProps;
}

export interface IAppState {
  user: Signal<IUserData>;
  language: Signal<string>;
  scene: Signal<Screen>;
  dialog: Signal<Dialog>;
  saving: Signal<boolean>;
  tip: Signal<string>;
  preloadedGame: Signal<boolean>;
  preloadedUI: Signal<boolean>;
  isMuted: Signal<boolean>;
}

const user: Signal<IUserData> = signal(null);
const language = signal("");
const scene = signal(Screen.Loading);
const dialog: Signal<Dialog> = signal({ type: ActiveDialog.None });
const saving: Signal<boolean> = signal(false);
const tip = signal("");
const preloadedGame: Signal<boolean> = signal(false);
const preloadedUI: Signal<boolean> = signal(false);
const isMuted: Signal<boolean> = signal(false);

export const appState: IAppState = {
  user,
  language,
  scene,
  dialog,
  saving,
  tip,
  preloadedGame,
  preloadedUI,
  isMuted,
};

export const setUser = (u: IUserData | null) => {
  user.value = u;
  if (u) {
    LoginSuccessEvent(u.userId, u.displayName, u.email);
  }
};
export const setLanguage = (l: string) => (language.value = l);
export const setScreen = (s: Screen) => {
  info(`Setting screen to ${s}`);
  scene.value = s;

  switch (s) {
    case Screen.MainMenu:
      playMusic("MenuMusic");
      stopMusic("GameMusic");
      break;
    case Screen.Game:
      playMusic("GameMusic");
      stopMusic("MenuMusic");
      break;
    default:
      stopMusic("GameMusic");
      stopMusic("MenuMusic");
      break;
  }
};
export const setSaving = (value: boolean) => (saving.value = value);

export const openDialog = (type: ActiveDialog, props?: DialogProps) =>
  (dialog.value = { type, props });
export const closeDialog = () => (dialog.value = { type: ActiveDialog.None });

export const setActiveTipKey = (t: string) => (tip.value = t);

export const onGamePreloadComplete = () => (preloadedGame.value = true);
export const onUIPreloadComplete = () => (preloadedUI.value = true);
export const isPreloaded = () => preloadedGame.value && preloadedUI.value;

export const setIsMuted = (value: boolean) => (isMuted.value = value);
