/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { effect } from "@lit-labs/preact-signals";
import { LitElement } from "lit";
import { state } from "lit/decorators.js";
import { appState } from "../state/app-state";

type Constructor<T = object> = new (...args: any[]) => T;

// Define the interface for the mixin
export declare class I18nMixinInterface {
  protected languageLoaded: () => boolean;
}

export const I18nMixin = <T extends Constructor<LitElement>>(superClass: T) => {
  class I18nMixinClass extends superClass {
    @state()
    private language = "";
    private unsubscribe: () => void;
    protected languageLoaded = () => this.language !== "";

    constructor(...args: any[]) {
      super(args);
      this.unsubscribe = effect(() => {
        this.language = appState.language.value;
      });
    }

    disconnectedCallback() {
      super.disconnectedCallback();
      this.unsubscribe();
    }
  }
  return I18nMixinClass as unknown as Constructor<I18nMixinInterface> & T;
};

export const LitElementI18n = I18nMixin(LitElement);
