import Phaser from "phaser";
import { GameImages } from "../data/images";

const progressBarBG = "loading_circle_BG.png";
const progressBarInner = "loading_circle_fill.png";

export class ProgressBarGameObject extends Phaser.GameObjects.Container {
  private progress: number = 0;
  private barBG: Phaser.GameObjects.Image;
  private barInner: Phaser.GameObjects.Image;

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene);
    this.setPosition(x, y);

    this.barBG = this.scene.add.image(
      0,
      0,
      GameImages.EmoticonAndThoughtBubbleSheet,
      progressBarBG,
    );
    this.add(this.barBG);

    this.barInner = this.scene.add.image(
      0,
      0,
      GameImages.EmoticonAndThoughtBubbleSheet,
      progressBarInner,
    );
    this.add(this.barInner);

    this.update();
  }

  // Set the progress in the range [0,1]
  setProgress = (progress: number) => {
    this.progress = progress;
    this.update();
  };

  update = () => {
    this.barInner
      .setCrop(0, 0, this.barInner.width, this.progress * this.barInner.height)
      .setAngle(180);
  };
}
