import { html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { LitElementI18n } from "../translations/lit-mixin";
import { TipTabKeys, TipsTab } from "../data/tips";
import { playSoundEffect } from "../sound/sound-manager";
import { translate } from "../translations/translations";

@customElement("tips-modal")
export class TipModal extends LitElementI18n {
  static styles = [
    css`
      :host {
        display: block;
      }

      #root {
        width: 85%;
        height: 65%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
      }
    `,
  ];

  @property({ type: String })
  contentKey: TipTabKeys;

  closeModal = () => {
    this.dispatchEvent(
      new Event("close", {
        bubbles: true,
        composed: true,
      }),
    );
    playSoundEffect("PopOut");
  };

  firstUpdated() {
    playSoundEffect("PopIn");
  }

  render() {
    const data = TipsTab[this.contentKey];
    return html`
      <div id="root">
        <h1>${data.title}</h1>
        ${data.body.map((b) => html` <p>${translate(b)}</p> `)}
        <button @click=${this.closeModal}>"Continue"</button>
      </div>
    `;
  }
}
