import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";

@customElement("chip-component")
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ChipComponent extends LitElement {
  static styles = css`
    :host {
      background-color: var(--primary-highlight);
      color: var(--primary-navy);
      border-radius: 5px;
      padding: 5px;

      --icon-padding: 5px;
      --icon-size: 20px;
    }

    #container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    #icon {
      width: var(--icon-size);
      height: var(--icon-size);
      margin-right: var(--icon-padding);
    }
  `;

  render() {
    return html`
      <div id="container">
        <div id="icon">
          <slot name="icon"></slot>
        </div>
        <slot></slot>
      </div>
    `;
  }
}
