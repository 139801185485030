import { css, html } from "lit";
import { customElement } from "lit/decorators.js";
import { LitElementI18n } from "../translations/lit-mixin";
import { Screen, setScreen } from "../state/app-state";
import { logInUser } from "../user";
import { SignalWatcher } from "@lit-labs/preact-signals";
import { PortalAPI } from "../portal/api";
import { usingDevBackend } from "../env";
import { warn } from "../helpers/loggerHelper";
import { IDialogButton } from "./warning-dialog";
import { IErrorScreen } from "./error-screen";
import "./error-screen";
import { translate } from "../translations/translations";

@customElement("logged-out")
export class LoggedOut extends SignalWatcher(LitElementI18n) {
  static styles = css`
    :host {
      width: 100%;
      height: 100%;
    }
  `;

  tryAgain = () => {
    PortalAPI.getUser()
      .then(logInUser)
      .catch(() => {
        warn("failed to log in again");
      });
  };

  render() {
    const buttons: IDialogButton[] = [
      { text: translate("Error_Button_TryAgain"), onClick: this.tryAgain },
    ];
    if (usingDevBackend) {
      buttons.push({
        text: "[DEV] Login",
        onClick: () => setScreen(Screen.SignIn),
      });
    }
    return html`
      <error-screen
        .details=${{
          title: translate("Error_Heading"),
          buttons,
          content: [translate("Error_Body")],
        } as IErrorScreen}
      ></error-screen>
    `;
  }
}
