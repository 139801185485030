import { Howl } from "howler";
import { MusicData, MusicKey, Sound, SoundData, SoundKey } from "../data/sound";

const soundMap: Record<SoundKey, { play(): void }> = {
  ButtonSelect_1: createSoundEffect(SoundData.ButtonSelect_1),
  ButtonSelect_2: createSoundEffect(SoundData.ButtonSelect_2),
  ButtonSelect_3: createSoundEffect(SoundData.ButtonSelect_3),
  Error: createSoundEffect(SoundData.Error),
  EmailNotification: createSoundEffect(SoundData.EmailNotification),
  EmailOpen: createSoundEffect(SoundData.EmailOpen),
  Swipe: createSoundEffect(SoundData.Swipe),
  Success: createSoundEffect(SoundData.Success),
  PopIn: createSoundEffect(SoundData.PopIn),
  PopOut: createSoundEffect(SoundData.PopOut),
  Purchase: createSoundEffect(SoundData.Purchase),
  BoxBounce: createSoundEffect(SoundData.BoxBounce),
  NewProduct: createSoundEffect(SoundData.NewProduct),
  Notification: createSoundEffect(SoundData.Notification),
  Sale: createSoundEffect(SoundData.Sale),
  OrderTaken: createSoundEffect(SoundData.OrderTaken),
  Report: createSoundEffect(SoundData.BoxBounce),
};

const musicMap: Record<MusicKey, { play(): void; stop(): void }> = {
  MenuMusic: createMusicTrack(MusicData.MenuMusic),
  GameMusic: createMusicTrack(MusicData.GameMusic),
};

const setGlobalSoundOn = (soundOn: boolean) => {
  Howler.mute(!soundOn);
};

function createSoundEffect(data: Sound) {
  return {
    howl: createHowl(data),
    play() {
      this.howl.play();
    },
  };
}

const playSoundEffect = (key: SoundKey): void => {
  soundMap[key].play();
};

function createHowl(sound: Sound): Howl {
  return new Howl({
    ...sound,
  });
}

function createMusicTrack(data: Sound) {
  return {
    howl: createHowl(data),
    play() {
      this.howl.play();
    },
    stop() {
      this.howl.stop();
    },
  };
}

const playMusic = (key: MusicKey): void => {
  musicMap[key].play();
};
const stopMusic = (key: MusicKey): void => {
  musicMap[key].stop();
};

export { playSoundEffect, playMusic, stopMusic, setGlobalSoundOn };
