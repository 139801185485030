import { v4 as uuidv4 } from "uuid";
import { Vector2 } from "./vector2";
import { info } from "../helpers/loggerHelper";
import { ProductGroup, ProductKind } from "../data/products";

export enum CustomerState {
  Browsing = "browsing",
  WalkingToTill = "walking_to_till",
  WaitingAtTill = "waiting_at_till",
  WalkingAway = "walking_away",
}

export enum WalkAwayReason {
  ProductNotAvailable = "product_not_available",
  TillNotAvailable = "till_not_available",
  ProductTooExpensive = "product_too_expensive",
  ProductPurchased = "product_purchased",
  Exception = "exception",
}

export interface INeed {
  kind: ProductKind;
  purchasePrice: number;
}

// Customer contains the state to represent a customer in the game
// and functionality to update it's state.
export class Customer {
  readonly id: string;
  readonly need: INeed;
  private state: CustomerState;
  private walkAwayReason: WalkAwayReason;
  private till: string;
  private x: number;
  private y: number;
  private yPath: number;
  speed: number;

  constructor(
    x: number,
    y: number,
    yPath: number,
    opts?: {
      id?: string;
      need?: INeed;
      state?: CustomerState;
      walkAwayReason?: WalkAwayReason;
      till?: string;
      speed?: number;
    },
  ) {
    this.id = opts?.id ?? uuidv4();
    this.speed = opts?.speed ?? 100;
    this.need = opts?.need ?? {
      kind: new ProductKind(ProductGroup.Clothing, 0), // TODO: No fallback, customer should be created with a need.
      purchasePrice: 10,
    };
    this.state = opts?.state ?? CustomerState.Browsing;
    this.walkAwayReason = opts?.walkAwayReason ?? undefined;
    this.till = opts?.till ?? "";
    this.x = x;
    this.y = y;

    this.yPath = yPath;
  }

  toJSON = () => ({
    id: this.id,
    needKind: this.need.kind,
    needPurchasePrice: this.need.purchasePrice,
    state: this.state,
    walkAwayReason: this.walkAwayReason,
    till: this.till,
    x: this.x,
    y: this.y,
    speed: this.speed,
    yPath: this.yPath,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJSON = (obj: any): Customer =>
    new Customer(obj.x, obj.y, obj.yPath, {
      id: obj.id,
      need: {
        kind: new ProductKind(obj.needKind.group, obj.needKind.index),
        purchasePrice: obj.needPurchasePrice,
      },
      state: obj.state,
      walkAwayReason: obj.walkAwayReason,
      till: obj.till,
      speed: obj.speed,
    });

  walkToTill = (till: string): boolean => {
    if (
      this.state !== CustomerState.Browsing &&
      this.state !== CustomerState.WalkingToTill
    ) {
      return false;
    }
    this.state = CustomerState.WalkingToTill;
    this.till = till;
    return true;
  };

  waitAtTill = (): boolean => {
    if (
      this.state !== CustomerState.WalkingToTill &&
      this.state !== CustomerState.WaitingAtTill
    ) {
      info("Only a customer that walked to a till can start waiting");
      return false;
    }
    if (!this.till) {
      info("Customer doesn't have a till set yet");
      return false;
    }
    info(`Customer [${this.id}] waiting at Till [${this.till}]`);
    this.state = CustomerState.WaitingAtTill;
    return true;
  };

  walkAway = (reason: WalkAwayReason) => {
    info(`Customer [${this.id}] is walking away [${reason}]`);
    this.state = CustomerState.WalkingAway;
    this.walkAwayReason = reason;
    this.till = "";
  };

  move(target: Vector2, delta: number): boolean {
    const pos = new Vector2(this.getX(), this.getY());
    const offset = target.subtract(pos);
    const moveDistance = this.speed * (delta / 1000);
    if (offset.length() <= moveDistance) {
      this.setPosition(target.x, target.y);
      return true;
    }
    const move = offset.normalize().scale(moveDistance);
    const newPosition = pos.add(move);
    this.setPosition(newPosition.x, newPosition.y);
    return false;
  }

  moveTo(target: Vector2, delta: number, moveXFirst: boolean): boolean {
    let x: boolean = target.x === this.getX();
    let y: boolean = target.y === this.getY();

    if (moveXFirst) {
      if (!x) {
        x = this.move(new Vector2(target.x, this.getY()), delta);
      } else {
        y = this.move(new Vector2(this.getX(), target.y), delta);
      }
    } else {
      if (!y) {
        y = this.move(new Vector2(this.getX(), target.y), delta);
      } else {
        x = this.move(new Vector2(target.x, this.getY()), delta);
      }
    }
    return x && y;
  }

  // Setters
  setPosition = (x: number, y: number) => {
    this.x = x;
    this.y = y;
  };
  // Getters
  getX = () => this.x;
  getY = () => this.y;
  getYPath = () => this.yPath;
  getState = (): CustomerState => this.state;
  getWalkAwayReason = (): WalkAwayReason => this.walkAwayReason;
  getTill = (): string => this.till;
}
