import { info, warn } from "../helpers/loggerHelper";
import { Till } from "../models/till";
import { GameModel } from "../models/gameModel";
import { IGameView } from "../views/gameView";
import { mapData } from "./mapData";
import { ProductGroup, ProductKind } from "../data/products";
import { Subcontroller } from "./subcontroller";
import {
  IGameplayParams,
  IProductCategoryParams,
} from "../config/gameplayParameters";
import { maxEmployeesPerLevel, maxProductsPerLevel } from "../data/shopData";
import { WalkAwayReason } from "../models/customer";
import { ModelReducer } from "../models/modelReducer";
import { Shop } from "../models/shop";
import { Product } from "../models/product";
import { playSoundEffect } from "../sound/sound-manager";

// ShopController is responsible for any game logic related to the shop
// and updating the game object to reflect the current state of the shop.
export class ShopController extends Subcontroller {
  private marketingCost: number;
  private marketingCostPerLevel: number;
  private maxDemand: number;
  private productParams: IProductCategoryParams[];

  constructor(
    model: GameModel,
    view: IGameView,
    gameplayParams: IGameplayParams,
  ) {
    super(model, view);
    this.productParams = gameplayParams.products;
  }

  updateParams = (gameplayParams: IGameplayParams) => {
    if (gameplayParams.products !== this.productParams) {
      this.productParams = gameplayParams.products;
    }
    if (gameplayParams.marketing.cost !== this.marketingCost) {
      this.marketingCost = gameplayParams.marketing.cost;
    }
    if (
      gameplayParams.marketing.increasePerLevel !== this.marketingCostPerLevel
    ) {
      this.marketingCostPerLevel = gameplayParams.marketing.increasePerLevel;
    }
    if (gameplayParams.marketing.maxDemand !== this.maxDemand) {
      this.maxDemand = gameplayParams.marketing.maxDemand;
    }
  };

  startGame = (newGame: boolean) => {
    if (newGame) {
      this.view.updateShop(this.model.getShop());
    }
  };

  tick = () => {
    // Rather than utilizing signals for the game pips, we can just compare gold values every frame.
    this.view.shop.displayAddProductAlert(
      this.model.getShop().nextProductCost > 0 &&
        this.model.getShop().nextProductCost <= this.model.getGold(),
    );
    this.view.shop.displayHireEmployeeAlert(
      this.model.getEmployeeHiringCost() > 0 &&
        this.model.getEmployeeHiringCost() <= this.model.getGold(),
    );
    this.view.shop.updateProductDemandPips(
      this.model.getShop().getProducts(),
      this.model.getGold(),
      this.marketingCost,
      this.marketingCostPerLevel,
      this.maxDemand,
    );
  };

  levelUp = (): boolean => {
    const shop = this.model.getShop();

    const maxEmployees = maxEmployeesPerLevel[shop.getLevel()];
    const maxProduct = maxProductsPerLevel[shop.getLevel()];

    let hasLevelIncreased = false;

    if (
      shop.getTills().length > maxEmployees ||
      shop.getProducts().length > maxProduct
    ) {
      hasLevelIncreased = shop.levelUp();
      playSoundEffect("Success");
    }

    if (!hasLevelIncreased) {
      return hasLevelIncreased;
    }
    ModelReducer.onModelUpdate(this.model);
    this.view.updateShop(shop);
    return hasLevelIncreased;
  };

  setBusinessType = (businessType: ProductGroup) => {
    this.model.getShop().setBusinessType(businessType);
  };

  // Adds a till to the shop. The return value indicates if a till could be added.
  addTill = (): string | undefined => {
    const shop = this.model.getShop();
    const tillIndex = this.model.getShop().getTills().length;
    const tillPos = mapData.tillPosition[tillIndex];
    const till = shop.addTill(tillPos.x, tillPos.y);
    this.view.updateShop(shop);
    return till.id;
  };

  // This is to create the first customer timer
  createFirstProduct = (): Product => {
    const shop = this.model.getShop();
    const productGroup = shop.getBusinessType();
    const params = this.productParams.find(
      (group) => group.category === productGroup,
    );
    const { cost, maxCost, stockLimit } = params.products[0];
    this.updateNextShopCost(shop);
    return new Product(
      new ProductKind(productGroup, 0),
      cost,
      maxCost,
      shop.nextProductPosition(),
      shop.nextProductSize(),
      stockLimit,
    );
  };

  launchProduct = () => {
    const shop = this.model.getShop();
    const index = shop.getNextProductIndex();
    if (index <= -1) return;

    const productGroup = shop.getBusinessType();
    const params = this.productParams.find(
      (group) => group.category === productGroup,
    );
    const { cost, maxCost, stockLimit } = params.products[index];
    if (!this.model.canPay(cost * stockLimit)) return;
    const added = shop.addProduct(
      new ProductKind(productGroup, index),
      cost,
      maxCost,
      stockLimit,
    );
    if (added) {
      this.updateNextShopCost(shop);
      this.model.buyStock(added.cost * added.stockLimit, added.kind);
      ModelReducer.onModelUpdate(this.model);
      this.view.updateShop(shop);
      playSoundEffect("NewProduct");
    }

    return added;
  };

  private updateNextShopCost(shop: Shop) {
    const index = shop.getNextProductIndex();
    if (index < 0) {
      shop.nextProductCost = undefined;
      return;
    }
    const { cost, stockLimit } = this.productParams.find(
      (group) => group.category === shop.getBusinessType(),
    ).products[index];
    shop.nextProductCost = cost * stockLimit;
  }

  orderItem = (
    customerID: string,
    product: ProductKind,
    tillID: string,
  ): boolean | WalkAwayReason => {
    if (!this.model.getShop()?.hasProduct(product)) {
      info("Shop doesn't stock the product");
      return WalkAwayReason.ProductNotAvailable;
    }

    const till = this.model.getShop()?.getTill(tillID);
    if (!till) {
      info("Can't claim till, it does not exist");
      return false;
    }
    if (till.claimedBy() !== customerID) {
      info("Till not claimed by current customer");
      return false;
    }

    const employee = this.model.employee(till.mannedBy());
    if (!employee) {
      // This should not happen
      warn("Can't claim till, couldn't find employee");
      return false;
    }

    if (!employee.takeOrder(product)) {
      if (employee.idle) {
        return WalkAwayReason.TillNotAvailable;
      }
      // This should not happen
      warn("Can't claim till, employee failed to take order");
      return false;
    } else {
      this.model.getShop().product(product).stock.value--;
      playSoundEffect("OrderTaken");
    }
    return till.claim(customerID);
  };

  availableTills = (): Till[] => this.model.getShop().availableTills();

  withinRange = (x: number): boolean => {
    const shop = this.model.getShop();
    return !!shop?.getTills().find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (t: any) =>
        x >= t.x - shop.attractionRange && x <= t.x + shop.attractionRange,
    );
  };
}
