import Phaser from "phaser";
import { GameObjectDepth } from "../data/game-objects";
import { GameImages, TillKeys } from "../data/images";

export class TillGameObject extends Phaser.GameObjects.Container {
  constructor(scene: Phaser.Scene) {
    super(scene);
    const image = this.scene.add.image(
      0,
      0,
      GameImages.TillSheet,
      TillKeys.EmployeeTill,
    );
    this.add(image);
    this.setDepth(GameObjectDepth.Till);
  }
}
