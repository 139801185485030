import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import "./chip-component";
import "./loading-spinner";

@customElement("saving-indicator")
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class SavingIndicator extends LitElement {
  static styles = css`
    :host {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: var(--z-index-save);

      display: flex;
      flex-direction: row;
      place-content: center;
    }

    chip-component {
      margin-top: 50px;
    }
  `;

  render() {
    return html`
      <chip-component>
        <loading-spinner slot="icon"></loading-spinner>
        Saving
      </chip-component>
    `;
  }
}
