import { v4 as uuidv4 } from "uuid";
import { Vector2 } from "./vector2";

export class Till {
  readonly id: string;
  // The customer that claimed the till
  private customerId: string;
  // The employee that is manning the till
  private employeeId: string;
  readonly x: number;
  readonly y: number;

  constructor(
    x: number,
    y: number,
    opts?: {
      id?: string;
      claimedBy?: string;
      mannedBy?: string;
    },
  ) {
    this.id = opts?.id ?? uuidv4();
    this.customerId = opts?.claimedBy;
    this.employeeId = opts?.mannedBy;
    this.x = x;
    this.y = y;
  }

  toJSON = () => ({
    id: this.id,
    customer: this.customerId,
    employee: this.employeeId,
    x: this.x,
    y: this.y,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJSON = (obj: any): Till =>
    new Till(obj.x, obj.y, {
      id: obj.id,
      claimedBy: obj.customer,
      mannedBy: obj.employee,
    });

  position = () => new Vector2(this.x, this.y);

  claim = (by: string): boolean => {
    if (this.customerId !== undefined && this.customerId !== by) {
      return false;
    }
    this.customerId = by;
    return true;
  };
  releaseClaim = () => {
    this.customerId = undefined;
  };
  claimedBy = () => this.customerId;
  isAvailable = (): boolean =>
    this.claimedBy() === undefined && this.isManned();

  // Each employee object is assigned a till when instantiated and is responsible for moving between their till and counter.
  // We should only ever re-assign the employeeId / man the till when it is undefined - when the employee is idled.
  // Ideally this should be reworked into an idled state as opposed to setting the employeeId to undefined
  man = (employeeID: string): boolean => {
    if (this.employeeId !== undefined) return false;
    this.employeeId = employeeID;
    return true;
  };
  unman = () => {
    this.employeeId = undefined;
  };
  mannedBy = () => this.employeeId;
  isManned = (): boolean => this.mannedBy() !== undefined;
}
