// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { info } from "../helpers/loggerHelper";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore/lite";

import {
  browserLocalPersistence,
  getAuth,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  User,
} from "firebase/auth";
import { IGameSave, IPortalAPI, IUserData } from "./type";
import { isDevEnv } from "../env";
import { logInUser, logOutUser } from "../user";
import { Screen, setScreen } from "../state/app-state";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCuNvDPPLmHvznuN-SwxydlHREM77nvcy4",
  authDomain: "agec-dev.firebaseapp.com",
  projectId: "agec-dev",
  storageBucket: "agec-dev.appspot.com",
  messagingSenderId: "1021484876391",
  appId: "1:1021484876391:web:a93d569016aaf6c9898ac2",
  measurementId: "G-XDC7ZYS5P9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

interface ISaveDoc {
  score: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  completed: boolean;
}

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export class PortalAPIDev implements IPortalAPI {
  private userID: string;
  private userData: IUserData | null;
  private authChanged: boolean = false;

  firebaseUserToIUserData = (user: User): IUserData | null => {
    if (!user) {
      return null;
    }
    return { displayName: user.email, userId: user.uid, email: user.email };
  };

  constructor() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      this.userID = user?.email || undefined;
      this.userData = this.firebaseUserToIUserData(user);
      this.authChanged = true;
      if (this.userData) {
        logInUser(this.userData);
      } else {
        logOutUser();
        setScreen(Screen.LoggedOutScreen);
      }
    });
  }

  static signIn = async (email: string, password: string): Promise<void> => {
    if (!isDevEnv) {
      return;
    }
    const auth = getAuth();
    setPersistence(auth, browserLocalPersistence);
    await signInWithEmailAndPassword(auth, email, password);
  };

  static signOut = async () => {
    if (!isDevEnv) {
      return;
    }
    await signOut(getAuth());
  };

  getUser = async (): Promise<IUserData> => {
    // Wait to return a value until the firebase
    // authentication state has changed at least once.
    while (!this.authChanged) {
      await sleep(100);
    }

    if (!this.userData) {
      throw new Error("No user data available");
    }
    return this.userData;
  };

  private saveDocRef = () => {
    if (!this.userID) {
      return null;
    }
    return doc(db, "saves", this.userID);
  };

  // Download game data from the backend
  loadGame = async (): Promise<IGameSave> => {
    if (!this.getUser()) {
      throw new Error("user not signed in");
    }

    info(`[DEV API] loadGameData`);
    const ref = this.saveDocRef();
    const saveDoc = await getDoc(ref);
    const doc = (saveDoc.data() as ISaveDoc) || null;
    if (doc == null) {
      throw new Error("failed to fetch document from firestore");
    }
    return { ...doc };
  };

  // Upload game data to the backend
  saveGame = async (game: IGameSave): Promise<void> => {
    if (!this.getUser()) {
      throw new Error("user not signed in");
    }

    const dataString = JSON.stringify(game.data, null, 2);
    info(`[DEV API] saveGameData:\n${dataString}`);
    const ref = this.saveDocRef();
    const gameDoc: ISaveDoc = { ...game };
    try {
      await setDoc(ref, gameDoc);
    } catch {
      throw new Error("failed to update firestore document");
    }
  };
}
