import { css, html } from "lit";
import { customElement } from "lit/decorators.js";
import { buttonStyle } from "../styles/theme";
import { LitElementI18n } from "../translations/lit-mixin";
import { SignalWatcher } from "@lit-labs/preact-signals";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { UIWatchableModel } from "../models/gameModel";
import { FinancialReportModel } from "../models/financialReportModel";

@customElement("business-value")
export class BusinessValue extends SignalWatcher(LitElementI18n) {
  static styles = [
    buttonStyle,
    css`
      :host {
        width: 100%;
        height: 100%;
        background-color: white;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .container {
        position: relative;

        width: 100%;
        max-width: 1920px;
        height: 100%;
        max-height: 1080px;

        display: flex;
        justify-content: space-evenly;
      }

      .column {
        flex: 1;
        padding: 5px;
      }
    `,
  ];

  @consume({ context: gameContext })
  game: GameController;

  uiModel: UIWatchableModel;
  financialReportModel: FinancialReportModel;

  connectedCallback(): void {
    super.connectedCallback();
    this.uiModel = this.game.getModel().getUIWatchableModel();
    this.financialReportModel = this.game.getModel().getFinancialReportModel();
  }

  render() {
    return html`
      <div class="container">
        <div class="column">
          The player with the highest Business Value after 12 paydays wins!
        </div>
        <div class="column">
          <p>ⓘ Cash: ${this.uiModel.gold}</p>
          <p>ⓘ Stock: ${this.financialReportModel.stockValue}</p>
          <p>ⓘ Achievements: ${this.financialReportModel.achievementsValue}</p>
          <h1>Business Value= ${this.uiModel.businessValue}</h1>
        </div>
        <div class="column">
          The player with the highest Business Value after 12 paydays wins!
        </div>
      </div>
    `;
  }
}
