import { css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { LitElementI18n } from "../translations/lit-mixin";
import { TipTabKeys } from "../data/tips";
import { setActiveTipKey } from "../state/app-state";

@customElement("tip-button")
export class TipButton extends LitElementI18n {
  static styles = css`
    :host {
      width: 100%;
      height: 100%;
    }

    .tip-button {
      position: absolute;
      z-index: var(--info-modal-z);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `;

  @property()
  tipModalKey: TipTabKeys;

  onTipButtonClicked = () => {
    setActiveTipKey(this.tipModalKey);
  };

  render = () => html`
    <button
      class="tip-button"
      @click=${this.onTipButtonClicked}
      contentKey=${this.tipModalKey}
    >
      ⓘ
    </button>
  `;
}
