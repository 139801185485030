import { signal } from "@lit-labs/preact-signals";

export const online = signal(navigator.onLine);

export function isOnline() {
  return online.value;
}

window.addEventListener("online", () => {
  online.value = true;
});

window.addEventListener("offline", () => {
  online.value = false;
});
