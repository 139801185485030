import Phaser from "phaser";
import { TillGameObject } from "./till";
import { ProductGameObject } from "./product";
import { Till } from "../models/till";
import { Product } from "../models/product";
import { AddProductButton } from "./add-product-btn";
import { Vector2 } from "../models/vector2";
import { GameObjectDepth } from "../data/game-objects";
import { gameConfig } from "../config/gameConfig";
import { ActiveDialog, openDialog } from "../state/app-state";
import { HireEmployeeButton } from "./hire-employee-btn";

export class ShopGameObject extends Phaser.GameObjects.Container {
  scene: Phaser.Scene;
  tills: { [id: string]: TillGameObject };
  products: { [kind: string]: ProductGameObject };
  addProduct: AddProductButton;
  hireEmployee: HireEmployeeButton;

  constructor(
    scene: Phaser.Scene,
    level: number,
    nextLaunchProductPosition: Vector2 | undefined,
    nextHireEmployeePosition: Vector2 | undefined,
  ) {
    super(scene);
    this.scene = scene;
    this.tills = {};
    this.products = {};

    const img = this.scene.add.image(0, 0, `shop_level${level}`);
    img.setOrigin(0, 0);
    img.setDepth(GameObjectDepth.Background);
    img
      .setDisplaySize(gameConfig.backgroundWidth, gameConfig.backgroundHeight)
      .setPosition(0, 0);

    // only create the button if the shop can have another product
    if (nextLaunchProductPosition) {
      this.createAddProductButton(nextLaunchProductPosition);
    }

    if (nextHireEmployeePosition) {
      this.createHireEmployeeButton(nextHireEmployeePosition);
    }
  }

  destroy = () => {
    Object.keys(this.tills).forEach((k) => {
      this.tills[k].destroy();
    });
    Object.values(this.products).forEach((p) => p.destroy(true));
    this.addProduct?.destroy(true);
    this.hireEmployee?.destroy(true);
  };

  till = (id: string): TillGameObject => {
    return this.tills[id];
  };

  createTill = (till: Till) => {
    const t = new TillGameObject(this.scene);
    t.setPosition(till.x, till.y);
    this.scene.add.existing(t);
    this.tills[till.id] = t;
  };

  createProduct = (p: Product) => {
    const pObj = new ProductGameObject(this.scene, p);
    pObj.setPosition(p.position.x, p.position.y);
    this.scene.add.existing(pObj);
    this.products[p.kind.key()] = pObj;
    pObj.on("click", (x: number, y: number) => {
      const product = p;
      openDialog(ActiveDialog.OpenProduct, {
        product,
        x: pObj.x + x,
        y: pObj.y + y,
      });
    });
  };

  createAddProductButton = (pos: Vector2) => {
    this.addProduct?.destroy();
    this.addProduct = new AddProductButton(this.scene);
    this.addProduct.setPosition(pos.x, pos.y);
    this.addProduct.on("click", (x: number, y: number) => {
      openDialog(ActiveDialog.LaunchProduct, {
        x: this.addProduct.x + x,
        y: this.addProduct.y + y,
      });
    });
    this.scene.add.existing(this.addProduct);
  };

  createHireEmployeeButton = (pos: Vector2) => {
    this.hireEmployee?.destroy();
    this.hireEmployee = new HireEmployeeButton(this.scene);
    this.hireEmployee.setPosition(pos.x, pos.y);
    this.hireEmployee.on("click", (x: number, y: number) => {
      openDialog(ActiveDialog.HireEmployee, {
        x: this.hireEmployee.x + x,
        y: this.hireEmployee.y + y,
      });
    });
    this.scene.add.existing(this.hireEmployee);
  };

  displayAddProductAlert = (enabled: boolean) => {
    this.addProduct?.setUpgradePipVisibility(enabled);
  };

  displayHireEmployeeAlert = (enabled: boolean) => {
    this.hireEmployee?.setUpgradePipVisibility(enabled);
  };

  updateProductDemandPips = (
    products: Product[],
    gold: number,
    initialCost: number,
    costPerLevel: number,
    maxDemand: number,
  ) => {
    products.forEach((p) => {
      this.products[p.kind.key()]?.setDemandPipVisibility(
        p.getMarketingCost(initialCost, costPerLevel) <= gold &&
          p.demand < maxDemand,
      );
    });
  };
}
