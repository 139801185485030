import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { borderStyles } from "../styles/theme";
import { playSoundEffect } from "../sound/sound-manager";
import { classMap } from "lit/directives/class-map.js";

@customElement("app-button")
export class AppButton extends LitElement {
  static styles = [
    borderStyles,
    css`
      button {
        background: var(--primary-teal-main);
        padding: 8px 10px 12px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: center;
        align-items: center;

        border-radius: 6px;
        box-sizing: border-box;
        box-shadow: 0px -4px 0px 0px var(--primary-lowlight) inset;
        outline: none;
        -webkit-tap-highlight-color: transparent;

        font-family: Ubuntu;
        font-size: 20px;
        font-weight: 700;
        line-height: normal;
        text-align: left;
        color: #16006d;
        margin: 0px;
      }

      button.minimal-padding {
        padding: 0px 4px 0px;
      }

      button:active {
        background: var(--primary-lowlight);
        box-shadow: none;
      }

      button:disabled {
        background: var(--button-disabled-primary);
        border: 2px solid var(--button-disabled-lowlight);
        box-shadow: 0px -4px 0px 0px var(--button-disabled-lowlight) inset;
      }

      button > * {
        flex-shrink: 1;
      }
    `,
  ];

  @property({ type: Boolean })
  disabled: boolean;
  @property({ type: Boolean, attribute: "minimal-padding" })
  minimalPadding = false;
  @property({ type: String })
  text?: string;
  @property({ type: String })
  imgURL?: string;

  onClick = () => {
    playSoundEffect("ButtonSelect_3");
  };

  render() {
    return html`
      <button
        ?disabled=${this.disabled}
        @click=${this.onClick}
        class=${classMap({
          "border-normal": true,
          "minimal-padding": this.minimalPadding,
        })}
      >
        ${when(this.imgURL, () => html`<img src=${this.imgURL} />`)}
        ${when(this.text, () => html`<div id="text">${this.text}</div>`)}
        <slot></slot>
      </button>
    `;
  }
}
