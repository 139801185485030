/* eslint-disable @typescript-eslint/no-explicit-any */
import { Signal, signal } from "@lit-labs/preact-signals";
import { IGameSave } from "../portal/type";
import { PortalAPI } from "../portal/api";
import { warn } from "../helpers/loggerHelper";

export enum RequestState {
  None = "none",
  Loading = "loading",
  Successful = "successful",
  Failed = "failed",
}

export interface ISaveState {
  local: Signal<IGameSave | null>;
  cloud: Signal<IGameSave | null>;
  cloudRequestState: Signal<RequestState>;
}

export const saveState: ISaveState = {
  local: signal(null),
  cloud: signal(null),
  cloudRequestState: signal(RequestState.None),
};

// <===== SAVE MANAGER =====>

const SAVE_KEY = "game_save";

const getDate = (data: any) =>
  data?.timestamp ? new Date(Date.parse(data.timestamp)) : null;

export class SaveManager {
  save = async (data: any) => {
    data.timestamp = new Date(Date.now()).toISOString();
    localStorage.setItem(SAVE_KEY, JSON.stringify(data));
    this.refreshLocalSave();
    try {
      const game: IGameSave = {
        score: 0, // TODO: get actual value
        completed: false, // TODO: get actual value
        data,
      };
      await PortalAPI.saveGame(game);
      // Just set the state instead of doing a refresh,
      // which would need to make a call to the backend.
      saveState.cloud.value = game;
      saveState.cloudRequestState.value = RequestState.Successful;
    } catch (e) {
      warn(`Failed to save game in the cloud: ${e.toString()}`);
    } finally {
      this.refreshCloudSave();
    }
  };

  cloudSave = (): IGameSave => saveState.cloud.value;
  localSave = (): IGameSave => saveState.local.value;

  localSaveTimeStamp = (): Date | null => getDate(this.localSave()?.data);
  refreshLocalSave = async (): Promise<void> => {
    const data = localStorage.getItem(SAVE_KEY);
    const saveData = data ? JSON.parse(data) : null;
    saveState.local.value =
      saveData === null
        ? null
        : ({
            data: saveData,
            score: 0, // TODO: Store this info locally
            completed: false, // TODO: Store this info locally
          } as IGameSave);
  };

  cloudSaveRequestState = () => saveState.cloudRequestState.value;
  cloudSaveTimeStamp = (): Date | null => getDate(this.cloudSave()?.data);
  refreshCloudSave = async (): Promise<void> => {
    // Don't clear the current game data
    saveState.cloudRequestState.value = RequestState.Loading;

    try {
      saveState.cloud.value = await PortalAPI.loadGame();
      saveState.cloudRequestState.value = RequestState.Successful;
    } catch (e) {
      warn(`Failed to load the cloud save: ${e.toString()}`);
      saveState.cloud.value = null;
      saveState.cloudRequestState.value = RequestState.Failed;
    }
  };
}

export const saveManager = new SaveManager();
