import Phaser from "phaser";
import { GameObjectDepth } from "../data/game-objects";
import { ProductGroup, ProductKind } from "../data/products";
import { GameImages, TillKeys } from "../data/images";
import { Product } from "../models/product";
import { effect } from "@lit-labs/preact-signals";

interface ProductSpriteInfo {
  spriteKey: string;
  frame: string;
}

const countertopPx = 46;
const baseAndShadowPx = 18;

const getProductSpriteInfo = (p: ProductKind): ProductSpriteInfo => {
  let spriteSheet: string;

  switch (p.group) {
    case ProductGroup.Clothing:
      spriteSheet = GameImages.ClothingSheet;
      break;
    case ProductGroup.CoffeeShop:
      spriteSheet = GameImages.CoffeeShopSheet;
      break;
    case ProductGroup.Stationary:
      spriteSheet = GameImages.StationarySheet;
      break;
    case ProductGroup.Technology:
      spriteSheet = GameImages.TechnologySheet;
      break;
    case ProductGroup.Toiletries:
      spriteSheet = GameImages.ToiletriesSheet;
      break;
  }

  return { spriteKey: spriteSheet, frame: `${p.group}_${p.index}.png` };
};

export class ProductGameObject extends Phaser.GameObjects.Container {
  private till: Phaser.GameObjects.Image;
  private demandPip: Phaser.GameObjects.Image;
  private unsubscribeFromSignal: () => void;

  constructor(scene: Phaser.Scene, product: Product) {
    super(scene);

    this.till = this.scene.add.image(
      0,
      0,
      GameImages.TillSheet,
      TillKeys.ProductTill,
    );
    this.add(this.till);

    const { spriteKey, frame } = getProductSpriteInfo(product.kind);
    // The product needs to be placed in the centre of the product counter,
    // not the image itself.
    const productY =
      (countertopPx + baseAndShadowPx) / 2 -
      (baseAndShadowPx + countertopPx / 2);
    const image = this.scene.add.image(0, productY, spriteKey, frame);
    this.add(image);
    this.setDepth(GameObjectDepth.Product);

    const stockPill = this.scene.add.image(
      this.till.getBottomCenter().x,
      this.till.getBottomCenter().y,
      GameImages.TillSheet,
      TillKeys.ProductCountPill,
    );
    stockPill.setOrigin(0.5, 1);
    this.add(stockPill);

    const stockCounter = this.scene.add
      .text(
        stockPill.getCenter().x,
        stockPill.getCenter().y,
        `${product.stock.value}`,
        {
          color: "#16006D",
        },
      )
      .setOrigin(0.5);
    this.add(stockCounter);

    const stockPip = this.scene.add.image(
      stockPill.getTopRight().x,
      stockPill.getTopRight().y,
      GameImages.StockAndPayAlert,
    );
    this.add(stockPip);

    this.demandPip = this.scene.add.image(
      image.getTopRight().x,
      image.getTopRight().y,
      GameImages.DemandAndUpgradeAlert,
    );
    this.add(this.demandPip);

    this.unsubscribeFromSignal = effect(() => {
      stockCounter.text = `${product.stock.value}`;
      stockPip.setVisible(product.stock.value === 0);
    });

    // setup click event
    this.till.setInteractive({
      useHandCursor: true,
    });
    this.scene.input.on(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown);
  }

  public setDemandPipVisibility = (visible: boolean) => {
    if (this.demandPip.visible !== visible) {
      this.demandPip.setVisible(visible);
    }
  };

  private onPointerDown = (
    pointer: Phaser.Input.Pointer,
    currentlyOver: Phaser.GameObjects.GameObject[],
  ) => {
    if (currentlyOver.includes(this.till)) {
      this.emit("click", pointer.worldX, pointer.worldY);
    }
  };

  destroy(fromScene?: boolean): void {
    this.unsubscribeFromSignal();
    this.scene.input.off(Phaser.Input.Events.POINTER_DOWN, this.onPointerDown);
    super.destroy(fromScene);
  }
}
