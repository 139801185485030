import { css, html, nothing } from "lit";
import { customElement, state } from "lit/decorators.js";
import "./intro-flow";
import "./hire-dialog";
import "./add-product-dialog";
import "./event-modal";
import "./pay-employee-dialog";
import "./open-product-dialog";
import "./level-up";
import "./payday-tracker";
import "./business-value-tracker";
import {
  ActiveDialog,
  Screen,
  appState,
  closeDialog,
  openDialog,
  setIsMuted,
  setScreen,
} from "../state/app-state";
import { translate } from "../translations/translations";
import { LitElementI18n } from "../translations/lit-mixin";
import { consume } from "@lit/context";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { when } from "lit/directives/when.js";
import { TipTabKeys } from "../data/tips";
import { SignalWatcher, effect } from "@lit-labs/preact-signals";
import { UIWatchableModel } from "../models/gameModel";
import { gameState, togglePause, isIntroComplete } from "../state/game-state";
import { playMusic, setGlobalSoundOn } from "../sound/sound-manager";
import { stopMusic } from "../sound/sound-manager";
import { saveManager } from "../state/save-state";
import { UIImages } from "../data/images";

@customElement("game-ui")
export class GameUI extends SignalWatcher(LitElementI18n) {
  static styles = css`
    :host {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      /* This disables the element from processing mouse
      events so the phaser canvas can receive events. */
      pointer-events: none;

      --level-up-z: 100;
      --intro-flow-z: 20;
      --info-modal-z: 10;
      --event-modal-z: 10;
      --game-dialog-z: 5;
    }

    :host > * {
      pointer-events: auto;
    }

    intro-flow {
      position: absolute;
      z-index: var(--intro-flow-z);
      width: 100%;
      height: 100%;
    }
    tips-modal {
      position: absolute;
      z-index: var(--info-modal-z);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    event-modal {
      position: absolute;
      z-index: var(--event-modal-z);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .game-dialog {
      position: absolute;
      z-index: var(--game-dialog-z);
      width: 100%;
      height: 100%;
    }
    #gamebar {
      width: 100%;
      height: 25px;
      background-color: #222222;
      color: white;
      display: flex;
      flex-direction: row;
    }
    #gold {
      height: 100%;
      display: flex;
      align-content: center;
    }
    #gold img {
      height: 100%;
      aspect-ratio: 1;
    }
    #business-value {
      height: 100%;
      display: flex;
      align-content: center;
    }
    #business-value img {
      height: 100%;
      aspect-ratio: 1;
    }
    payday-timer {
      width: 250px;
    }
    #buttonbar {
      width: 100%;
      height: 50px;

      display: flex;
      align-content: start;

      position: absolute;
      bottom: 0px;
    }
    button {
      background-color: black;
      color: white;
      height: 100%;

      cursor: pointer;
    }

    #buttonbar button {
      flex-grow: 1;
    }

    level-up {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: var(--level-up-z);
      pointer-events: none !important;
    }

    .shortfall-ui {
      display: flex;
      width: fit-content;
      height: 24px;
      border-radius: 4px;
      background-color: var(--ginger);
      gap: 4px;
      box-shadow: 0px -2px 0px 0px var(--ginger-lowlight) inset;
      align-content: center;
      justify-content: end;
      flex-wrap: wrap;
    }

    .shortfall-ui-text {
      margin-right: 4px;
      color: var(--primary-navy);
      text-align: center;
      font-family: Ubuntu;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .shortfall-ui-img {
      margin-left: 4px;
      width: 16px;
      height: 16px;
    }

    .shortfall-wrapper-ui {
      display: flex;
      position: absolute;
      right: 137px;
      top: 10px;
      flex-direction: column;
      gap: 6px;
      align-items: flex-end;
    }

    payday-tracker {
      position: absolute;
      top: 10px;
      right: 10px;

      width: 119px;
      height: 74px;

      @media (min-width: 1000px) {
        right: 12px;
      }
    }

    business-value-tracker {
      position: absolute;
      top: 92px;
      right: 10px;

      width: 119px;
      height: 50px;

      @media (min-width: 1000px) {
        right: 12px;
      }
    }
  `;

  @consume({ context: gameContext })
  game: GameController;

  @state()
  tipModalKey: TipTabKeys;

  uiModel: UIWatchableModel;

  private unsubscribeFromSignal: () => void;

  connectedCallback() {
    super.connectedCallback();
    this.uiModel = this.game.getModel().getUIWatchableModel();
  }

  protected firstUpdated(): void {
    this.unsubscribeFromSignal = effect(() => {
      if (this.uiModel.gold.value >= 0) {
        this.game.getModel().checkTotalSalariesDue();
      }
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.unsubscribeFromSignal();
  }

  hireClicked = () => {
    openDialog(ActiveDialog.HireEmployee);
  };
  hire = () => {
    closeDialog();
    this.game.hire();
  };
  cancelHire = () => {
    closeDialog();
  };

  handleExitGame = () => {
    this.game.exitGame();
    setScreen(Screen.MainMenu);
  };

  handleLoadGame = () => {
    this.game.load(true);
    this.uiModel = this.game.getModel().getUIWatchableModel();
    stopMusic("GameMusic");
    playMusic("GameMusic");
  };

  renderTipsModal = () => html` <tips-modal></tips-modal> `;
  renderLevelUp = () => html`<level-up></level-up>`;

  renderEventModal = () => html`<event-modal></event-modal> `;

  toggleMute = () => {
    setIsMuted(!appState.isMuted.value);
    setGlobalSoundOn(appState.isMuted.value);
  };

  renderDialog = () => {
    switch (appState.dialog.value.type) {
      case ActiveDialog.HireEmployee:
        return html` <hire-dialog
          class="game-dialog"
          @accept=${this.hire}
          @cancel=${this.cancelHire}
        ></hire-dialog>`;
      case ActiveDialog.PayEmployee:
        return html`<pay-employee-dialog
          class="game-dialog"
        ></pay-employee-dialog>`;
      case ActiveDialog.LaunchProduct:
        return html`<add-product-dialog
          class="game-dialog"
        ></add-product-dialog>`;
      case ActiveDialog.OpenProduct:
        return html`<open-product-dialog
          class="game-dialog"
        ></open-product-dialog>`;
      default:
        return nothing;
    }
  };

  render() {
    if (!isIntroComplete()) {
      return html` <intro-flow></intro-flow>`;
    }

    return html`
      ${this.renderDialog()} ${when(appState.tip.value, this.renderTipsModal)}
      ${when(
        gameState.eventQueue.value.length && !gameState.displayLevelUp.value,
        this.renderEventModal,
      )}
      ${this.renderLevelUp()}

      <payday-tracker></payday-tracker>
      <business-value-tracker
        @open=${() => this.game.displayFinancialReport()}
      ></business-value-tracker>
      ${when(
        gameState.displayFinancialReport.value,
        () => html`<financial-report></financial-report>`,
      )}

      <div class="shortfall-wrapper-ui">
        ${when(
          this.uiModel.gold.value < gameState.overdueSalaries.value,
          () =>
            html`<div class="shortfall-ui">
              <img class="shortfall-ui-img" src=${UIImages.CoinLine} />
              <div class="shortfall-ui-text">
                ${gameState.overdueSalaries.value}
                ${translate("BusinessView_Overdue")}
              </div>
            </div>`,
        )}
        ${when(
          this.uiModel.gold.value < gameState.totalSalariesDue.value,
          () =>
            html`<div class="shortfall-ui">
              <img class="shortfall-ui-img" src=${UIImages.CoinLine} />
              <div class="shortfall-ui-text">
                ${gameState.totalSalariesDue.value}
                ${translate("BusinessView_Due")}
              </div>
            </div>`,
        )}
      </div>

      <div id="buttonbar">
        <button @click=${() => togglePause()}>
          ${gameState.isPaused.value ? "Play" : "Pause"}
        </button>
        <button @click=${() => this.game.save()}>"Save"</button>
        <button
          @click=${this.handleLoadGame}
          ?disabled="${!saveManager.localSave()}"
        >
          ${translate("SavedGame_Button_Load")}
        </button>
        <button @click=${this.toggleMute}>
          ${appState.isMuted.value ? "Mute" : "Unmute"}
        </button>
        <button @click=${this.handleExitGame}>Exit Game</button>
      </div>
    `;
  }
}
