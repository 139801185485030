import { SignalWatcher } from "@lit-labs/preact-signals";
import { consume } from "@lit/context";
import { LitElement, html, css } from "lit";
import { customElement } from "lit/decorators.js";
import { gameContext } from "../gameContext";
import { GameController } from "../controllers/gameController";
import { appState, closeDialog } from "../state/app-state";
import { Employee } from "../models/employee";
import { error } from "../helpers/loggerHelper";
import { gameDialogStyles } from "../styles/game-dialog";

@customElement("pay-employee-dialog")
export class PayEmployeeDialog extends SignalWatcher(LitElement) {
  static styles = [
    gameDialogStyles,
    css`
      .content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }

      .buttons {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
      }

      button {
        height: 50%;
        background-color: lightblue;
        min-width: 20%;
      }
    `,
  ];

  @consume({ context: gameContext })
  game: GameController;

  private employee: Employee;
  connectedCallback() {
    super.connectedCallback();
    const employee = appState.dialog.value.props;
    if (!employee || !(employee instanceof Employee)) {
      error(
        "[pay-employee-dialog] Wrong properties passed to this dialog!",
        appState.dialog.value,
      );
      closeDialog();
    } else {
      this.employee = employee;
    }
  }

  onYes = () => {
    this.game.payEmployee(this.employee);
    closeDialog();
  };
  onNo = () => {
    closeDialog();
  };

  render() {
    return html`
      <dialog open>
        <div class="content">
          <div>Do you want to pay your employee?</div>
        </div>
        <div class="buttons">
          <button @click=${this.onNo}>No</button>
          <button autofocus @click=${this.onYes}>
            Yes (${this.employee.getCost()} coins)
          </button>
        </div>
      </dialog>
    `;
  }
}
