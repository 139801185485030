import * as Phaser from "phaser";
const phaserConfig: Phaser.Types.Core.GameConfig = {
  type: Phaser.CANVAS,
  transparent: false,
  fps: { target: 60 },
  backgroundColor: "#00FF00",
  render: {
    roundPixels: true,
    failIfMajorPerformanceCaveat: true,
  },
  audio: {
    disableWebAudio: false,
  },
  plugins: {},
  title: "",
  version: "0",
};

export default phaserConfig;
